import React from 'react';
import ErrorModal from '../../components/ErrorModal';
import Dashboard from './components/Dashboard';
import Loading from '../../components/Loading';
import { getMonthlyScreenshotStatsByType } from '../../api/lv';

const reportType = {
  scheduled: 'scheduled',
  added: 'added',
};

class MonthlyScreenshotStatus extends React.Component {
  state = {
    status: 'LOADING',
    statesWithScheduledMonthlyScreenshots: [],
    scheduledMonthlyScreenshotStatus: 'LOADING',
    statesWithAddedMonthlyScreenshots: [],
    addedMonthlyScreenshotStatus: 'LOADING',
    dateToFilter: '',
  };
  async componentDidMount() {
    try {
      const { scheduled, added } = reportType;
      let statesWithScheduledMonthlyScreenshots = await getMonthlyScreenshotStatsByType(scheduled);
      this.setState({
        statesWithScheduledMonthlyScreenshots: statesWithScheduledMonthlyScreenshots.data,
        scheduledMonthlyScreenshotStatus: 'LOADED',
        status: 'LOADED',
      });
      let statesWithAddedMonthlyScreenshots = await getMonthlyScreenshotStatsByType(added);
      this.setState({
        statesWithAddedMonthlyScreenshots: statesWithAddedMonthlyScreenshots.data,
        addedMonthlyScreenshotStatus: 'LOADED',
      });
    } catch (error) {
      this.setState({
        status: 'ERRORED',
      });
    }
  }

  filterByDate = async (dateToFilter) => {
    try {
      const { scheduled, added } = reportType;
      const params = { date: dateToFilter };
      this.setState({
        status: 'LOADING',
        scheduledMonthlyScreenshotStatus: 'LOADING',
        addedMonthlyScreenshotStatus: 'LOADING',
        statesWithScheduledMonthlyScreenshots: [],
        statesWithAddedMonthlyScreenshots: [],
        dateToFilter,
      });
      let statesWithScheduledMonthlyScreenshots = await getMonthlyScreenshotStatsByType(scheduled, params);
      this.setState({
        statesWithScheduledMonthlyScreenshots: statesWithScheduledMonthlyScreenshots.data,
        scheduledMonthlyScreenshotStatus: 'LOADED',
        status: 'LOADED',
      });
      let statesWithAddedMonthlyScreenshots = await getMonthlyScreenshotStatsByType(added, params);
      this.setState({
        statesWithAddedMonthlyScreenshots: statesWithAddedMonthlyScreenshots.data,
        addedMonthlyScreenshotStatus: 'LOADED',
      });
    } catch (error) {
      this.setState({
        status: 'ERRORED',
      });
    }
  };
  render() {
    const {
      status,
      scheduledMonthlyScreenshotStatus,
      addedMonthlyScreenshotStatus,
      statesWithScheduledMonthlyScreenshots,
      statesWithAddedMonthlyScreenshots,
      dateToFilter,
    } = this.state;
    switch (status) {
      case 'LOADING':
        return <Loading message="Searching Monthly Screenshots information..." />;

      case 'LOADED':
        return (
          <Dashboard
            scheduledMonthlyScreenshotStatus={scheduledMonthlyScreenshotStatus}
            addedMonthlyScreenshotStatus={addedMonthlyScreenshotStatus}
            statesWithScheduledMonthlyScreenshots={statesWithScheduledMonthlyScreenshots}
            statesWithAddedMonthlyScreenshots={statesWithAddedMonthlyScreenshots}
            filterByDate={this.filterByDate}
            dateToFilter={dateToFilter}
          />
        );

      case 'ERRORED':
      default:
        return <ErrorModal />;
    }
  }
}

export default MonthlyScreenshotStatus;
