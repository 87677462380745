import React from 'react';
import Row from 'emerald-ui/lib/Row';
import Col from 'emerald-ui/lib/Col';
import Label from 'emerald-ui/lib/Label';

const StatusLabels = ({ labels }) => {
  const statusLabels = [];
  for (const commandName in labels) {
    const commandStatus = labels[commandName];
    const labelColor = commandStatus === 'Running' ? 'success' : 'danger';

    statusLabels.push(
      <Col key={commandName} xs={12} sm={6} md={3} style={{ marginTop: '15px' }}>
        <Label style={{ marginTop: '5px', marginRight: '10px', fontSize: '16px' }} color={labelColor}>
          {commandName}: {commandStatus}
        </Label>
      </Col>
    );
  }

  return <Row>{statusLabels}</Row>;
};

export default StatusLabels;
