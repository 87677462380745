import React from 'react';

import Table from 'emerald-ui/lib/Table';
import Avatar from 'emerald-ui/lib/Avatar';

import TablePlaceholder from '../../../components/TablePlaceholder';

import { getUploadHistories } from '../../../api/prehire';

class BoardStatusTable extends React.Component {
  state = {
    histories: [],
    status: 'LOADING',
  };

  async componentDidMount() {
    try {
      let { data: histories } = await getUploadHistories();

      this.setState({
        histories,
        status: 'LOADED',
      });
    } catch (error) {
      this.setState({
        status: 'ERRORED',
      });
    }
  }

  render() {
    const errorOcurred = this.state.status === 'ERRORED';
    const isLoaded = this.state.status === 'LOADED';
    const isEmpty = this.state.histories.length === 0;

    return (
      <Table>
        <tbody>
          {errorOcurred && <TablePlaceholder message="Something went wrong" icon="error_outline" />}
          {isLoaded && isEmpty && <TablePlaceholder message="Nothing to see here" icon="chat_bubble_outline" />}
          {isLoaded &&
            !isEmpty &&
            this.state.histories.map((history, index) => {
              const historyDate = history.date ? new Date(history.date).toLocaleString() : '[ Date not given ]';

              return (
                <tr key={index}>
                  <td style={{ display: 'flex' }}>
                    <Avatar title={history.username} style={{ marginRight: 10 }} size="md" />
                    <p>
                      <b>{history.username}</b> ran an upload for employer <b>{history.employerId}</b> on{' '}
                      <b>{historyDate}</b>.
                      <br />
                      {history.note && (
                        <span>
                          <b>Note:</b> {history.note}
                        </span>
                      )}
                    </p>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table>
    );
  }
}

export default BoardStatusTable;
