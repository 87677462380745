import React from 'react';
import ErrorModal from '../../components/ErrorModal';
import Dashboard from './components/Dashboard';
import Loading from '../../components/Loading';
import { getSMissingcreenshotStats, getS404creenshotStats, getCapturedScreenshotStats } from '../../api/lv';

class ScreenshotStatus extends React.Component {
  state = {
    status: 'LOADING',
    statesWithMissingScreenshots: [],
    missingScreenshotStatus: 'LOADING',
    statesWith404Screenshots: [],
    screenshot404Status: 'LOADING',
    statesWithCapturedScreenshots: [],
    capturedScreenshotStatus: 'LOADING',
    dateToFilter: '',
  };

  async componentDidMount() {
    try {
      let statesWithMissingScreenshots = await getSMissingcreenshotStats();
      this.setState({
        statesWithMissingScreenshots: statesWithMissingScreenshots.data,
        missingScreenshotStatus: 'LOADED',
        status: 'LOADED',
      });
      let statesWith404Screenshots = await getS404creenshotStats();
      this.setState({
        statesWith404Screenshots: statesWith404Screenshots.data,
        screenshot404Status: 'LOADED',
      });
      let statesWithCapturedScreenshots = await getCapturedScreenshotStats();
      this.setState({
        statesWithCapturedScreenshots: statesWithCapturedScreenshots.data,
        capturedScreenshotStatus: 'LOADED',
      });
    } catch (error) {
      this.setState({
        status: 'ERRORED',
      });
    }
  }

  filterByDate = async (dateToFilter) => {
    try {
      const params = { dateToFilter };

      this.setState({
        status: 'LOADING',
        missingScreenshotStatus: 'LOADING',
        screenshot404Status: 'LOADING',
        capturedScreenshotStatus: 'LOADING',
        statesWithMissingScreenshots: [],
        statesWith404Screenshots: [],
        statesWithCapturedScreenshots: [],
        dateToFilter,
      });

      let statesWithMissingScreenshots = await getSMissingcreenshotStats(params);
      this.setState({
        statesWithMissingScreenshots: statesWithMissingScreenshots.data,
        missingScreenshotStatus: 'LOADED',
        status: 'LOADED',
      });
      let statesWith404Screenshots = await getS404creenshotStats(params);
      this.setState({
        statesWith404Screenshots: statesWith404Screenshots.data,
        screenshot404Status: 'LOADED',
      });
      let statesWithCapturedScreenshots = await getCapturedScreenshotStats(params);
      this.setState({
        statesWithCapturedScreenshots: statesWithCapturedScreenshots.data,
        capturedScreenshotStatus: 'LOADED',
      });
    } catch (error) {
      this.setState({
        status: 'ERRORED',
      });
    }
  };

  render() {
    const {
      status,
      missingScreenshotStatus,
      screenshot404Status,
      capturedScreenshotStatus,
      statesWithMissingScreenshots,
      statesWith404Screenshots,
      statesWithCapturedScreenshots,
      dateToFilter,
    } = this.state;

    switch (status) {
      case 'LOADING':
        return <Loading message="Searching screenshots information..." />;

      case 'LOADED':
        return (
          <Dashboard
            missingScreenshotStatus={missingScreenshotStatus}
            screenshot404Status={screenshot404Status}
            capturedScreenshotStatus={capturedScreenshotStatus}
            statesWithMissingScreenshots={statesWithMissingScreenshots}
            statesWithCapturedScreenshots={statesWithCapturedScreenshots}
            statesWith404Screenshots={statesWith404Screenshots}
            filterByDate={this.filterByDate}
            dateToFilter={dateToFilter}
          />
        );

      case 'ERRORED':
      default:
        return <ErrorModal />;
    }
  }
}

export default ScreenshotStatus;
