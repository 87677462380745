import React from 'react';
import Panel from 'emerald-ui/lib/Panel';
import Table from 'emerald-ui/lib/Table';
import Label from 'emerald-ui/lib/Label';
import Container from 'emerald-ui/lib/Container';
import styled from 'styled-components';
import Row from 'emerald-ui/lib/Row';
import Col from 'emerald-ui/lib/Col';
import DatePicker from 'emerald-ui/lib/DatePicker';
import moment from 'moment';
import 'react-day-picker/lib/style.css';
import './Dashboard.css';

export const StyledH3 = styled.h3`
  text-align: center;
  font-weight: bold;
  padding: 10px;
  padding-top: 0px;
`;

let statusColors = {
  QUEUED: {
    name: 'Queued',
    color: '#42a5f5',
    tag: 'info',
    count: 0,
  },
  PROCESSING: {
    name: 'Processing',
    color: '#fa0',
    tag: 'warning',
    count: 0,
  },
  COMPLETED: {
    name: 'Completed',
    color: '#5bd672',
    tag: 'success',
    count: 0,
  },
  FAILED: {
    name: 'Failed',
    color: '#ff4e4a',
    tag: 'danger',
    count: 0,
  },
  UNKNOWN: {
    name: 'Unknown',
    color: '#607b7b',
    tag: 'default',
    count: 0,
  },
};

const cardValues = [
  {
    title: 'Queued',
    value: 'QUEUED',
  },
  {
    title: 'Processing',
    value: 'PROCESSING',
  },
  {
    title: 'Completed',
    value: 'COMPLETED',
  },
  {
    title: 'Failed',
    value: 'FAILED',
  },
];

const Dashboard = ({ CNProgressInformation, filterByDate, date }) => {
  const handlerFilterByDate = (day) => {
    let date = moment(day).format('MM-DD-YYYY');
    filterByDate(date);
  };

  const { data, status } = CNProgressInformation;

  return (
    <Container>
      <Panel>
        <Panel.Body>
          <Row className="container-row-license">
            <Col lg={8} xs={12} md={6} sm={12}>
              <h1 className="text-dark">Custom Notifications</h1>
            </Col>
            <Col xs={6} md={4} sm={6} lg={4} className="container-date-picker upload-status">
              <DatePicker
                ariaLabel={`Choose date to filter`}
                onChange={handlerFilterByDate}
                value={date}
                label="Date filter"
              />
            </Col>
          </Row>
        </Panel.Body>
      </Panel>

      <Panel>
        <Panel.Body>
          <Row className="container-cards" center>
            {cardValues.map((card, i) => {
              return (
                <Col lg={3} md={3} xs={12} sm={6} key={i}>
                  <Row center style={{ padding: '20px 24px' }}>
                    <Col xs={12}>
                      <StyledH3 className="text-dark">{card.title}</StyledH3>
                    </Col>
                    <Col xs={12}>
                      <Label color={statusColors[card.value].tag} style={{ display: 'block' }}>
                        {status.hasOwnProperty(card.value) ? status[card.value] : 0}
                      </Label>
                    </Col>
                  </Row>
                </Col>
              );
            })}
          </Row>

          <div style={{ overflow: 'auto' }}>
            <Table hover striped condensed>
              <thead>
                <tr>
                  <th>Employer name</th>
                  <th>Employer ID</th>
                  <th>Old ID</th>
                  <th>Settings ID</th>
                  <th>CN ID</th>
                  <th>Description</th>
                  <th>Recipient</th>
                  <th>Frequency</th>
                  <th>Created Date</th>
                  <th>Next Run Date</th>
                  <th>emails sent</th>
                  <th>Status</th>
                  <th>Summary</th>
                </tr>
              </thead>
              <tbody>
                {data.length ? (
                  data.map((fileInformation, i) => {
                    const { name: labelText, tag } = statusColors[fileInformation.status] || statusColors['UNKNOWN'];
                    return (
                      <tr key={i}>
                        <td>{fileInformation.employerName}</td>
                        <td>{fileInformation.employerId}</td>
                        <td>{fileInformation.oldCustomNotificationId}</td>
                        <td>{fileInformation.settingId}</td>
                        <td>{fileInformation.newCustomNotificationId}</td>
                        <td>{fileInformation.description}</td>
                        <td>{fileInformation.recipient}</td>
                        <td>{fileInformation.frequency}</td>
                        <td>{!fileInformation.createdAt ? '' : moment(fileInformation.createdAt).format('LLL')}</td>
                        <td>{!fileInformation.nextRun ? '' : moment(fileInformation.nextRun).utc().format('LLL')}</td>
                        <td>{fileInformation.emailsSent}</td>
                        <td>
                          <Label color={tag}>{labelText}</Label>
                        </td>
                        <td>{fileInformation.summary}</td>
                      </tr>
                    );
                  })
                ) : (
                  <tr className="table-nodata-found">
                    <td colSpan={8}>No data found</td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        </Panel.Body>
      </Panel>
    </Container>
  );
};

export default Dashboard;
