import React from 'react';
import styled from 'styled-components';
import Container from 'emerald-ui/lib/Container';
import BoardErrorsTable from './components/BoardErrorsTable';
import StateSummaryPanel from './components/StateSummaryPanel';

import BoardStatusTable from './components/BoardStatusTable';

import { canUse } from '../../utils/userPermissionHandler';

const Title = styled.h1`
  font-weight: lighter;
`;

const BoardStats = (props) => {
  window.scrollTo(0, 0);

  return (
    <Container>
      <Title className="text-dark">State verification status</Title>
      <hr />

      <p>
        <b>**</b> State verification status doesn't include licenses verified through <b>file</b>.
      </p>

      <StateSummaryPanel {...props} />

      <Title className="text-dark">Board Status summary</Title>
      <hr />
      <BoardStatusTable {...props} />

      {canUse('BOARD_ERRORS_DETAILS') && (
        <React.Fragment>
          <Title className="text-dark">Board Errors summary</Title>
          <hr />
          <BoardErrorsTable {...props} />
        </React.Fragment>
      )}
    </Container>
  );
};

export default BoardStats;
