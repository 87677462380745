import React from 'react';

import Spinner from 'emerald-ui/lib/Spinner';

const TableLoader = (props) => {
  const loadingMessage = props.message || 'Loading...';

  return (
    <tr>
      <td colSpan={props.colSpan} style={{ padding: '300px 0', textAlign: 'center' }}>
        <Spinner size="lg" />
        <h2>{loadingMessage}</h2>
      </td>
    </tr>
  );
};

export default TableLoader;
