import React from 'react';
import Spinner from 'emerald-ui/lib/Spinner';

const Loading = ({ message, submessage = null }) => {
  return (
    <div style={{ textAlign: 'center' }}>
      <Spinner size="lg" style={{ marginTop: 50 }} />
      <h2 className="text-dark" style={{ fontWeight: 'lighter' }}>
         {message}
      </h2>
      <small>
        {submessage || 'This request is heavy. We are consulting the data. Please wait '}
        <span role="img" aria-label="emoji">
          ☕
        </span>
      </small>
    </div>
  );
};

export default Loading;
