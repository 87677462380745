import React from 'react';

import Alert from 'emerald-ui/lib/Alert';
import Card from 'emerald-ui/lib/Card';

const getQueueColor = (value) => {
  if (value >= 15 && value < 45) return 'warning';

  if (value >= 45) return 'danger';

  return 'success';
};

const QstatusContent = ({ queue }) => {
  return (
    <React.Fragment>
      <h3>
        Name: {queue.name} ({queue.alias})
      </h3>
      <br />
      <Alert color={getQueueColor(queue.messages)}>
        Number of messages: {queue.messages} (Age {queue.age} min; Size {queue.size} mb)
      </Alert>
      <Alert color={getQueueColor(queue.backlogMessages)}>
        Backlog (messages): {queue.backlogMessages} (Age {queue.backlogAge} min)
      </Alert>
    </React.Fragment>
  );
};

const getHeaderColor = (queueInfo) => {
  if (!queueInfo) return false;
  const maxQueueValue = Object.values(queueInfo)
    .map(({ age, messages }) => Math.max(age, messages))
    .reduce((previous, current) => Math.max(current, previous), 0);

  return getQueueColor(maxQueueValue);
};

const QStatusCard = ({ qstatus }) => {
  const { host, information: queues } = qstatus;

  const headerColor = getHeaderColor(queues);

  return (
    <Card>
      <Card.Header color={headerColor}>
        <h2 className="text-off-white-dark">QSTATUS</h2>
      </Card.Header>
      <div>
        <h2>Queues Statistics for {host} </h2>
        <hr />
        {queues &&
          Object.values(queues).map((queue, index) => {
            return <QstatusContent queue={queue} key={index} />;
          })}
      </div>
    </Card>
  );
};

export default QStatusCard;
