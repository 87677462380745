import React from 'react';
import { FirestoreCollection } from 'react-firestore';
import ExpansionPanel from 'emerald-ui/lib/ExpansionPanel';
import Col from 'emerald-ui/lib/Col';
import Row from 'emerald-ui/lib/Row';
import TotalsCards from './TotalsCards';
import { LabelLoader } from './Loaders';
import PackagesList from './PackagesList';
import styled from 'styled-components';

export const StyledCol = styled(Col)`
  height: 150px;
  position: relative;
  display: flex;
  flex-direction: row;
`;

export const StyledRow = styled(Row)`
  margin: 20px;
  align-content: center:
  position: relative;
`;

export const StyledDiv = styled.div`
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
`;

const InstanceStatus = ({ datacenter }) => {
  return (
    <React.Fragment>
      <FirestoreCollection
        path={`oracle-packages-${datacenter}`}
        render={({ isLoading, data: packages, error }) => {
          if (isLoading) {
            return <LabelLoader />;
          }

          if (error) {
            return <h3>{error.message}</h3>;
          }

          return (
            <ExpansionPanel>
              <ExpansionPanel.Summary>
                <Row center>
                  <StyledCol xs={2} md={2} lg={2}>
                    <StyledDiv>
                      <h2 className="text-dark">
                        {datacenter.toUpperCase()} ORA{' '}
                        <span role="img" aria-label="pkg">
                          📦
                        </span>
                        s
                      </h2>
                    </StyledDiv>
                  </StyledCol>
                  <StyledCol xs={10} md={10} lg={10}>
                    <TotalsCards packages={packages} />
                  </StyledCol>
                </Row>
              </ExpansionPanel.Summary>
              <ExpansionPanel.Content style={{ 'background-color': '#f2f2f2' }}>
                <StyledRow center>
                  <PackagesList id={datacenter} packages={packages} />
                </StyledRow>
              </ExpansionPanel.Content>
            </ExpansionPanel>
          );
        }}
      />
    </React.Fragment>
  );
};

export default InstanceStatus;
