import React from 'react';
import Spinner from 'emerald-ui/lib/Spinner';
import IconDropdown from 'emerald-ui/lib/IconDropdown';
import DropdownItem from 'emerald-ui/lib/DropdownItem';

import { exportStatusProgressDownload } from '../../../api/hr';

const spanStyle = {
  display: 'flex',
  flexDirecion: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '8px',
};

class Export extends React.Component {
  state = {
    isDownloading: false,
  };

  exportByTYpe = async (date) => {
    const params = { date };
    this.setState({
      isDownloading: true,
      needPageRefresh: false,
    });

    let downloaded = await exportStatusProgressDownload(params);
    if (!downloaded) this.setState({ needPageRefresh: true });

    this.setState({ isDownloading: false });
  };
  render() {
    const { date } = this.props;
    const { isDownloading, needPageRefresh } = this.state;

    return (
      <IconDropdown id="download" icon="more_vert" style={{ marginLeft: '15px' }}>
        {isDownloading ? (
          <span style={spanStyle}>
            <Spinner size="sm" />
            Downloading
          </span>
        ) : needPageRefresh ? (
          <span>Please refresh</span>
        ) : (
          <DropdownItem icon="exit_to_app" onClick={() => this.exportByTYpe(date)}>
            Export
          </DropdownItem>
        )}
      </IconDropdown>
    );
  }
}
export default Export;
