import React from 'react';

import CardGrid from 'emerald-ui/lib/CardGrid';
import LoadingCard from '../LoadingCard';

export default function CardLoader({ numberOfCards = 4 } = {}) {
  const loadingCards = [];

  for (let i = 0; i < numberOfCards; i++) {
    loadingCards.push(<LoadingCard key={i} />);
  }

  return <CardGrid>{loadingCards}</CardGrid>;
}
