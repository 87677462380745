import axios from 'axios';
import { getAuthToken } from '../utils/auth';

export const usersAPI = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

const getHeader = () => {
  return {
    headers: { Authorization: getAuthToken() },
  };
};

export const getUsers = (params = {}) => {
  return usersAPI.get('/user', { params, ...getHeader() });
};

export const createUser = ({ name, username, password, role, permissions = [], active = true }) => {
  return usersAPI.post('/user', { name, username, password, role, permissions, active }, { ...getHeader() });
};

export const updateUser = ({ _id, name, password, role, permissions, active, loginAttemps }) => {
  return usersAPI.put(`/user/${_id}`, { name, password, role, permissions, active, loginAttemps }, { ...getHeader() });
};
