import React from 'react';
import SkeletonLoader from 'emerald-ui/lib/SkeletonLoader';

const LabelLoader = () => {
  return (
    <div style={{ display: 'flex' }}>
      <SkeletonLoader loading height="27px" />
      <SkeletonLoader loading height="27px" />
      <SkeletonLoader loading height="27px" />
      <SkeletonLoader loading height="27px" />
      <SkeletonLoader loading height="27px" />
      <SkeletonLoader loading height="27px" />
    </div>
  );
};

const TableLoader = () => {
  return <SkeletonLoader loading width="586px" height="717px" />;
};

export { LabelLoader, TableLoader };
