import React from 'react';
import Panel from 'emerald-ui/lib/Panel';
import Table from 'emerald-ui/lib/Table';
import Label from 'emerald-ui/lib/Label';
import Pager from 'emerald-ui/lib/Pager';
import Container from 'emerald-ui/lib/Container';
import Row from 'emerald-ui/lib/Row';
import Col from 'emerald-ui/lib/Col';
import DatePicker from 'emerald-ui/lib/DatePicker';
import styled from 'styled-components';
import moment from 'moment';
import Export from './Export';
import 'react-day-picker/lib/style.css';
import './Expiring.css';
export const StyledH3 = styled.h3`
  text-align: center;
  font-weight: bold;
  padding: 10px;
  padding-top: 0px;
`;

const statusColors = {
  PENDING: 'info',
  NOT_FOUND: 'warning',
  PROCESSED: 'success',
  SERVER_ERROR: 'danger',
};

const cardValues = [
  {
    title: 'Pending',
    value: 'PENDING',
  },
  {
    title: 'Processed',
    value: 'PROCESSED',
  },
  {
    title: 'Not Found',
    value: 'NOT_FOUND',
  },
  {
    title: 'Server Errors',
    value: 'SERVER_ERROR',
  },
];

const Dashboard = ({
  expiringLicensesInfo,
  offset,
  limit,
  totalLicenses,
  handleLimitChange,
  handlePageChange,
  handleDayClick,
  range,
  dateFormat,
}) => {
  const { from, to } = range;
  const startDate = from ? moment(from).format(dateFormat) : '';
  const endDate = to ? moment(to).format(dateFormat) : '';
  const stringRange = {
    startDate,
    endDate,
  };

  const { licenses, counts } = expiringLicensesInfo;

  return (
    <Container>
      <Panel>
        <Panel.Body>
          <Row className="container-row-expiring">
            <Col xs={12} sm={12} md={4}>
              <h1 className="text-dark">Expiring LCRs</h1>
            </Col>
            <Col xs={12} sm={12} md={8} className="container-date-picker-expiring">
              <Row>
                <Col xs={6}>
                  <DatePicker
                    ariaLabel={`Choose date to filter`}
                    label="From"
                    onChange={(date) => handleDayClick(date, 'from')}
                    selected={from}
                    value={from}
                    selectsStart
                    endDate={to}
                  />
                </Col>
                <Col xs={6}>
                  <DatePicker
                    ariaLabel={`Choose date to filter`}
                    label="To"
                    onChange={(date) => handleDayClick(date, 'to')}
                    minDate={from}
                    selected={to}
                    value={to}
                    selectsEnd
                    startDate={from}
                  />
                </Col>
              </Row>

              {licenses.length ? <Export range={stringRange} offset={offset} limit={limit} /> : null}
            </Col>
          </Row>
        </Panel.Body>
      </Panel>

      <Panel>
        <Panel.Body>
          <Row className="container-card-expiring">
            {cardValues.map((card, i) => {
              return (
                <Col lg={3} md={3} xs={12} sm={6} key={i}>
                  <Row center style={{ padding: '20px 24px' }}>
                    <Col xs={12}>
                      <StyledH3 className="text-dark">{card.title}</StyledH3>
                    </Col>
                    <Col xs={12}>
                      <Label color={statusColors[card.value]} style={{ display: 'block' }}>
                        {counts.hasOwnProperty(card.value) ? counts[card.value] : 0}
                      </Label>
                    </Col>
                  </Row>
                </Col>
              );
            })}
          </Row>

          <div style={{ overflow: 'auto' }}>
            <Table hover striped condensed>
              <thead>
                <tr>
                  <th>Employer Name</th>
                  <th>Employer ID</th>
                  <th>Employee Name</th>
                  <th>Employee ID</th>
                  <th>State</th>
                  <th>Board</th>
                  <th>Profession Name</th>
                  <th>License Number</th>
                  <th>Expiration Date</th>
                  <th>Last Verified</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {licenses.length ? (
                  licenses.map((licInfo, i) => {
                    const {
                      NM_EMPLOYER: employerName,
                      ID_EMPLOYER: employerID,
                      NM_FIRST: firstName,
                      NM_LAST: lastName,
                      NM_MIDDLE: middleName,
                      ID_EMPLOYEE_NUMBER: idEmployeeNumber,
                      CD_STATE: stateCD,
                      NM_BOARD: boardName,
                      NM_PROFESSION: progessionName,
                      DS_LICENSE_NUMBER: licNum,
                      DT_EXPIRATION: expDate,
                      LAST_VERIFIED: lastVerified,
                      STATUS: status,
                    } = licInfo;

                    const fullName = [
                      firstName ? firstName : '',
                      middleName ? middleName : '',
                      lastName ? lastName : '',
                    ].join(' ');

                    return (
                      <tr key={i}>
                        <td>{employerName}</td>
                        <td>{employerID}</td>
                        <td>{fullName}</td>
                        <td>{idEmployeeNumber}</td>
                        <td>{stateCD}</td>
                        <td>{boardName}</td>
                        <td>{progessionName}</td>
                        <td>{licNum}</td>
                        <td>{expDate}</td>
                        <td>{lastVerified}</td>
                        <td>
                          <Label color={statusColors[status]}>{status}</Label>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={12} style={{ textAlign: 'center' }}>
                      No data found
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
          <Pager
            offset={offset}
            limit={limit}
            onPageChange={handlePageChange}
            onLimitChange={handleLimitChange}
            total={totalLicenses}
            style={{ marginTop: 10 }}
          />
        </Panel.Body>
      </Panel>
    </Container>
  );
};

export default Dashboard;
