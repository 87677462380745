import React from 'react';
import ErrorModal from '../../components/ErrorModal';
import Dashboard from './components/Dashboard';
import Loading from '../../components/Loading';
import { exportStatusProgress } from '../../api/hr';
import moment from 'moment';

class ExportStatus extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status: 'LOADING',
      exportsProgressInformation: [],
      exportsProgressInformationStatus: 'LOADING',
      date: moment().format('YYYY-MM-DD'),
    };
  }

  async componentDidMount() {
    try {
      let exportsProgress = await exportStatusProgress();
      this.setState({
        exportsProgressInformation: exportsProgress.data,
        exportsProgressInformationStatus: 'LOADED',
        status: 'LOADED',
      });
    } catch (error) {
      this.setState({
        status: 'ERRORED',
      });
    }
  }

  filterByDate = async (date) => {
    try {
      const params = { date };

      this.setState({
        status: 'LOADING',
        exportsProgressInformationStatus: 'LOADING',
        exportsProgressInformation: [],
        date,
      });

      let exportsProgress = await exportStatusProgress(params);
      this.setState({
        exportsProgressInformation: exportsProgress.data,
        exportsProgressInformationStatus: 'LOADED',
        status: 'LOADED',
      });
    } catch (error) {
      this.setState({
        status: 'ERRORED',
      });
    }
  };

  render() {
    const { status, exportsProgressInformation, exportsProgressInformationStatus, date } = this.state;

    switch (status) {
      case 'LOADED':
        return (
          <Dashboard
            exportsProgressInformation={exportsProgressInformation}
            exportsProgressInformationStatus={exportsProgressInformationStatus}
            filterByDate={this.filterByDate}
            date={date}
          />
        );

      case 'LOADING':
        return <Loading message="Searching Export Status information..." />;

      case 'ERRORED':
      default:
        return <ErrorModal />;
    }
  }
}

export default ExportStatus;
