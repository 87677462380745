import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Button from 'emerald-ui/lib/Button';
import Card from 'emerald-ui/lib/Card';
import Label from 'emerald-ui/lib/Label';
import Progressbar from 'emerald-ui/lib/Progressbar';
import Table from 'emerald-ui/lib/Table';

import { canUse } from '../../../utils/userPermissionHandler';
import './style.css';

const StyledCard = styled(Card)`
  margin-top: 20px;
  min-height: 409px;
  justify-content: space-between;
`;

const SeeMoreButton = styled(Button)`
  float: right;
`;

const StateCard = (props) => {
  const { count, processed, notFound, serverError } = props;

  const processedPercentage = ((processed / count) * 100).toFixed(2);
  const notFoundPercentage = ((notFound / count) * 100).toFixed(2);
  const serverErrorPercentage = ((serverError / count) * 100).toFixed(2);

  const headerColor = processedPercentage < 40 ? 'danger' : processedPercentage < 90 ? 'warning' : 'success';

  const labels = [];

  if (props.serverError > 0) {
    labels.push({ color: 'danger', text: 'Server Errors' });
  }

  if (props.notFound > 0) {
    labels.push({ color: 'warning', text: 'Not founds' });
  }

  if (processedPercentage === 100) {
    labels.push({ color: 'success', text: 'Completed' });
  }

  return (
    <StyledCard>
      <Card.Header color={headerColor}>
        <h2 className="eui-card-header-title">
          {props.stateCode} - {props.stateName}
        </h2>
        <Progressbar
          progress={+processedPercentage}
          backgroundColor={headerColor}
          circle
          style={{
            height: '50px',
            width: '50px',
          }}
        />
      </Card.Header>

      <div style={{ marginBottom: 20 }}>
        {labels.map((labelInfo, index) => {
          return (
            <Label key={index} style={{ marginRight: '10px' }} color={labelInfo.color}>
              {labelInfo.text}
            </Label>
          );
        })}
      </div>

      <div className="card-state-container">
        <Table condensed striped style={{ marginBottom: 20 }}>
          <thead>
            <tr>
              <th>Report</th>
              <th>Licenses</th>
              <th>Percentage</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Processed licenses</td>
              <td>{props.processed}</td>
              <td>{processedPercentage}%</td>
            </tr>
            <tr>
              <td>Not found licenses</td>
              <td>{props.notFound}</td>
              <td>{notFoundPercentage}%</td>
            </tr>
            <tr>
              <td>Server errors</td>
              <td>{props.serverError}</td>
              <td>{serverErrorPercentage}%</td>
            </tr>
            <tr>
              <td>Total licenses</td>
              <td>{props.count}</td>
              <td>-</td>
            </tr>
          </tbody>
        </Table>

        {canUse('BOARD_STATS') && (
          <Link to={`/lv/board-stats?stateCode=${props.stateCode}`}>
            <SeeMoreButton onClick={props.onClick}>See More</SeeMoreButton>
          </Link>
        )}
      </div>
    </StyledCard>
  );
};

export default StateCard;
