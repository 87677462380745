import React from 'react';

import Card from 'emerald-ui/lib/Card';
import SkeletonLoader from 'emerald-ui/lib/SkeletonLoader';

function loadingCard() {
  return (
    <Card style={{ flex: '1 1 30%', margin: '40px 7.5px 0 7.5px' }}>
      <SkeletonLoader loading height="20px" />
      <SkeletonLoader loading height="20px" />
      <SkeletonLoader loading height="20px" />
      <SkeletonLoader loading height="20px" />
    </Card>
  );
}

export default loadingCard;
