import React from 'react';
import ExpansionPanel from 'emerald-ui/lib/ExpansionPanel';
import Table from 'emerald-ui/lib/Table';
import Chip from 'emerald-ui/lib/Chip';
import BoardExpansion from './BoardExpansion';
import './MonthlyScreenshot.css';

const StateExpansion = ({ stateName, status, stats, type, dateToFilter }) => {
  const chipStyle = {
    backgroundColor: '#de350b',
    fontWeight: 'bold',
    marginRight: '10px',
    color: 'white',
  };

  return (
    <ExpansionPanel style={{ marginTop: '10px' }} className="panel-content-screenshot">
      <ExpansionPanel.Summary>
        <div className="summaryPanelStyle">
          <p>{stateName}</p>
          <Chip style={chipStyle} size="lg">
            {stats.totalScreenshots}
          </Chip>
        </div>
      </ExpansionPanel.Summary>
      <ExpansionPanel.Content>
        <Table borderless hover striped>
          <tbody>
            {status === 'LOADED'
              ? stats.board.map((board, i) => {
                  return <BoardExpansion key={i} boardStats={board} type={type} dateToFilter={dateToFilter} />;
                })
              : null}
          </tbody>
        </Table>
      </ExpansionPanel.Content>
    </ExpansionPanel>
  );
};

export default StateExpansion;
