import React from 'react';

import DataCentePanel from './components/DataCentePanel';
import { FirestoreProvider } from 'react-firestore';
import firebase from 'firebase/app';

import Container from 'emerald-ui/lib/Container';

const OraclePackagesStatus = () => {
  return (
    <FirestoreProvider firebase={firebase}>
      <Container>
        <DataCentePanel datacenter="jax" />
        <br />
        <DataCentePanel datacenter="bdu" />
      </Container>
    </FirestoreProvider>
  );
};

export default OraclePackagesStatus;
