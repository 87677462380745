import React from 'react';
import styled from 'styled-components';

import Table from 'emerald-ui/lib/Table';
import Label from 'emerald-ui/lib/Label';

import TableLoader from '../../../components/TableLoader';
import TablePlaceholder from '../../../components/TablePlaceholder';

const StyledTable = styled(Table)`
  tbody tr td {
    vertical-align: middle;
  }
`;

const TdRight = styled.td`
  text-align: right;
`;

const DemographicUploadsTable = ({ uploadsStatus, fetchStatus }) => {
  const isLoading = fetchStatus === 'LOADING';
  const isLoaded = fetchStatus === 'LOADED';
  const errorOcurred = fetchStatus === 'ERRORED';
  const isEmpty = uploadsStatus.length === 0;

  const getLabelStatus = (status) => {
    let labelColor;

    switch (status) {
      case 'completed':
        labelColor = 'success';
        break;

      case 'failed':
        labelColor = 'danger';
        break;

      default:
        labelColor = 'default';
        break;
    }

    return <Label color={labelColor}>{status}</Label>;
  };

  const calculateProcessed = ({ after = 0, prior = 0 }) => {
    return Number.parseInt(after, 10) - Number.parseInt(prior, 10);
  };

  return (
    <StyledTable hover={isLoaded && !isEmpty ? true : false} striped condensed>
      <thead>
        <tr>
          <th>Employer ID</th>
          <th>File Name</th>
          <th>Status</th>
          <th>Created at</th>
          <th>Finished at</th>
          <th>FTP</th>
          <th>Total Items</th>
          <th>Processed</th>
        </tr>
      </thead>
      <tbody>
        {isLoading && <TableLoader colSpan={11} />}
        {isLoaded && isEmpty && (
          <TablePlaceholder iconName="chat_bubble_outline" message="Nothing to see here 👀" colSpan={11} />
        )}
        {errorOcurred && <TablePlaceholder iconName="error_outline" message="Something went wrong 💥🌪" colSpan={11} />}
        {isLoaded &&
          !isEmpty &&
          uploadsStatus.map((upload, index) => {
            if (upload) {
              return (
                <tr key={`upload-status-${index}`}>
                  <td>{upload.employer_id}</td>
                  <td>{upload.file_name}</td>
                  <td>{getLabelStatus(upload.status)}</td>
                  <td>{upload.created_at}</td>
                  <td>{upload.finished_at}</td>
                  <td>{upload.is_ftp ? 'Yes' : 'No'}</td>
                  <TdRight>{upload.total_items}</TdRight>
                  <TdRight>
                    {calculateProcessed({ after: upload.employee_count_after, prior: upload.employee_count_prior })}
                  </TdRight>
                </tr>
              );
            }
          })}
      </tbody>
    </StyledTable>
  );
};

export default DemographicUploadsTable;
