import axios from 'axios';

import { getAuthToken } from '../utils/auth';

const getHeader = () => {
  return {
    headers: { Authorization: getAuthToken() },
  };
};

export const lvAPI = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

export const checkCadidateStatus = (params = {}) => {
  return lvAPI.get('/wallet/candidate/registration-status', { params, ...getHeader() });
};
