import React from 'react';

import Container from 'emerald-ui/lib/Container';
import Row from 'emerald-ui/lib/Row';
import Col from 'emerald-ui/lib/Col';
import Card from 'emerald-ui/lib/Card';
import Button from 'emerald-ui/lib/Button';
import styled from 'styled-components';
import Toast from 'emerald-ui/lib/Toast';

import ConfirmationText from './components/ConfirmationText';

import { ButtonContainer, ShortTextField } from './components/styledComponents';

import * as hrAPI from '../../api/hr';

const Title = styled.h2`
  font-weight: lighter;
`;

const TextFieldContainer = styled.div`
  margin: 20px 0;
`;

class ChangeHRPassword extends React.Component {
  state = {
    accountId: '',
    status: 'IDLE',
    accountInfo: null,
    showToast: false,
    toastMessage: '',
  };

  handleAccountChanges = (error) => {
    this.setState({
      accountId: '',
      accountInfo: null,
      showToast: true,
      toastMessage: error ? 'Ops, something went wrong' : 'Good to go!',
    });
  };

  handleTrackingNumberChange = (event) => {
    const incomingValue = event.target.value;

    if (incomingValue.match(/^[0-9]{0,}$/)) {
      this.setState({
        accountId: incomingValue,
        accountInfo: null,
      });
    }
  };

  findAccount = async () => {
    try {
      this.setState({
        status: 'LOADING',
      });

      const { data: accountInfo } = await hrAPI.getUserAccount({
        accountId: this.state.accountId,
      });

      this.setState({
        accountInfo,
      });
    } catch (error) {
      const accountNotFound = error.response && error.response.status === 404;
      const toastMessage = accountNotFound
        ? 'No active account found with given ID'
        : 'Something went wrong. Please, try again later.';

      this.setState({
        showToast: true,
        toastMessage,
      });
    } finally {
      this.setState({
        status: 'IDLE',
      });
    }
  };

  hideToast = () => {
    this.setState({
      showToast: false,
    });
  };

  render() {
    const isLoading = this.state.status === 'LOADING';

    return (
      <React.Fragment>
        <Container>
          <Row center>
            <Col xs={12} md={5}>
              <Card>
                <Title className="text-dark">Unlock Account or Reset Password</Title>
                <TextFieldContainer>
                  <ShortTextField
                    label="Account Tracking Number"
                    id="account-tracking-number"
                    disabled={isLoading}
                    onChange={this.handleTrackingNumberChange}
                    value={this.state.accountId}
                  />
                </TextFieldContainer>
                <ButtonContainer>
                  <Button
                    style={{ width: '100%' }}
                    onClick={this.findAccount}
                    color="info"
                    disabled={!this.state.accountId || isLoading || this.state.accountInfo}
                    loading={isLoading}
                  >
                    Find account
                  </Button>
                </ButtonContainer>
                {this.state.accountInfo && (
                  <ConfirmationText info={this.state.accountInfo} onSubmitCallback={this.handleAccountChanges} />
                )}
              </Card>
            </Col>
          </Row>
        </Container>
        <Toast
          message={this.state.toastMessage}
          visible={this.state.showToast}
          position="right"
          onTimeout={this.hideToast}
        />
      </React.Fragment>
    );
  }
}

export default ChangeHRPassword;
