import React from 'react';
import Icon from 'emerald-ui/lib/Icon';

const TablePlaceholder = (props) => {
  return (
    <tr>
      <td colSpan={props.colSpan} style={{ padding: '300px 0', textAlign: 'center' }}>
        <Icon name={props.iconName} style={{ fontSize: props.iconSize || '168px' }} />
        <h2 style={{ fontSize: props.fontSize || 'auto', textAlign: 'center' }}>{props.message}</h2>
      </td>
    </tr>
  );
};

export default TablePlaceholder;
