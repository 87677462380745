import React from 'react';
import Container from 'emerald-ui/lib/Container';
import Row from 'emerald-ui/lib/Row';
import Col from 'emerald-ui/lib/Col';
import SearchForm from 'emerald-ui/lib/SearchForm';

import { getStateStats } from '../../api/lv';
import StateCard from './components/stateCard';
import ErrorModal from '../../components/ErrorModal';
import CardsLoader from '../../components/CardsLoader';

class Dashboard extends React.Component {
  state = {
    status: 'LOADING',
    showModal: false,
    statesStats: [],
    showDetailsModal: false,
    searchTerm: '',
  };

  async componentDidMount() {
    window.scrollTo(0, 0);
    try {
      let { data: statesStats } = await getStateStats();

      this.setState({
        statesStats,
        status: 'LOADED',
      });
    } catch (error) {
      this.setState({
        status: 'ERRORED',
        showModal: true,
      });
    }
  }

  openDetailsModal = () => {
    this.setState({
      showDetailsModal: true,
    });
  };

  closeDetailsModal = () => {
    this.setState({
      showDetailsModal: false,
    });
  };

  updateSeachTerm = (e) => {
    this.setState({
      searchTerm: e.target.value.replace(/[^A-Za-z]/gi, ''),
    });
  };

  clearSearchTerm = () => {
    this.setState({
      searchTerm: '',
    });
  };

  render() {
    const { status, statesStats, searchTerm } = this.state;

    switch (status) {
      case 'LOADING':
        return (
          <Container>
            <CardsLoader numberOfCards={6} />
          </Container>
        );
      case 'LOADED': {
        let statesToDisplay;

        if (!searchTerm) {
          statesToDisplay = statesStats;
        } else {
          const searchTermRegExp = new RegExp(searchTerm.toUpperCase());

          statesToDisplay = statesStats.filter(({ stateCode, stateName }) => {
            return stateCode.toUpperCase().match(searchTermRegExp) || stateName.toUpperCase().match(searchTermRegExp);
          });
        }

        return (
          <React.Fragment>
            <Container>
              <Row center>
                <Col>
                  <SearchForm
                    inputId="query"
                    onSubmit={(e) => e.preventDefault()}
                    onChange={this.updateSeachTerm}
                    onClear={this.clearSearchTerm}
                    value={searchTerm}
                    clearable
                  />
                </Col>
              </Row>
              <Row className="container-grid-cards">
                {statesToDisplay.map((info, index) => (
                  <Col key={index} xs={12} sm={6} lg={4}>
                    <StateCard {...info} key={info.stateCode} onClick={this.openDetailsModal} />
                  </Col>
                ))}
              </Row>
            </Container>
          </React.Fragment>
        );
      }
      case 'ERRORED':
      default:
        return <ErrorModal />;
    }
  }
}

export default Dashboard;
