import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { init as initApm } from '@elastic/apm-rum';

import 'emerald-ui/lib/styles-ec.css';
import App from './App';

const { REACT_APP_APM_SERVER_URL, REACT_APP_ENV } = process.env;

// APM initialization

const ENVIRONMENTS = {
  test: 'Test',
  demo: 'Demo',
  production: 'Production',
};

if (REACT_APP_ENV && REACT_APP_ENV !== 'development') {
  initApm({
    serviceName: 'ec-monitoring-web',
    serverUrl: REACT_APP_APM_SERVER_URL,
    environment: ENVIRONMENTS[REACT_APP_ENV],
    serviceVersion: '1.0.0',
  });
}

console.log(' ')

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById('root')
);
