import React from 'react';
import SkeletonLoader from 'emerald-ui/lib/SkeletonLoader';
import Container from 'emerald-ui/lib/Container';
import Row from 'emerald-ui/lib/Row';
import Col from 'emerald-ui/lib/Col';

const LabelLoader = () => {
  return (
    <Container>
      <Row>
        <Col>
          <SkeletonLoader loading height="150px" />
        </Col>
        <Col>
          <SkeletonLoader loading height="150px" />
        </Col>
        <Col>
          <SkeletonLoader loading height="150px" />
        </Col>
      </Row>
    </Container>
  );
};

const TableLoader = () => {
  return <SkeletonLoader loading width="586px" height="717px" />;
};

export { LabelLoader, TableLoader };
