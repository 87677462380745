import React from 'react';

import Container from 'emerald-ui/lib/Container';
import Row from 'emerald-ui/lib/Row';
import Col from 'emerald-ui/lib/Col';
import Card from 'emerald-ui/lib/Card';
import Button from 'emerald-ui/lib/Button';
import Checkbox from 'emerald-ui/lib/Checkbox';
import styled from 'styled-components';
import moment from 'moment';
import Toast from 'emerald-ui/lib/Toast';
import SearchableSelect from 'emerald-ui/lib/SearchableSelect';

import { downloadFile } from '../../utils/download';
import { downloadNotificationLogs, downloadCustomNotificationLogs } from '../../api/hr';

import DatePicker from 'emerald-ui/lib/DatePicker';
import TextField from 'emerald-ui/lib/TextField';

import UploadFile from './UploadFile';

import { formatDate, parseDate } from 'react-day-picker/moment';

import { EXPORT_EMAILS_LOG } from '../../utils/constants';

const Title = styled.h2`
  text-align: center;
  font-weight: lighter;
`;

const StyledTextField = styled(TextField)`
  margin: 0px auto;
  display: block;
`;

const queueStatuses = ['QUEUED', 'FAILED', 'PROCESSING', 'SEND_EMAIL', 'INVALID_EMAIL', 'EMPTY_EMAIL', 'ERROR_TO_SEND','ERROR_BODY_EMAIL', 'ERROR_TEMPLATE_EMAIL','ERROR','COMPLETED','EMAIL_NOT_SENT','MAILING_DISABLED',`Cannot read property 'data' of undefined`];

class NotificationLogs extends React.Component {
  state = {
    initialDate: null,
    finalDate: null,
    employerId: null,
    loading: false,
    toastMessage: '',
    isCustomNotification: false,
    statusQueue: null
  };

  handleInitialDateChange = (date) => {
    this.setState(({ finalDate }) => {
      const newInitialDate = date && new Date(date);

      return {
        initialDate: newInitialDate,
        finalDate: finalDate && finalDate < newInitialDate ? newInitialDate : finalDate,
      };
    });
  };

  handleFinalDateChange = (date) => {
    this.setState({
      finalDate: date && new Date(date),
    });
  };

  handleEmployerIdChange = (e) => {
    let employerId = !e.target.value ? null : e.target.value;
    this.setState({
      employerId,
    });
  };

  handleIsCustomNotification = (event) => {
    const checked = event.target.checked;
    this.setState({
      isCustomNotification: checked,
    });
  };

  exportNotificationLogs = async () => {
    this.setState({
      loading: true,
    });

    try {
      const { initialDate, finalDate, employerId, isCustomNotification, statusQueue } = this.state;

      const params = {
        dateStart: moment(initialDate).format('MM-DD-YYYY'),
        dateEnd: moment(finalDate).format('MM-DD-YYYY'),
        employerId,
        statusQueue
      };

      const filename = `${employerId || 'All'}-lv-notifications-from-${params.dateStart}-to-${params.dateEnd}.xlsx`;

      const { data } = isCustomNotification
        ? await downloadCustomNotificationLogs(params)
        : await downloadNotificationLogs(params);

      downloadFile(data, filename);
    } catch (error) {
      this.setState({
        toastMessage:
          error.response && error.response.status === 507
            ? EXPORT_EMAILS_LOG.EXCEEDED_NUMBER
            : 'An error ocurred while exporting the data',
        showToast: true,
      });
    } finally {
      this.setState({
        loading: false,
      });
    }
  };

  hideToast = () => {
    this.setState({
      showToast: false,
    });
  };

  handleStatusQueue = (statusQueue) => {
    this.setState({
      statusQueue,
    });
  }

  render() {
    const { finalDate, initialDate, employerId, loading, isCustomNotification, statusQueue } = this.state;

    const buttonDisable = !finalDate || !initialDate || loading;

    const inputStyle = {
      width: '100%',
      height: 35,
    };

    const DATE_FORMAT = 'MM-DD-YYYY';

    return (
      <React.Fragment>
        <Container>
          <Row center>
            <Col xs={12} md={5}>
              <Card>
                <Title className="text-dark">Notification Logs</Title>
                <DatePicker
                  ariaLabel={`Choose date to filter`}
                  label="From"
                  onChange={this.handleInitialDateChange}
                  formatDate={formatDate}
                  parseDate={parseDate}
                  format={DATE_FORMAT}
                  value={initialDate}
                  style={{ marginTop: 20 }}
                  dayPickerProps={{
                    modifiers: {
                      disabled: [{ after: new Date() }],
                    },
                  }}
                  selectsStart
                  endDate={finalDate}
                />
                <DatePicker
                  ariaLabel={`Choose date to filter`}
                  label="To"
                  onChange={this.handleFinalDateChange}
                  formatDate={formatDate}
                  parseDate={parseDate}
                  format={DATE_FORMAT}
                  disabled={!initialDate}
                  selected={finalDate}
                  value={finalDate}
                  minDate={initialDate}
                  dayPickerProps={{
                    modifiers: {
                      disabled: [
                        {
                          before: new Date(initialDate),
                          after: new Date(),
                        },
                      ],
                    },
                  }}
                  selectsEnd
                  startDate={initialDate}
                />
                <StyledTextField
                  label="Employer ID"
                  id="employer-id"
                  autoComplete="off"
                  style={{ ...inputStyle, marginBottom: 20 }}
                  onChange={this.handleEmployerIdChange}
                  value={employerId}
                  maxLength={3}
                />
                {isCustomNotification && <SearchableSelect
                  label="Queue status"
                  id="statuses"
                  style={{ width: '100%', zIndex:100 }}
                  onSelect={this.handleStatusQueue}
                  placeholder="Select..."
                  value={statusQueue}
                  clearable
                  onClear={this.handleStatusQueue}
                >
                  {queueStatuses.map(st => {
                    return (
                      <option key={st} value={st} selected={st === (statusQueue || 'Selected...')}>
                        {st}
                      </option>
                    );
                  })}
                </SearchableSelect>}
                
                <hr></hr>
                <Checkbox
                  id="isCustomNotification-id"
                  label="Custom Notification Logs"
                  defaultChecked={isCustomNotification}
                  onChange={this.handleIsCustomNotification}
                />
                <Button
                  color="info"
                  onClick={this.exportNotificationLogs}
                  disabled={buttonDisable}
                  style={{ marginTop: 45 }}
                  loading={loading}
                >
                  Export notification logs
                </Button>
              </Card>
            </Col>
          </Row>
        </Container>

        {isCustomNotification && <UploadFile />}

        <Toast
          message={this.state.toastMessage}
          visible={this.state.showToast}
          position="right"
          onTimeout={this.hideToast}
        />
      </React.Fragment>
    );
  }
}

export default NotificationLogs;
