import React from 'react';

import Pager from 'emerald-ui/lib/Pager';
import Spinner from 'emerald-ui/lib/Spinner';
import Toast from 'emerald-ui/lib/Toast';
import Container from 'emerald-ui/lib/Container';
import Panel from 'emerald-ui/lib/Panel';

import ImageNowTable from './components/Table';
import ImageNowSummary from './components/Summary';
import Filters from './components/Filters';

import * as hrApi from '../../api/hr';
import { downloadFile } from '../../utils/download';
import moment from 'moment';

let statusColor = {
  queued: {
    name: 'Queued',
    color: '#42a5f5',
    tag: 'info',
    count: 0,
  },
  processing: {
    name: 'Processing',
    color: '#fa0',
    tag: 'warning',
    count: 0,
  },
  completed: {
    name: 'Completed',
    color: '#5bd672',
    tag: 'success',
    count: 0,
  },
  errored: {
    name: 'Failed',
    color: '#ff4e4a',
    tag: 'danger',
    count: 0,
  },
};

class ImageNowDashBoard extends React.Component {
  state = {
    summary: [],
    items: [],
    limit: 10,
    offset: 0,
    size: 10,
    loading: true,
    errorMessage: '',
    employers: [],
    filters: {},
    historyTypes: [],
    filtersRetry: {},
    showToastRetry: false,
    showToastExportError: false,
    textRetryResponse: '',
    textExportError: '',
    downloadingExport: false,
  };

  componentDidMount() {
    const { limit, offset } = this.state;
    this.loadImageNow({ limit, offset });
  }

  loadImageNow = async (params) => {
    this.setState({ loading: true });

    const [imageNow, employers] = await Promise.all([this.getImageNow(params), this.getEmployers()]);
    const { historyTypes, summary, items, size, error: imgError } = imageNow;
    if (imgError)
      return this.setState({
        errorMessage: 'Error loading image now logs',
      });
    const { error: employerError, items: employerItems } = employers;
    if (employerError) this.setState({ errorMessage: 'Error loading employers' });

    this.setState({
      historyTypes,
      summary,
      items,
      size,
      limit: params.limit,
      offset: params.offset,
      loading: false,
      employers: employerItems,
    });
  };

  handleFilter = (paramsWithValues) => {
    const { limit } = this.state;
    this.setState({ loading: true });

    this.getImageNow({ ...paramsWithValues, limit }).then((data) => {
      const { historyTypes, summary, items, size, error: imgError } = data;

      if (imgError) return this.setState({ errorMessage: 'Error loading image now logs ' });

      this.setState({
        historyTypes,
        summary,
        items,
        size,
        limit,
        loading: false,
        filters: paramsWithValues,
      });
    });
  };

  handlePageChange = (goTo) => {
    const { limit, offset, filters } = this.state;
    let newOffset;
    if (goTo === 'next') newOffset = limit + offset;
    else newOffset = offset - limit;
    this.loadImageNow({ limit, offset: newOffset, ...filters });
  };

  handleLimitChange = (newLimit) => {
    const { filters } = this.state;
    this.loadImageNow({ limit: newLimit, offset: 0, ...filters });
  };

  cleanFilters = () => {
    this.setState({ filters: {} });
    this.loadImageNow({ limit: 10, offset: 0 });
  };

  retryItemsFailed = async () => {
    const { filtersRetry } = this.state;
    try {
      const { data } = await hrApi.retryItemsFailed(filtersRetry);

      this.setState({
        showToastRetry: true,
        textRetryResponse: `${data.size ? data.size : 0} element(s) have been queued`,
      });
    } catch (error) {
      this.setState({
        showToastRetry: true,
        textRetryResponse: 'an error has occurred, please try again',
        errorMessage: 'Error loading image now logs ',
      });
    }
  };

  getEmployers = async () => {
    try {
      const { data } = await hrApi.getImageNowEmployers();

      return { items: data };
    } catch (error) {
      return { error: true, items: [] };
    }
  };

  getImageNow = async ({ limit = 10, offset = 0, employerId, historyType, imageStatus, queuedDate }) => {
    try {
      const { data } = await hrApi.getImageNow({
        limit,
        offset,
        employerId,
        historyType,
        imageStatus: imageStatus && imageStatus.toLowerCase(),
        queuedDate: queuedDate ? moment(queuedDate).format('MM/D/YYYY') : moment().format('MM/D/YYYY'),
      });
      const { items, size, statuses, historyTypes } = data;

      const summary = this.mapStatus(statuses);
      return {
        historyTypes,
        summary,
        items,
        size,
      };
    } catch (error) {
      return { error: true };
    }
  };

  mapStatus = (statuses) => {
    for (const status in statusColor) {
      const count = statuses[status] || 0;
      statusColor[status].count = count;
    }
    return statusColor;
  };

  exportImageNowData = async () => {
    try {
      const { filters, items } = this.state;

      if (items.length === 0) {
        this.setState({
          showToastExportError: true,
          textExportError: 'There are no items to export, please apply some filter',
        });
      } else {
        this.setState({ downloadingExport: true });
        let { employerId, historyType, imageStatus, queuedDate } = filters;

        queuedDate = queuedDate ? moment(queuedDate).format('MM/D/YYYY') : moment().format('MM/D/YYYY');
        imageStatus = imageStatus && imageStatus.toLowerCase();

        const { data, headers } = await hrApi.exportImageNow({
          employerId,
          historyType,
          imageStatus,
          queuedDate,
        });
        const contentType = headers['content-type'];

        downloadFile(data, `ImageNow_${Date.now()}.xlsx`, contentType);
        this.setState({ downloadingExport: false });
      }
    } catch (error) {
      this.setState({
        downloadingExport: false,
        showToastExportError: true,
        textExportError: 'The export was not successfully generated',
      });
    }
  };

  render() {
    const {
      items,
      summary,
      limit,
      offset,
      size,
      loading,
      employers,
      filters,
      historyTypes,
      filtersRetry,
      downloadingExport,
      textRetryResponse,
      showToastRetry,
      showToastExportError,
      textExportError,
    } = this.state;
    const filterCount = Object.keys(filters).length;
    return (
      <Container>
        <Panel>
          <Panel.Body>
            <React.Fragment>
              <div style={{ backgroundColor: '#fff' }}>
                <div
                  style={{
                    paddingTop: 20,
                    paddingBottom: 20,
                    paddingLeft: 20,
                    paddingRight: 20,
                  }}
                >
                  <Filters
                    employers={employers}
                    handleFilter={this.handleFilter}
                    cleanFilters={this.cleanFilters}
                    filters={filters}
                    historyTypes={historyTypes}
                    filterCount={filterCount}
                    filtersRetry={filtersRetry}
                    retryItemsFailed={this.retryItemsFailed}
                    exportData={this.exportImageNowData}
                    downloadingExport={downloadingExport}
                  />
                  {loading && (
                    <div style={{ textAlign: 'center' }}>
                      <Spinner size={'md'} />
                    </div>
                  )}
                  {!loading && (
                    <React.Fragment>
                      <ImageNowSummary summary={summary} />
                      <ImageNowTable items={items} statusColor={statusColor} />
                      <Pager
                        offset={offset}
                        limit={limit}
                        onPageChange={this.handlePageChange}
                        onLimitChange={this.handleLimitChange}
                        total={size}
                        style={{ marginTop: 10 }}
                      />
                    </React.Fragment>
                  )}
                  <Toast
                    message={textRetryResponse}
                    actionText="Ok"
                    position="left"
                    onActionClick={() => {
                      this.setState({ showToastRetry: false });
                    }}
                    visible={showToastRetry}
                  />
                  <Toast
                    message={textExportError}
                    actionText="Ok"
                    position="left"
                    onActionClick={() => {
                      this.setState({ showToastExportError: false });
                    }}
                    visible={showToastExportError}
                  />
                </div>
              </div>
            </React.Fragment>
          </Panel.Body>
        </Panel>
      </Container>
    );
  }
}

export default ImageNowDashBoard;
