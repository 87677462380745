import React from 'react';
import styled from 'styled-components';

import Modal from 'emerald-ui/lib/Modal';
import Button from 'emerald-ui/lib/Button';
import Container from 'emerald-ui/lib/Container';
import Row from 'emerald-ui/lib/Row';
import Spinner from 'emerald-ui/lib/Spinner';

import { ButtonContainer, ShortTextField } from './styledComponents';

import * as hrAPI from '../../../api/hr';

const NoCopyText = styled.p`
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

class ConfirmationText extends React.Component {
  state = {
    randomNumber: Math.round(Math.random() * 1000),
    inputValue: null,
    state: 'IDLE',
    showModal: false,
    toastMessage: '',
  };

  hideToast = () => {
    this.setState({
      showToast: false,
    });
  };

  handleInputChange = (event) => {
    this.setState({
      inputValue: event.target.value,
    });
  };

  updateAccount = async (passwordAction) => {
    try {
      this.setState({
        status: 'LOADING',
        showModal: false,
      });

      const accountId = this.props.info.id;

      await hrAPI.changeUserPassword(accountId, {
        passwordAction,
      });

      this.setState({
        status: 'IDLE',
      });

      this.props.onSubmitCallback();
    } catch (error) {
      this.props.onSubmitCallback(error);
    }
  };

  closeModal = () => {
    this.setState({
      showModal: false,
    });
  };

  openReconfirmationModal = () => {
    this.setState({
      showModal: true,
    });
  };

  render() {
    const isLoading = this.state.status === 'LOADING';
    const showButtons = +this.state.inputValue === this.state.randomNumber && !isLoading;
    return (
      <React.Fragment>
        <h3>Are you sure?</h3>
        <NoCopyText>
          You are about to change the information of <b>{this.props.info.login}</b> account. To proceed, please type the
          following number <b>{this.state.randomNumber}</b>.
        </NoCopyText>

        <ShortTextField
          label="Confirmation number"
          id="confirmation-number"
          value={this.state.inputValue}
          onChange={this.handleInputChange}
        />
        {showButtons && (
          <Container>
            <Row>
              <Button style={{ width: '100%' }} onClick={this.openReconfirmationModal} disabled={isLoading}>
                Reset password and unlock
              </Button>
            </Row>
            <Row>
              <Button
                onClick={() => this.updateAccount('UNLOCK_ACCOUNT')}
                style={{ width: '100%', marginTop: 15 }}
                disabled={isLoading}
                color="info"
              >
                Just unlock account
              </Button>
            </Row>
          </Container>
        )}
        {isLoading && (
          <ButtonContainer>
            <Spinner size="sm" />
          </ButtonContainer>
        )}

        <Modal show={this.state.showModal} onHide={this.closeModal}>
          <Modal.Header closeButton>
            <Modal.Title>Reset password</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              Are you really sure you want to reset the password for <b>{this.props.info.login}</b> account?
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button color="info" shape="flat" onClick={this.closeModal}>
              Cancel
            </Button>
            <Button color="info" onClick={() => this.updateAccount('RESET_PASSWORD')}>
              Reset password
            </Button>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  }
}

export default ConfirmationText;
