import React from 'react';
import ErrorModal from '../../components/ErrorModal';
import Dashboard from './components/Dashboard';
import Loading from '../../components/Loading';
import { expiringLicensesList } from '../../api/lv';
import moment from 'moment';

const dateFormat = 'l';
class ExpiringLicenses extends React.Component {
  constructor(props) {
    super(props);
    this.handleLimitChange = this.handleLimitChange.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleDayClick = this.handleDayClick.bind(this);
    this.handleResetClick = this.handleResetClick.bind(this);
    this.state = {
      offset: 0,
      limit: 10,
      totalLicenses: 0,
      status: 'LOADING',
      expiringLicensesInfo: {},
      range: this.getInitialState(),
      dateFormat,
    };
  }

  getInitialState() {
    return {
      from: undefined,
      to: undefined,
    };
  }

  handleDayClick(day, type) {
    let { to } = this.state.range;
    let range = {};
    switch (type) {
      case 'from':
        const newInitialDate = day && new Date(day);
        range = {
          from: newInitialDate,
          to: to && to < newInitialDate ? newInitialDate : to,
        };
        break;
      case 'to':
        range = { ...this.state.range, to: day && new Date(day) };
        break;
      default:
        break;
    }

    this.setState({ range });
    if (range.from && range.to) this.filterByDate({ range });
  }

  handleResetClick() {
    this.setState({ range: this.getInitialState() });
  }

  handleLimitChange(newLimit) {
    this.setState({ limit: newLimit, offset: 0 });
    const { range } = this.state;
    this.filterByDate({ offset: 0, limit: newLimit, range });
  }

  handlePageChange(goTo) {
    const { range, limit, offset } = this.state;
    const newOffset = goTo === 'next' ? offset + limit : offset - limit;
    this.setState({ offset: newOffset });
    this.filterByDate({ limit, offset: newOffset, range });
  }

  async componentDidMount() {
    try {
      const { offset, limit } = this.state;
      const params = { offset, limit };
      const expiringLicensesInfo = await expiringLicensesList(params);
      const {
        data: {
          counts: { TOTAL },
        },
      } = expiringLicensesInfo;
      this.setState({
        totalLicenses: TOTAL,
        expiringLicensesInfo: expiringLicensesInfo.data,
        status: 'LOADED',
      });
    } catch (error) {
      this.setState({
        status: 'ERRORED',
      });
    }
  }

  filterByDate = async ({ offset, limit, range }) => {
    try {
      const { from, to } = range;
      const startDate = moment.isDate(from) ? moment(from).format(dateFormat) : moment().format(dateFormat);
      const endDate = moment.isDate(to) ? moment(to).format(dateFormat) : moment().format(dateFormat);
      const params = { offset, limit, startDate, endDate };

      this.setState({
        status: 'LOADING',
        expiringLicensesInfo: [],
        range,
      });

      const expiringLicensesInfo = await expiringLicensesList(params);
      const {
        data: {
          counts: { TOTAL },
        },
      } = expiringLicensesInfo;
      this.setState({
        totalLicenses: TOTAL,
        expiringLicensesInfo: expiringLicensesInfo.data,
        status: 'LOADED',
      });
    } catch (error) {
      this.setState({
        status: 'ERRORED',
      });
    }
  };

  render() {
    const { status, expiringLicensesInfo, range } = this.state;

    switch (status) {
      case 'LOADED':
        return (
          <Dashboard
            expiringLicensesInfo={expiringLicensesInfo}
            offset={this.state.offset}
            limit={this.state.limit}
            totalLicenses={this.state.totalLicenses}
            handleLimitChange={this.handleLimitChange}
            handlePageChange={this.handlePageChange}
            filterByDate={this.filterByDate}
            handleDayClick={this.handleDayClick}
            handleResetClick={this.handleResetClick}
            getInitialState={this.getInitialState}
            dateFormat={dateFormat}
            range={range}
          />
        );

      case 'LOADING':
        return <Loading message="Searching Licenses about to expire..." />;

      case 'ERRORED':
      default:
        return <ErrorModal />;
    }
  }
}

export default ExpiringLicenses;
