import React from 'react';
import styled from 'styled-components';

import Container from 'emerald-ui/lib/Container';
import Panel from 'emerald-ui/lib/Panel';

import DemographicUploadsTable from './components/DemographicUploadsTable';
import { getDemographicUploadsStatus } from '../../api/hr';

const Title = styled.h1`
  font-weight: lighter;
`;

class DemographicUploadsStatus extends React.Component {
  state = {
    fetchStatus: 'LOADING',
    showModal: false,
    uploadsStatus: [],
    showDetailsModal: false,
    searchTerm: '',
  };

  async componentDidMount() {
    window.scrollTo(0, 0);

    try {
      let { data = {} } = await getDemographicUploadsStatus();
      const { items: uploadsStatus = [] } = data;

      this.setState({
        uploadsStatus,
        fetchStatus: 'LOADED',
      });
    } catch (error) {
      this.setState({
        fetchStatus: 'ERRORED',
        showModal: true,
      });
    }
  }

  render() {
    const { fetchStatus, uploadsStatus } = this.state;

    return (
      <React.Fragment>
        <Container>
          <Title className="text-dark">Demographic Uploads Status</Title>
          <hr />
          <Panel>
            <Panel.Body>
              <div style={{ overflow: 'auto' }}>
                <DemographicUploadsTable fetchStatus={fetchStatus} uploadsStatus={uploadsStatus} />
              </div>
            </Panel.Body>
          </Panel>
        </Container>
      </React.Fragment>
    );
  }
}

export default DemographicUploadsStatus;
