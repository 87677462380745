import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Avatar from 'emerald-ui/lib/Avatar';
import Chip from 'emerald-ui/lib/Chip';
import DropdownItem from 'emerald-ui/lib/DropdownItem';
import DropdownMenu from 'emerald-ui/lib/DropdownMenu';
import TextField from 'emerald-ui/lib/TextField';
import FormControlBorder from 'emerald-ui/lib/FormControls/FormControlBorder';
import FormControlFooter from 'emerald-ui/lib/FormControls/FormControlFooter';
import Animation from 'emerald-ui/lib/Animation';
import findElementNode from 'emerald-ui/lib/utils/findElementNode';

class MultipleSelect extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      options: [],
      selectedOptions: [],
      selectedOption: {},
      showDropdown: props.showDropdown,
      query: '',
      focused: false,
      textFieldHasFocus: true,
    };

    this.dropdownNode = React.createRef();
    this.searchNode = React.createRef();
    this.wrapperNode = React.createRef();
  }

  componentDidMount() {
    this.parseChildren(this.props);

    this.dropdownDOMNode = findElementNode(this.dropdownNode);
    this.searchDOMNode = findElementNode(this.searchNode);
    this.wrapperDOMNode = findElementNode(this.wrapperNode);

    document.addEventListener('click', this.hideDropdownList, false);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.hideDropdownList, false);
  }

  componentDidUpdate(prevProps) {
    if (this.props.children !== prevProps.children) {
      this.parseChildren(this.props);
    }
  }

  setFocusStyle = () => {
    if (!this.props.disabled) {
      this.setState({ focused: true, showDropdown: true });
      this.positionDropdownList();
    } else {
      this.blur();
    }
  };

  setBlurStyle = () => {
    this.setState({ focused: false, showDropdown: false });
  };

  parseChildren = (props) => {
    const { children } = props;

    const { options, selectedOptions, selectedOption } = this.formatChildren(children);

    if (!selectedOption.value && options && options.length > 0) {
      if (options.length > 0 && !options[0].label) {
        selectedOption.caption = options[0].caption;
        selectedOption.value = options[0].value;
        selectedOption.disabled = options[0].disabled;
      } else {
        selectedOption.caption = options[0].items[0].caption;
        selectedOption.value = options[0].items[0].value;
        selectedOption.disabled = options[0].items[0].disabled;
      }
    }

    if (options.length === 0) {
      selectedOption.caption = 'Select...';
    }

    this.setState({
      options,
      selectedOptions,
      selectedOption,
    });
  };

  formatChildren(allChildren, options = [], selectedOptions = [], selectedOption = {}) {
    React.Children.forEach(allChildren, (child, index) => {
      const { value, children, selected, label, disabled, title, captionText, tooltip, ...rest } = child.props;

      if (child.type === 'option') {
        if (typeof children !== 'string' && !captionText) {
          // eslint-disable-next-line no-console
          console.error(
            `You didn't pass a string as an option's child. Use the prop "captionText" to enable search functionality`
          );
        }

        const option = {
          ...rest,
          caption: children,
          captionText,
          value,
          disabled,
          title,
          tooltip,
        };

        options.push(option);

        if (selected) {
          selectedOptions.push(option);
        }
      } else if (child.type === 'optgroup') {
        if (children) {
          const { options: opts, selectedOptions: selOpts } = this.formatChildren(children, [], [], selectedOption);

          const optionGroup = {
            label,
            items: opts.map((item) => {
              return {
                ...item,
                group: index + 1,
              };
            }),
          };

          options.push(optionGroup);

          selectedOptions.push(...selOpts);
        }
      } else {
        // eslint-disable-next-line no-console
        console.error(
          'MultipleSelect only supports option and optgroup as children. All other elements will be discarded.'
        );
      }
    });

    return {
      options,
      selectedOptions,
      selectedOption,
    };
  }

  showDropdownList = () => {
    this.setState({ showDropdown: true }, () => {});
  };

  hideDropdownList = () => {
    this.setState({ showDropdown: false }, () => {});
  };

  focus = () => {
    this.searchDOMNode.focus();
    this.setState({ textFieldHasFocus: true });
    this.showDropdownList();
  };

  blur = () => {
    this.searchDOMNode.blur();
  };

  positionDropdownList() {
    const dropdownStyle = this.dropdownDOMNode.style;

    dropdownStyle.height = 'auto';
    dropdownStyle.maxHeight = '100vh';

    const wrapperPosition = this.wrapperDOMNode.getBoundingClientRect();

    dropdownStyle.width = `${wrapperPosition.width}px`;

    const dropdownPosition = this.dropdownDOMNode.getBoundingClientRect();

    if (dropdownPosition.top + dropdownPosition.height > window.innerHeight) {
      dropdownStyle.maxHeight = `${window.innerHeight - dropdownPosition.top - 20}px`;
    }
  }

  handleSearchChange = (event) => {
    const { onInputChange } = this.props;
    const query = event.target.value;

    this.positionDropdownList();

    this.setState(
      {
        query,
      },
      this.positionDropdownList
    );

    if (onInputChange) {
      onInputChange(query);
    }
  };

  handleOptionDismiss = (value) => {
    this.setState((prevState) => {
      const selectedOptions = prevState.selectedOptions.map((option) => {
        const parsedValue = option.value ? option.value.toString() : '';

        if (parsedValue !== value.split('-')[1]) {
          return option;
        }

        option.unmount = true;

        return option;
      });

      this.handleSelect(selectedOptions);

      return {
        selectedOptions,
      };
    });

    this.positionDropdownList();
    this.focus();
  };

  handleSelect(selectedOptions) {
    const { onSelect } = this.props;

    const realSelectedOptions = [];

    selectedOptions.forEach((option) => {
      if (option.unmount) {
        return;
      }

      delete option.unmount;

      realSelectedOptions.push(option);
    });

    setTimeout(() => {
      if (onSelect) {
        onSelect(realSelectedOptions);
      }
    }, 300);
  }

  handleSearchKeyDown = (event) => {
    const { onKeyDown, cleanOnEnterKeyPress, cleanOnSpaceKeyPress, onCreate, creatable } = this.props;

    const keyCodes = {
      ENTER: 13,
      SPACE: 32,
      ESCAPE: 27,
      TAB: 9,
      UP_ARROW: 38,
      DOWN_ARROW: 40,
    };

    if (event.keyCode === keyCodes.ENTER) {
      event.preventDefault();
    }

    if (onKeyDown) {
      onKeyDown(event);
    }

    const query = this.state.query;

    if (query && (onCreate || creatable) && event.keyCode === keyCodes.ENTER && !this.isQueryInOptions(query)) {
      if (onCreate) {
        onCreate(query);
      }

      const newSelectedOptions = [...this.state.selectedOptions];

      newSelectedOptions.push({ caption: query, value: query, created: true });

      this.setState({ selectedOptions: newSelectedOptions, query: '' });

      this.handleSelect(newSelectedOptions);
    }

    if (
      (event.keyCode === keyCodes.ENTER && cleanOnEnterKeyPress) ||
      (event.keyCode === keyCodes.SPACE && cleanOnSpaceKeyPress)
    ) {
      event.preventDefault();
      this.setState({ query: '' });
    }

    let menuItemsArray;
    let position;

    switch (event.keyCode) {
      case keyCodes.ESCAPE:
      case keyCodes.TAB:
        this.hideDropdownList();
        break;
      case keyCodes.DOWN_ARROW:
      case keyCodes.UP_ARROW:
        event.preventDefault();
        this.showDropdownList();

        menuItemsArray = this.dropdownDOMNode.querySelectorAll('a');

        position = event.keyCode === keyCodes.DOWN_ARROW ? 0 : menuItemsArray.length - 1;
        if (menuItemsArray.length > 0) {
          menuItemsArray[position].focus();
          this.setState({ textFieldHasFocus: false });
        }

        break;
      default:
        this.showDropdownList();
        break;
    }
  };

  handleOptionSelect = (value) => {
    const { onInputChange } = this.props;

    this.positionDropdownList();

    if (onInputChange) {
      onInputChange('');
    }

    const caption = this.getOptionFromValue(this.state.options, value).caption;

    this.setState((prevState) => {
      const selectedOptions = [...prevState.selectedOptions];

      selectedOptions.push({
        caption,
        value,
        unmount: false,
      });

      this.handleSelect(selectedOptions);

      return { selectedOptions, query: '' };
    });

    this.hideDropdownList();

    this.focus();
  };

  getOptionFromValue(options, value) {
    for (const option of options) {
      if (option.label) {
        const tmp = this.getOptionFromValue(option.items, value);

        if (tmp) {
          return tmp;
        }
      } else if (option.value === value) {
        return option;
      }
    }
  }

  renderOptions = (options) => {
    return options.map((option, index) => {
      const { caption, value, items, label, disabled, title, filtered, tooltip, ...rest } = option;

      delete rest.captionText;
      delete rest.unmount;

      if (!label && !value) {
        // eslint-disable-next-line no-console
        console.error(`You must provide a value for ${caption}`);
      }

      const { useAvatar } = this.props;

      if (caption) {
        return (
          <DropdownItem
            {...rest}
            key={`${value}-menuitem-${index}`}
            eventKey={value}
            onSelect={this.handleOptionSelect}
            disabled={disabled}
            title={title}
            className={classnames({
              filtered,
            })}
            ariaLabel={caption}
            tooltip={tooltip}
          >
            <div className="eui-avatar-container">
              {useAvatar ? <Avatar title={caption} /> : null}
              <span role="option" aria-selected="false">
                {caption}
              </span>
            </div>
          </DropdownItem>
        );
      }

      return [
        <DropdownItem separator key={`separator-${index}`} />,
        <DropdownItem header key={`header-${index}`}>
          {label}
        </DropdownItem>,
        ...this.renderOptions(items),
      ];
    });
  };

  getRemainingOptions(options) {
    const { filterByValue } = this.props;

    const { selectedOptions, query } = this.state;
    const filteredOptions = [];

    for (const option of options) {
      const { label, items, value, caption, captionText } = option;

      if (label) {
        const tmpItems = this.getRemainingOptions(items);

        if (tmpItems.length > 0) {
          filteredOptions.push({ label, items: tmpItems });
        }
      } else {
        let notFiltered = true;

        if (typeof caption === 'string') {
          notFiltered = caption && caption.toLowerCase().indexOf(query.toLowerCase()) !== -1;
        }

        if (captionText) {
          notFiltered = captionText && captionText.toLowerCase().indexOf(query.toLowerCase()) !== -1;
        }

        const notFilteredByValue = filterByValue && value && value.toLowerCase().indexOf(query.toLowerCase()) !== -1;

        if (
          (notFiltered && !selectedOptions.some((sel) => sel.value === value && !sel.unmount)) ||
          (notFilteredByValue && !selectedOptions.some((sel) => sel.value === value && !sel.unmount))
        ) {
          filteredOptions.push({ ...option, filtered: true });
        }
      }
    }

    return filteredOptions;
  }

  renderMultipleSelect = (dropdownOptions, searchField) => {
    const { id, label, className, errorMessage, helpText, disabled, useAvatar, ...rest } = this.props;

    delete rest.autoComplete;
    delete rest.cleanOnEnterKeyPress;
    delete rest.cleanOnSpaceKeyPress;
    delete rest.filterByValue;
    delete rest.onInputChange;
    delete rest.showDropdown;
    delete rest.onCreate;
    delete rest.creatable;
    delete rest.onSelect;

    const selectedOptions = this.state.selectedOptions.map(({ value, caption, unmount, created }, index) => (
      <Animation key={`animation-${value}`} unmount={unmount} effect={created ? 'slideFromRight' : 'slideFromBottom'}>
        <Chip
          key={`${value}-${index}`}
          id={`${id}-${value}`}
          onFocus={this.setFocusStyle}
          onClick={this.handleElementClick}
          onBlur={this.setBlurStyle}
          disabled={disabled}
          onDismiss={this.handleOptionDismiss}
          ariaLabel={caption}
        >
          {useAvatar ? <Avatar title={caption} /> : null}
          {caption}
        </Chip>
      </Animation>
    ));

    selectedOptions.push(searchField);

    return (
      <div
        {...rest}
        className={classnames('eui-multiple-select', {
          error: errorMessage,
          focused: this.state.showDropdown || this.state.focused,
          disabled,
        })}
        ref={this.wrapperNode}
        aria-invalid={errorMessage && !!errorMessage}
        aria-errormessage={errorMessage && `${id}ErrorMessage`}
        style={{ zIndex: 5 }}
      >
        <div className="eui-select-field-wrapper">
          <div className={classnames('eui-select-field', className)}>
            <div className="selected-options">{selectedOptions}</div>
            <div className="menu-wrapper">
              <DropdownMenu
                onHide={() => {
                  this.focus();
                  this.hideDropdownList();
                }}
                onClick={this.handleElementClick}
                onFocus={this.setFocusStyle}
                onScroll={this.handleMenuScroll}
                ref={this.dropdownNode}
                show={this.state.showDropdown}
              >
                {dropdownOptions}
              </DropdownMenu>
            </div>
          </div>
          <FormControlBorder
            label={label}
            id={id}
            focused={this.state.showDropdown || this.state.focused}
            error={!!errorMessage}
          />
          <FormControlFooter id={id} errorMessage={errorMessage} helpText={helpText} />
        </div>
      </div>
    );
  };

  handleElementClick = () => {
    this.positionDropdownList();
    setTimeout(() => {
      this.showDropdownList();
    }, 1);
  };

  isQueryInOptions = (query) => {
    return this.getRemainingOptions(this.state.options).find((option) => option.caption === query);
  };

  render() {
    const { autoComplete, disabled, id, placeholder, onCreate, creatable, label, ariaLabel } = this.props;

    const dropdownOptions = this.renderOptions(this.getRemainingOptions(this.state.options));

    const query = this.state.query;

    if (this.state.textFieldHasFocus && query && (onCreate || creatable) && !this.isQueryInOptions(query)) {
      dropdownOptions.unshift(
        <DropdownItem header key="header-not-found">
          {`Press Enter to create "${query}"`}
        </DropdownItem>
      );
    }

    if (dropdownOptions && dropdownOptions.length === 0) {
      dropdownOptions.push(
        <DropdownItem header key="header-not-found">
          {!(onCreate && creatable) && 'No options found...'}
        </DropdownItem>
      );
    }

    const searchField = (
      <TextField
        autoComplete={autoComplete}
        id={`${id}MultipleSelectSearch`}
        key="search-field"
        onChange={this.handleSearchChange}
        onClick={this.handleElementClick}
        onKeyDown={this.handleSearchKeyDown}
        onFocus={this.setFocusStyle}
        onBlur={this.setBlurStyle}
        placeholder={placeholder || 'Search'}
        ref={this.searchNode}
        role="searchbox"
        tabIndex={disabled ? '-1' : '0'}
        value={this.state.query}
        ariaLabel={`${label || ariaLabel} search`}
      />
    );

    return this.renderMultipleSelect(dropdownOptions, searchField);
  }
}

MultipleSelect.propTypes = {
  // ** autoComplete: One of <'on'>, <'off'> : HTML5 autocomplete. This prop will be passed down to the <TextField> child component
  autoComplete: PropTypes.oneOf(['on', 'off']),
  // ** id: One of <Number> or <String> : Id of the component
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  // ** children: Node : Series of <option> or <optgroup> elements. These form the dropdown list of items
  children: PropTypes.node,
  // ** onSelect: Function : Callback for when an item is selected. <onSelect(selectedOptions)> where <selectedOptions> is a list of all the selected options. This is called even when a new item is created (passing the <onCreate> callback) since it is automatically selected.
  onSelect: PropTypes.func,
  // ** showDropdown: Boolean : Whether or not the dropdown list is shown
  showDropdown: PropTypes.bool,
  // ** onClick: Function : Callback for when the search field is clicked
  onClick: PropTypes.func,
  // ** onKeyDown: Function : Callback for when a key is pressed
  onKeyDown: PropTypes.func,
  // ** label: String : Label that accompanies the component
  label: PropTypes.string,
  // ** helpText: String : Text that appears below the component for helping purposes
  helpText: PropTypes.string,
  // ** errorMessage: Any : Text that appears below the component to indicate an error
  errorMessage: PropTypes.any,
  // ** disabled: Boolean : Renders an unclickable, unfocusable <MultipleSelect> component
  disabled: PropTypes.bool,
  // ** onInputChange: Function : Callback for when the search input changes. <onInputChange(query)> where <query> is the typed text
  onInputChange: PropTypes.func,
  // ** filterByValue: Boolean : Whether or not to filter by the option value. Normally, options are only filtered by their caption
  filterByValue: PropTypes.bool,
  // ** useAvatar: Boolean : Renders an <Avatar> with the caption as title
  useAvatar: PropTypes.bool,
  // ** className: String : CSS class name for the component
  className: PropTypes.string,
  // ** cleanOnEnterKeyPress: Boolean : Cleans the Text input field when the Enter key is pressed
  cleanOnEnterKeyPress: PropTypes.bool,
  // ** cleanOnSpaceKeyPress: Boolean : Cleans the Text input field when the Space key is pressed
  cleanOnSpaceKeyPress: PropTypes.bool,
  // ** placeholder: String : Placeholder for the search field
  placeholder: PropTypes.string,
  // ** ariaLabel: String : The label the screen readers will read. This is used for accessibility purposes
  ariaLabel: PropTypes.string,
  // ** creatable: Boolean : This allows the creation of new items by typing them in the TextField. You can pass this prop and not pass onCreate if you don't need to know which item was created at any given moment
  creatable: PropTypes.bool,
  // ** onCreate: Function : Callback for when an option is created. <onCreate(option)> where <option> is the created option (String). Note that this is called when you press Enter, the textField is not empty and the typed text doesn't match an existing option
  onCreate: PropTypes.func,
};

MultipleSelect.defaultProps = {
  disabled: false,
};

export default MultipleSelect;
