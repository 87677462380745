import React from 'react';
import Panel from 'emerald-ui/lib/Panel';
import StateExpansion from './StateExpansion';
import Export from './Export';
import Loading from '../../../components/Loading';
import Row from 'emerald-ui/lib/Row';
import Col from 'emerald-ui/lib/Col';
import Container from 'emerald-ui/lib/Container';
import DatePicker from 'emerald-ui/lib/DatePicker';
import moment from 'moment';
import 'react-day-picker/lib/style.css';
import Tab from 'emerald-ui/lib/Tab';
import Nav from 'emerald-ui/lib/Nav';
import './ScreenShot.css';

const Dashboard = ({
  missingScreenshotStatus,
  screenshot404Status,
  capturedScreenshotStatus,
  statesWithMissingScreenshots,
  statesWithCapturedScreenshots,
  statesWith404Screenshots,
  filterByDate,
  dateToFilter,
}) => {
  let totalMissingScreenshots = 0;
  let total404Screenshots = 0;
  let totalCapturedScreenshots = 0;

  if (missingScreenshotStatus && missingScreenshotStatus === 'LOADED')
    Object.keys(statesWithMissingScreenshots).forEach((state) => {
      totalMissingScreenshots += statesWithMissingScreenshots[state].totalScreenshots;
    });

  if (screenshot404Status && screenshot404Status === 'LOADED')
    Object.keys(statesWith404Screenshots).forEach((state) => {
      total404Screenshots += statesWith404Screenshots[state].totalScreenshots;
    });

  if (capturedScreenshotStatus && capturedScreenshotStatus === 'LOADED')
    Object.keys(statesWithCapturedScreenshots).forEach((state) => {
      totalCapturedScreenshots += statesWithCapturedScreenshots[state].totalScreenshots;
    });

  const handlerFilterByDate = (day) => {
    dateToFilter = moment(day).format('YYYY-MM-DD');
    filterByDate(dateToFilter);
  };

  return (
    <Container>
      <Panel>
        <Panel.Body>
          <Row className="container-screenshot">
            <Col lg={8} xs={12} md={8} sm={12}>
              <h1 className="text-dark">Screenshot Reports</h1>
            </Col>
            <Col xs={6} md={4} sm={6} lg={4} className="container-date-picker">
              <DatePicker
                ariaLabel={`Choose date to filter`}
                onChange={handlerFilterByDate}
                value={dateToFilter}
                label="Date filter"
              />
            </Col>
          </Row>
        </Panel.Body>
      </Panel>

      <Tab.Container defaultActiveKey="first">
        <Nav>
          <a id="first" href="/">
            <b>
              {missingScreenshotStatus === 'LOADING' ? (
                <span role="img" aria-label="searching">
                  🔍
                </span>
              ) : (
                totalMissingScreenshots
              )}
              &nbsp;
            </b>
            Missing Screenshots
            {totalMissingScreenshots > 0 ? <Export type="missing" dateToFilter={dateToFilter} /> : null}
          </a>
          <a id="second" href="/">
            <b>
              {screenshot404Status === 'LOADING' ? (
                <span role="img" aria-label="searching">
                  🔍
                </span>
              ) : (
                total404Screenshots
              )}
              &nbsp;
            </b>
            Not Found Screenshots
            {total404Screenshots > 0 ? <Export type="404" dateToFilter={dateToFilter} /> : null}
          </a>
          <a id="third" href="/">
            <b>
              {capturedScreenshotStatus === 'LOADING' ? (
                <span role="img" aria-label="searching">
                  🔍
                </span>
              ) : (
                totalCapturedScreenshots
              )}
              &nbsp;
            </b>
            Captured Screenshots
            {totalCapturedScreenshots > 0 ? <Export type="captured" dateToFilter={dateToFilter} /> : null}
          </a>
        </Nav>
        <div className="bg-body screenshot-status">
          <Tab.Content>
            <Tab.Pane eventKey="first">
              {missingScreenshotStatus === 'LOADING' ? (
                <Loading meesage="Searching screenshots information..." />
              ) : Object.keys(statesWithMissingScreenshots).length ? (
                Object.keys(statesWithMissingScreenshots).map((state, i) => {
                  return (
                    <StateExpansion
                      key={i}
                      stateName={state}
                      status={missingScreenshotStatus}
                      stats={statesWithMissingScreenshots[state]}
                      type={'missing'}
                      dateToFilter={dateToFilter}
                    />
                  );
                })
              ) : (
                <b>
                  No missing screenshots{' '}
                  <span role="img" aria-label="celebrate">
                    🎉
                  </span>
                </b>
              )}
            </Tab.Pane>
            <Tab.Pane eventKey="second">
              {screenshot404Status === 'LOADING' ? (
                <Loading />
              ) : Object.keys(statesWith404Screenshots).length ? (
                Object.keys(statesWith404Screenshots).map((state, i) => {
                  return (
                    <StateExpansion
                      key={i}
                      stateName={state}
                      status={screenshot404Status}
                      stats={statesWith404Screenshots[state]}
                      type={'404'}
                      dateToFilter={dateToFilter}
                    />
                  );
                })
              ) : (
                <b>
                  No 404 screenshots{' '}
                  <span role="img" aria-label="celebrate">
                    🎉
                  </span>
                </b>
              )}
            </Tab.Pane>
            <Tab.Pane eventKey="third">
              {capturedScreenshotStatus === 'LOADING' ? (
                <Loading />
              ) : Object.keys(statesWithCapturedScreenshots).length ? (
                Object.keys(statesWithCapturedScreenshots).map((state, i) => {
                  return (
                    <StateExpansion
                      key={i}
                      stateName={state}
                      status={capturedScreenshotStatus}
                      stats={statesWithCapturedScreenshots[state]}
                      type={'captured'}
                      dateToFilter={dateToFilter}
                    />
                  );
                })
              ) : (
                <b>
                  No captured screenshots{' '}
                  <span role="img" aria-label="flushed-face">
                    😳
                  </span>
                </b>
              )}
            </Tab.Pane>
          </Tab.Content>
        </div>
      </Tab.Container>
    </Container>
  );
};

export default Dashboard;
