import React from 'react';

import Container from 'emerald-ui/lib/Container';
import Row from 'emerald-ui/lib/Row';
import Modal from 'emerald-ui/lib/Modal';
import Table from 'emerald-ui/lib/Table';
import Label from 'emerald-ui/lib/Label';
import IconButton from 'emerald-ui/lib/IconButton';
import * as lvAPI from '../../../api/lv';

const exportLic = async (params, type) => {
  const { boardName, stateCode } = params;

  lvAPI.getLicsByReportType({ boardName, stateCode, type });
};

const DetailsModal = (props) => {
  const { boardStats = {} } = props;
  const { count, processed, notFound, serverError, newLv, verifiedNewLV, verifiedOldLV, oldLv, boardName } = boardStats;

  const processedPercentage = ((processed / count) * 100).toFixed(2);

  const notFoundPercentage = ((notFound / count) * 100).toFixed(2);
  const serverErrorPercentage = ((serverError / count) * 100).toFixed(2);
  const newLvPercentage = ((newLv / count) * 100).toFixed(2);
  const newLvVerificationsPercentage = ((verifiedNewLV / newLv) * 100).toFixed(2);
  const oldLvVerificationsPercentage = ((verifiedOldLV / oldLv) * 100).toFixed(2);

  const firstVerifiedAt = boardStats.firstVerifiedAt
    ? new Date(boardStats.firstVerifiedAt).toLocaleString()
    : 'None given';

  const lastVerifiedAt = boardStats.lastVerifiedAt
    ? new Date(boardStats.lastVerifiedAt).toLocaleString()
    : 'None given';

  return (
    <Modal show={props.show} onHide={props.onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{boardName}</Modal.Title>
      </Modal.Header>

      <Container>
        <Row
          center
          style={{
            margin: '40px 0',
          }}
        >
          <h2 className="text-dark" style={{ fontWeight: 'lighter', margin: 0 }}>
            Board report
          </h2>
          {processedPercentage === 100 && (
            <Label style={{ marginLeft: '15px' }} color="default">
              Completed
            </Label>
          )}
        </Row>
      </Container>

      <Table striped>
        <tbody>
          <tr>
            <td>
              Licenses <b>Processed</b>
            </td>
            <td>{processedPercentage}%</td>
            <td>{processed}</td>
            <td>
              <IconButton
                icon="file_download"
                onClick={() => exportLic(boardStats, 'PROCESSED')}
                disabled={!processed}
              />
            </td>
          </tr>
          <tr>
            <td>
              Licenses <b>Not Found</b>
            </td>
            <td>{notFoundPercentage}%</td>
            <td>{notFound}</td>
            <td>
              <IconButton
                icon="file_download"
                onClick={() => exportLic(boardStats, 'NOT_FOUND')}
                disabled={!notFound}
              />
            </td>
          </tr>
          <tr>
            <td>
              Licenses with <b>Server Errors</b>
            </td>
            <td>{serverErrorPercentage}%</td>
            <td>{serverError}</td>
            <td>
              <IconButton
                icon="file_download"
                onClick={() => exportLic(boardStats, 'SERVER_ERROR')}
                disabled={!serverError}
              />
            </td>
          </tr>
          <tr>
            <td>
              <b>New LV</b> licenses
            </td>
            <td>{newLvPercentage}%</td>
            <td>{newLv}</td>
            <td>
              <IconButton icon="file_download" onClick={() => exportLic(boardStats, 'NEW_LV')} disabled={!newLv} />
            </td>
          </tr>
          {!!newLv && (
            <tr>
              <td>
                licenses verified by <b>New LV</b>
              </td>
              <td>{newLvVerificationsPercentage}%</td>
              <td>{verifiedNewLV}</td>
              <td>
                <IconButton
                  icon="file_download"
                  onClick={() => exportLic(boardStats, 'VERIFIED_BY_NEW_LV')}
                  disabled={!verifiedNewLV}
                />
              </td>
            </tr>
          )}
          <tr>
            <td>
              <b>Old LV</b> licenses
            </td>
            <td>{100 - newLvPercentage}%</td>
            <td>{oldLv}</td>
            <td>
              <IconButton icon="file_download" onClick={() => exportLic(boardStats, 'OLD_LV')} disabled={!oldLv} />
            </td>
          </tr>
          {!!oldLv && (
            <tr>
              <td>
                licenses verified by <b>Old LV</b>
              </td>
              <td>{oldLvVerificationsPercentage}%</td>
              <td>{verifiedOldLV}</td>
              <td>
                <IconButton
                  icon="file_download"
                  onClick={() => exportLic(boardStats, 'VERIFIED_BY_OLD_LV')}
                  disabled={!verifiedOldLV}
                />
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      <Modal.Body>
        <p>
          First license verified at: <b>{firstVerifiedAt}</b>
          <br />
          Last license verified at: <b>{lastVerifiedAt}</b>
          <br />
          <br />
          <b>FYI:</b> These changes do not get updated automatically, if you need a more recent report you have to
          refresh.
        </p>
      </Modal.Body>
    </Modal>
  );
};

export default DetailsModal;
