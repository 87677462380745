import React from 'react';
import Label from 'emerald-ui/lib/Label';
import Table from 'emerald-ui/lib/Table';

const renderRows = (items, statusColor) => {
  if (!items.length) {
    return (
      <tbody>
        <tr style={{ textAlign: 'center' }}>
          <td colSpan={8}>There are no items to show</td>
        </tr>
      </tbody>
    );
  }

  return (
    <tbody>
      {items.map((it) => {
        const { name: labelText, tag } = statusColor[it.imageStatus];
        return (
          <tr key={it.id}>
            <td>{it.employerName}</td>
            <td>{it.historyType}</td>
            <td>
              {it.employee.firstName} {it.employee.lastName}
            </td>
            <td>{it.employee.employeeNumber}</td>
            <td>{it.employee.licenseNumber}</td>
            <td>{it.employee.state}</td>
            <td>{it.employee.profession}</td>
            <td>
              <Label color={tag}>{labelText}</Label>
            </td>
          </tr>
        );
      })}
    </tbody>
  );
};
const ImageNowTable = ({ items, statusColor }) => {
  return (
    <Table style={{ marginTop: 10 }}>
      <thead>
        <tr>
          <th>Employer</th>
          <th>History type</th>
          <th>Employee</th>
          <th>Employee #</th>
          <th>License #</th>
          <th>State</th>
          <th>Profession</th>
          <th>status</th>
        </tr>
      </thead>

      {renderRows(items, statusColor)}
    </Table>
  );
};

export default ImageNowTable;
