import React from 'react';

import Button from 'emerald-ui/lib/Button';
import styled from 'styled-components';
import moment from 'moment';
import Toast from 'emerald-ui/lib/Toast';

import { downloadFile } from '../../utils/download';
import { downloadLvNotificationsFile } from '../../api/lv';

import DatePicker from 'emerald-ui/lib/DatePicker';
import Container from 'emerald-ui/lib/Container';
import Row from 'emerald-ui/lib/Row';
import Col from 'emerald-ui/lib/Col';
import Card from 'emerald-ui/lib/Card';

const Title = styled.h2`
  text-align: center;
  font-weight: lighter;
`;

class ExportNotificationFile extends React.Component {
  state = {
    initialDate: null,
    finalDate: null,
    loading: false,
    toastMessage: '',
  };

  handleInitialDateChange = (date) => {
    this.setState(({ finalDate }) => {
      const newInitialDate = date && new Date(date);

      return {
        initialDate: newInitialDate,
        finalDate: finalDate && finalDate < newInitialDate ? newInitialDate : finalDate,
      };
    });
  };

  handleFinalDateChange = (date) => {
    this.setState({
      finalDate: date && new Date(date),
    });
  };

  exportLVNotifications = async () => {
    this.setState({
      loading: true,
    });

    try {
      const { initialDate, finalDate } = this.state;

      const params = {
        initialDate: moment(initialDate).format('MM-DD-YYYY'),
        finalDate: moment(finalDate).format('MM-DD-YYYY'),
      };

      const filename = `lv-notifications-from-${params.initialDate}-to-${params.finalDate}.xlsx`;

      const { data } = await downloadLvNotificationsFile(params);

      downloadFile(data, filename);
    } catch (error) {
      this.setState({
        toastMessage: 'An error ocurred while exporting the data',
        showToast: true,
      });
    } finally {
      this.setState({
        loading: false,
      });
    }
  };

  hideToast = () => {
    this.setState({
      showToast: false,
    });
  };

  render() {
    const { finalDate, initialDate, loading } = this.state;

    const buttonDisable = !finalDate || !initialDate || loading;

    const datePickerStyle = {
      width: 200,
      height: 35,
    };

    return (
      <React.Fragment>
        <Container>
          <Row center>
            <Col xs={12} sm={5}>
              <Card>
                <Title>LV Notifications Export</Title>
                <DatePicker
                  ariaLabel={`Choose date to filter`}
                  label="From"
                  onChange={this.handleInitialDateChange}
                  selected={initialDate}
                  value={initialDate}
                  inputProps={{ style: datePickerStyle }}
                  style={{ marginTop: 20 }}
                  dayPickerProps={{
                    modifiers: {
                      disabled: [{ after: new Date() }],
                    },
                  }}
                  selectsStart
                  endDate={finalDate}
                />
                <DatePicker
                  ariaLabel={`Choose date to filter`}
                  label="To"
                  onChange={this.handleFinalDateChange}
                  disabled={!initialDate}
                  selected={finalDate}
                  value={finalDate}
                  minDate={initialDate}
                  dayPickerProps={{
                    modifiers: {
                      disabled: [
                        {
                          before: new Date(this.state.initialDate),
                          after: new Date(),
                        },
                      ],
                    },
                  }}
                  selectsEnd
                  startDate={initialDate}
                />
                <br />
                <Button
                  color="info"
                  onClick={this.exportLVNotifications}
                  disabled={buttonDisable}
                  style={{ marginTop: 20 }}
                  loading={this.state.loading}
                >
                  Export LV notifications
                </Button>
              </Card>
            </Col>
          </Row>
        </Container>

        <Toast
          message={this.state.toastMessage}
          visible={this.state.showToast}
          position="right"
          onTimeout={this.hideToast}
        />
      </React.Fragment>
    );
  }
}

export default ExportNotificationFile;
