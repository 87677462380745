import TextField from 'emerald-ui/lib/TextField';
import styled from 'styled-components';

export const ButtonContainer = styled.div`
  text-align: center;
`;

export const ShortTextField = styled(TextField)`
  margin-top: 0 !important;
`;
