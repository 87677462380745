import axios from 'axios';
import moment from 'moment';

import { getAuthToken } from '../utils/auth';
import { convertObjectToQueryString } from '../utils/url';

export const API = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

const getHeader = () => {
  return {
    headers: { Authorization: getAuthToken() },
  };
};

export const runUpload = (params = {}) => {
  return API.post('/prehire/upload/run', params, { ...getHeader() });
};

export const getUploadHistories = (params = {}) => {
  return API.get('/prehire/upload-history', { params, ...getHeader() });
};

export const getVerifyingCredentialsByBoards = ({ limit, offset = 0 }) => {
  return API.get(`/prehire/verifying-credentials${convertObjectToQueryString({ limit, offset })}`, {
    ...getHeader(),
  });
};

export const getVerifyingExport = (boardId) => {
  const fileName = `Verifying credentials ${moment().format('MM-DD-YYYY_HHmmss')}.xlsx`;

  API.get('/prehire/verifying-credentials/export', {
    params: {
      boardId,
      authorization: getAuthToken(),
    },
    responseType: 'blob',
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
  });
};
