import React from 'react';
import Panel from 'emerald-ui/lib/Panel';
import Tab from 'emerald-ui/lib/Tab';
import Nav from 'emerald-ui/lib/Nav';
import Row from 'emerald-ui/lib/Row';
import Col from 'emerald-ui/lib/Col';
import Container from 'emerald-ui/lib/Container';
import DatePicker from 'emerald-ui/lib/DatePicker';
import StateExpansion from './StateExpansion';
import Export from './Export';
import Loading from '../../../components/Loading';
import moment from 'moment';
import 'react-day-picker/lib/style.css';
import './MonthlyScreenshotStatus.css';

const Dashboard = ({
  scheduledMonthlyScreenshotStatus,
  addedMonthlyScreenshotStatus,
  statesWithScheduledMonthlyScreenshots,
  statesWithAddedMonthlyScreenshots,
  filterByDate,
  dateToFilter,
}) => {
  let totalScheduledScreenshots = 0;
  let totalAddedScreenshots = 0;

  if (scheduledMonthlyScreenshotStatus && scheduledMonthlyScreenshotStatus === 'LOADED')
    Object.keys(statesWithScheduledMonthlyScreenshots).forEach((state) => {
      totalScheduledScreenshots += statesWithScheduledMonthlyScreenshots[state].totalScreenshots;
    });

  if (addedMonthlyScreenshotStatus && addedMonthlyScreenshotStatus === 'LOADED')
    Object.keys(statesWithAddedMonthlyScreenshots).forEach((state) => {
      totalAddedScreenshots += statesWithAddedMonthlyScreenshots[state].totalScreenshots;
    });

  const handlerFilterByDate = (day) => {
    let dateToFilter = moment(day).format('YYYY-MM-DD');
    filterByDate(dateToFilter);
  };

  return (
    <Container>
      <Panel>
        <Panel.Body>
          <Row className="container-reports">
            <Col lg={9} xs={12} md={8} sm={12}>
              <h1 className="text-dark">Monthly Screenshot Reports</h1>
            </Col>
            <Col xs={6} md={4} sm={6} lg={3}>
              <DatePicker
                ariaLabel={`Choose date to filter`}
                onChange={handlerFilterByDate}
                value={dateToFilter}
                label="Date filter"
              />
            </Col>
          </Row>
        </Panel.Body>
      </Panel>

      <Tab.Container defaultActiveKey="first">
        <Nav className="monthly-screenshot-nav">
          <a id="first" href="/">
            <b>
              {scheduledMonthlyScreenshotStatus === 'LOADING' ? (
                <span role="img" aria-label="searching">
                  🔍
                </span>
              ) : (
                totalScheduledScreenshots
              )}
              &nbsp;
            </b>
            Scheduled
            {totalScheduledScreenshots > 0 ? <Export type="scheduled" dateToFilter={dateToFilter} /> : null}
          </a>
          <a id="second" href="/">
            <b>
              {addedMonthlyScreenshotStatus === 'LOADING' ? (
                <span role="img" aria-label="searching">
                  🔍
                </span>
              ) : (
                totalAddedScreenshots
              )}
              &nbsp;
            </b>
            Added
            {totalAddedScreenshots > 0 ? <Export type="added" dateToFilter={dateToFilter} /> : null}
          </a>
        </Nav>
        <div className="bg-body monthly-screenshot-status">
          <Tab.Content>
            <Tab.Pane eventKey="first" className="wrapper-dashboard">
              {scheduledMonthlyScreenshotStatus === 'LOADING' ? (
                <Loading message="Searching Monthly Screenshots information..." />
              ) : Object.keys(statesWithScheduledMonthlyScreenshots).length ? (
                Object.keys(statesWithScheduledMonthlyScreenshots).map((state, i) => {
                  return (
                    <StateExpansion
                      key={i}
                      stateName={state}
                      status={scheduledMonthlyScreenshotStatus}
                      stats={statesWithScheduledMonthlyScreenshots[state]}
                      type={'scheduled'}
                      dateToFilter={dateToFilter}
                    />
                  );
                })
              ) : (
                <b>No found Scheduled Monthly Screenshots</b>
              )}
            </Tab.Pane>
            <Tab.Pane eventKey="second" className="wrapper-dashboard">
              {addedMonthlyScreenshotStatus === 'LOADING' ? (
                <Loading />
              ) : Object.keys(statesWithAddedMonthlyScreenshots).length ? (
                Object.keys(statesWithAddedMonthlyScreenshots).map((state, i) => {
                  return (
                    <StateExpansion
                      key={i}
                      stateName={state}
                      status={addedMonthlyScreenshotStatus}
                      stats={statesWithAddedMonthlyScreenshots[state]}
                      type={'added'}
                      dateToFilter={dateToFilter}
                    />
                  );
                })
              ) : (
                <b>No found Added Monthly Screenshots</b>
              )}
            </Tab.Pane>
          </Tab.Content>
        </div>
      </Tab.Container>
    </Container>
  );
};

export default Dashboard;
