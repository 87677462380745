import React from 'react';

import ExportNotificationFile from './ExportNotificationFile';
import UploadFile from './UploadFile';

const AuditLvNotifications = () => {
  return (
    <React.Fragment>
      <ExportNotificationFile />
      <UploadFile />
    </React.Fragment>
  );
};

export default AuditLvNotifications;
