import React from 'react';

import moment from 'moment';
import Card from 'emerald-ui/lib/Card';
import Alert from 'emerald-ui/lib/Alert';
import Table from 'emerald-ui/lib/Table';

const ShowPostTables = ({ postStatus }) => {
  const postSince = postStatus && moment.unix(postStatus.since.seconds).format('llll');

  return (
    <React.Fragment>
      <Table condensed>
        <thead>
          <tr>
            <th>Target</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{postStatus && postStatus.queue}</td>
            <td>{postStatus && postStatus.status}</td>
          </tr>
        </tbody>
      </Table>
      <br />
      <Table condensed>
        <thead>
          <tr>
            <th>Post</th>
            <th>Since</th>
            <th>Total</th>
            <th>Backlog</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{postStatus && postStatus.posted}</td>
            <td>{postSince}</td>
            <td>{postStatus && postStatus.total}</td>
            <td>{postStatus && postStatus.backlog}</td>
          </tr>
        </tbody>
      </Table>
    </React.Fragment>
  );
};

const ShowPostCard = ({ showPost }) => {
  const { host, information: postStatus } = showPost;

  const lastTransationDate = postStatus && moment.unix(postStatus.lastTransaction.sourceTime.seconds).format('llll');

  return (
    <Card>
      <Card.Header color="info">
        <h2 className="text-off-white-dark">SHOW POST</h2>
      </Card.Header>

      <div>
        <h2>Host: {host}</h2>
        <hr />
        <h3>
          <b>Source:</b> {postStatus && postStatus.source} - <b>Queue:</b> {postStatus && postStatus.queue}
        </h3>
        <br />
        <ShowPostTables postStatus={postStatus} />
        <h3>Last operation posted:</h3>
        <Alert color="info">
          <p>
            <i>{postStatus && postStatus.lastOperation.value}</i>
            <br />
            <b>Redo log:</b> {postStatus && postStatus.lastOperation.redo} <b>Log offset:</b>{' '}
            {postStatus && postStatus.lastOperation.offset}
          </p>
        </Alert>

        <h3>Last transaction posted:</h3>
        <Alert color="info">
          <p>
            <b>SCN:</b> {postStatus && postStatus.lastTransaction.scn} <b>Source Time:</b> {lastTransationDate}
            <br />
            <b>Redo log:</b> {postStatus && postStatus.lastTransaction.redo} <b>Log offset:</b>{' '}
            {postStatus && postStatus.lastTransaction.offset}
          </p>
        </Alert>
      </div>
    </Card>
  );
};

export default ShowPostCard;
