import React from 'react';

import ExpansionPanel from 'emerald-ui/lib/ExpansionPanel';
import Spinner from 'emerald-ui/lib/Spinner';
import Table from 'emerald-ui/lib/Table';
import Label from 'emerald-ui/lib/Label';
import moment from 'moment';

import * as lvAPI from '../../../api/lv';
import { convertQueryStringToObject } from '../../../utils/url';

const DateLabel = ({ date, format = 'llll' }) => {
  return date ? (
    <Label color="success"> {moment(date).format(format)}</Label>
  ) : (
    <Label color="warning">None given</Label>
  );
};

class StateSummaryPanel extends React.Component {
  state = {
    stateStats: {},
    status: 'LOADING',
  };

  async componentDidMount() {
    try {
      const {
        location: { search: queryStrings },
      } = this.props;

      const queryStringObject = convertQueryStringToObject(queryStrings);

      const { data: stateStats } = await lvAPI.getStateStats(queryStringObject);

      this.setState({
        stateStats: stateStats[0],
        status: 'LOADED',
      });
    } catch (error) {
      this.setState({
        status: 'ERRORED',
      });
    }
  }

  render() {
    const { stateStats, status } = this.state;

    const {
      count,
      processed,
      notFound,
      serverError,
      newLv,
      verifiedNewLV,
      oldLv,
      verifiedOldLV,
      stateCode,
      stateName,
      firstVerifiedAt,
      lastVerifiedAt,
    } = stateStats;

    const processedPercentage = ((processed / count) * 100).toFixed(2);
    const notFoundPercentage = ((notFound / count) * 100).toFixed(2);
    const serverErrorPercentage = ((serverError / count) * 100).toFixed(2);
    const newLvPercentage = ((newLv / count) * 100).toFixed(2);
    const oldLvPercentage = (100 - (newLv / count) * 100).toFixed(2);
    const verifiedByNewLV = ((verifiedNewLV / newLv) * 100).toFixed(2);
    const verifiedByOldLV = ((verifiedOldLV / oldLv) * 100).toFixed(2);

    const isLoading = status === 'LOADING';
    const errorOcurred = status === 'ERRORED';
    const isLoaded = status === 'LOADED';

    return (
      <div style={{ padding: '0 32px' }}>
        <ExpansionPanel defaultExpanded>
          <ExpansionPanel.Summary>
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: '3fr 1fr',
                marginRight: '30px',
              }}
            >
              <p style={{ margin: 0 }}>
                {isLoading ? (
                  <i>Loading...</i>
                ) : (
                  <span>
                    <b>{stateCode}</b> - {stateName}
                  </span>
                )}
              </p>
              <p style={{ margin: 0, marginLeft: '20px' }}>
                {isLoading ? (
                  <Spinner size="sm" />
                ) : errorOcurred ? (
                  <span className="text-muted">An Error Ocurred</span>
                ) : (
                  <span className="text-muted"> Completed: {processedPercentage}% </span>
                )}
              </p>
            </div>
          </ExpansionPanel.Summary>
          <ExpansionPanel.Content>
            {isLoading && (
              <div style={{ textAlign: 'center' }}>
                <Spinner size="lg" style={{ marginTop: 30 }} />
                <h2 className="text-dark" style={{ fontWeight: 'lighter' }}>
                  Loading information...
                </h2>
              </div>
            )}
            {isLoaded && (
              <React.Fragment>
                <Table borderless hover striped style={{ marginTop: '20px' }}>
                  <tbody>
                    <tr>
                      <td>Not Found</td>
                      <td>{notFoundPercentage}% of total number of licenses.</td>
                      <td>{notFound}</td>
                    </tr>
                    <tr>
                      <td>Server Errors</td>
                      <td>{serverErrorPercentage}% of total number of licenses.</td>
                      <td>{serverError}</td>
                    </tr>
                    <tr>
                      <td>Processed</td>
                      <td>{processedPercentage}% of total number of licenses.</td>
                      <td>{processed}</td>
                    </tr>
                    <tr>
                      <td>New LV</td>
                      <td>{newLvPercentage}% of total number of licenses.</td>
                      <td>{newLv}</td>
                    </tr>
                    {!!newLv && (
                      <tr>
                        <td>New LV Verified</td>
                        <td>{verifiedByNewLV}% of new LV licenses.</td>
                        <td>{verifiedNewLV}</td>
                      </tr>
                    )}
                    <tr>
                      <td>old LV</td>
                      <td>{oldLvPercentage}% of total number of licenses.</td>
                      <td>{oldLv}</td>
                    </tr>
                    {!!oldLv && (
                      <tr>
                        <td>old LV verified</td>
                        <td>{verifiedByOldLV}% of old LV licenses.</td>
                        <td>{verifiedOldLV}</td>
                      </tr>
                    )}
                    <tr>
                      <td>Total Licenses</td>
                      <td>-</td>
                      <td>{count}</td>
                    </tr>
                  </tbody>
                </Table>

                <p>
                  <br />
                  <b>First</b> license verified today at: <DateLabel date={firstVerifiedAt} />
                  <br />
                  <br />
                  <b>Last</b> license verified today at: <DateLabel date={lastVerifiedAt} />
                </p>
              </React.Fragment>
            )}
          </ExpansionPanel.Content>
        </ExpansionPanel>
      </div>
    );
  }
}

export default StateSummaryPanel;
