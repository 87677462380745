export const FIREBASE_COLLECTIONS = {
  SERVICES_DEPLOYMENT_SETTINGS: 'services-deployment-settings'
};
export const ROLE_OPTIONS = ['CUSTOM', 'QA', 'SUPERADMIN'];
export const PERMISSION_OPTIONS = [
  {
    value: 'STATE_STATS',
    description: 'Get LV stats by state',
  },
  {
    value: 'SCREENSHOT_STATS',
    description: 'Get screenshots stats',
  },
  {
    value: 'BOARD_STATS',
    description: 'Get LV stats by board',
  },
  {
    value: 'FILE_STATS',
    description: 'Get file stats',
  },
  {
    value: 'IMAGE_NOW',
    description: 'Handle image now',
  },
  {
    value: 'LICENSE_UPLOAD_PROGRESS',
    description: 'Get LCR upload progress',
  },
  {
    value: 'LICENSE_EXPORT_PROGRESS',
    description: 'Get LCR export progress',
  },
  {
    value: 'EXPIRING_LICENSES',
    description: 'Get expiring licenses',
  },
  {
    value: 'GET_INVOICES',
    description: 'Get invoices & payment export',
  },
  {
    value: 'SCHEDULE_DL',
    description: 'Manage Driver licenses',
  },
  {
    value: 'RESET_HR_PASSWORD',
    description: 'Reset HR user password',
  },
  {
    value: 'WALLET_REG_STATUS',
    description: 'Check Wallet registration status',
  },
  {
    value: 'RUN_PREHIRE_UPLOAD',
    description: 'Run Prehire upload',
  },
  {
    value: 'GET_VERIFYING_CREDENTIALS',
    description: 'Get verifying credentials',
  },
  {
    value: 'DATABASE',
    description: 'Get Oracle database stats',
  },
  {
    value: 'REVIEW_LV_NOTIFICATIONS',
    description: 'Audit LV notifications',
  },
  {
    value: 'NOTIFICATION_LOGS',
    description: 'Get notification logs',
  },
  {
    value: 'USER_MANAGEMENT',
    description: 'Manage users',
  },
  {
    value: 'CREATE_EMPLOYER',
    description: 'Create Employer',
  },
];

export const EXPORT_EMAILS_LOG = {
  TIME_OUT_EXPORT_EMAILS: 900000,
  EXCEEDED_NUMBER: `Number of emails to generate exceeded`,
};
