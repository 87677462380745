import React from 'react';
import Col from 'emerald-ui/lib/Col';
import Row from 'emerald-ui/lib/Row';
import Label from 'emerald-ui/lib/Label';
import styled from 'styled-components';

export const StyledH3 = styled.h3`
  text-align: center;
  font-weight: bold;
  padding: 10px;
  padding-top: 0px;
`;
const Summary = ({ summary }) => {
  return (
    <Row>
      {Object.values(summary).map(({ name, count, tag }, index) => {
        return (
          <Col key={index} lg={3} md={3} xs={12} sm={6}>
            <Row center style={{ padding: '20px 24px' }}>
              <Col xs={12}>
                <StyledH3 className="text-dark">{name}</StyledH3>
              </Col>
              <Col xs={12}>
                <Label color={tag} style={{ display: 'block' }}>
                  {count}
                </Label>
              </Col>
            </Row>
          </Col>
        );
      })}
    </Row>
  );
};

export default Summary;
