import React, { useState } from 'react';

import Container from 'emerald-ui/lib/Container';
import TextField from 'emerald-ui/lib/TextField';
import Button from 'emerald-ui/lib/Button';
import Alert from 'emerald-ui/lib/Alert';
import Card from 'emerald-ui/lib/Card';
import styled from 'styled-components';

import { createEmployer } from '../../api/hr';

const CardContainer = styled(Card)`
  width: 30%;
  margin: 10px auto;
`;

const Title = styled.h2`
  margin: 10px auto;
  font-weight: lighter;
`;

const TextFieldContainer = styled(Container)`
  margin: 20px 0;
`;

const SmallTextField = styled(TextField)``;

const ButtonContainer = styled.div`
  margin: 15px;
  text-align: right;
`;

const EmployerCreation = () => {
  const [employerId, setEmployerId] = useState('');
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [usernameCreated, setUsernameCreated] = useState('');
  const [status, setStatus] = useState('IDLE');

  const isLoading = status === 'LOADING';
  const isErrored = status === 'ERRORED';
  const isDisabled = !employerId || isLoading;
  const isSuccess = !isLoading && !isErrored && successMessage;

  const handleEmployerIdChange = (e) => {
    const eventValue = e.target.value;

    if (eventValue.match(/^[0-9]{0,3}$/)) {
      setEmployerId(eventValue);
    }
  };

  const handleRequestStatusErrored = (errorMsg) => {
    setStatus('ERRORED');
    setErrorMessage(errorMsg);
    setSuccessMessage('');
    setUsernameCreated('');
  };

  const handleSubmit = async () => {
    try {
      setStatus('LOADING');

      const { data: requestStatus } = await createEmployer(employerId);

      if (requestStatus.code === 'EMPLOYER_EXIST') {
        handleRequestStatusErrored('The employer already exist 🚫');
        return;
      }
      if (requestStatus.code === 'USER_NOT_FOUND') {
        handleRequestStatusErrored('The user created was not found 🚫');
        return;
      }

      setStatus('IDLE');
      setErrorMessage('');
      setSuccessMessage(requestStatus.message);
      setUsernameCreated(`Use this username: ${requestStatus.username}`);
    } catch (error) {
      handleRequestStatusErrored('Something went wrong, try again later or start crying 🤷🏻‍♂️');
    }
  };

  return (
    <Container>
      <CardContainer>
        <Title className="text-dark">Create Employer</Title>
        <TextFieldContainer>
          <SmallTextField
            style={{ marginTop: 0 }}
            label="Employer ID"
            id="employer-id"
            disabled={isLoading}
            onChange={handleEmployerIdChange}
            value={employerId}
            maxLength={3}
          />
        </TextFieldContainer>
        <ButtonContainer>
          <Button onClick={handleSubmit} color="info" loading={isLoading} disabled={isDisabled}>
            SUBMIT
          </Button>
        </ButtonContainer>
        {isErrored ? (
          <Alert color="warning">
            <p style={{ padding: '10px 0', margin: 0 }}>{errorMessage}</p>
          </Alert>
        ) : null}
        {isSuccess ? (
          <Alert color="success">
            <p style={{ padding: '10px 0', margin: 0 }}>{successMessage}</p>
          </Alert>
        ) : null}
        {isSuccess ? (
          <Alert color="info">
            <p style={{ padding: '10px 0', margin: 0 }}>{usernameCreated}</p>
          </Alert>
        ) : null}
      </CardContainer>
    </Container>
  );
};

export default EmployerCreation;
