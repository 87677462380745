import React from 'react';
import Panel from 'emerald-ui/lib/Panel';
import Table from 'emerald-ui/lib/Table';
import Label from 'emerald-ui/lib/Label';
import Container from 'emerald-ui/lib/Container';
import styled from 'styled-components';
import Row from 'emerald-ui/lib/Row';
import Col from 'emerald-ui/lib/Col';
import DatePicker from 'emerald-ui/lib/DatePicker';
import moment from 'moment';
import Export from './Export';
import 'react-day-picker/lib/style.css';
import './Dashboard.css';

export const StyledH3 = styled.h3`
  text-align: center;
  font-weight: bold;
  padding: 10px;
  padding-top: 0px;
`;

const statusColors = {
  QUEUED: 'info',
  PROCESSING: 'warning',
  COMPLETED: 'success',
  FAILED: 'danger',
};

const cardValues = [
  {
    title: 'Queued',
    value: 'QUEUED',
  },
  {
    title: 'Processing',
    value: 'PROCESSING',
  },
  {
    title: 'Completed',
    value: 'COMPLETED',
  },
  {
    title: 'Failed',
    value: 'FAILED',
  },
];

const Dashboard = ({ lcrUploadsProgressInformation, filterByDate, date }) => {
  const handlerFilterByDate = (day) => {
    let date = moment(day).format('YYYY-MM-DD');
    filterByDate(date);
  };

  const { data, status } = lcrUploadsProgressInformation;

  return (
    <Container>
      <Panel>
        <Panel.Body>
          <Row className="container-row-license">
            <Col lg={8} xs={12} md={6} sm={12}>
              <h1 className="text-dark">License Uploads</h1>
            </Col>
            <Col xs={6} md={4} sm={6} lg={4} className="container-date-picker upload-status">
              <DatePicker
                ariaLabel={`Choose date to filter`}
                onChange={handlerFilterByDate}
                value={date}
                label="Date filter"
              />
              {data.length ? <Export type="captured" date={date} /> : null}
            </Col>
          </Row>
        </Panel.Body>
      </Panel>

      <Panel>
        <Panel.Body>
          <Row className="container-cards" center>
            {cardValues.map((card, i) => {
              return (
                <Col lg={3} md={3} xs={12} sm={6} key={i}>
                  <Row center style={{ padding: '20px 24px' }}>
                    <Col xs={12}>
                      <StyledH3 className="text-dark">{card.title}</StyledH3>
                    </Col>
                    <Col xs={12}>
                      <Label color={statusColors[card.value]} style={{ display: 'block' }}>
                        {status.hasOwnProperty(card.value) ? status[card.value] : 0}
                      </Label>
                    </Col>
                  </Row>
                </Col>
              );
            })}
          </Row>

          <div style={{ overflow: 'auto' }}>
            <Table hover striped condensed>
              <thead>
                <tr>
                  <th>Employer name</th>
                  <th>Employer ID</th>
                  <th>File Name</th>
                  <th>Status</th>
                  <th>Queued date/time</th>
                  <th>Start date/time</th>
                  <th>Finish date/time</th>
                  <th>Percentage uploaded</th>
                </tr>
              </thead>
              <tbody>
                {data.length ? (
                  data.map((fileInformation, i) => {
                    return (
                      <tr key={i}>
                        <td>{fileInformation.NM_EMPLOYER}</td>
                        <td>{fileInformation.ID_EMPLOYER}</td>
                        <td>{fileInformation.DS_FILENAME}</td>
                        <td>
                          <Label color={statusColors[fileInformation.STATUS]}>{fileInformation.STATUS}</Label>
                        </td>
                        <td>{moment(fileInformation.DT_ADDED).utc().format('LLL')}</td>
                        <td>
                          {fileInformation.STATUS === 'QUEUED'
                            ? null
                            : moment(fileInformation.DT_START).utc().format('LLL')}
                        </td>
                        <td>
                          {fileInformation.STATUS === 'COMPLETED'
                            ? moment(fileInformation.DT_END).utc().format('LLL')
                            : null}
                        </td>
                        <td>
                          {fileInformation.STATUS === 'QUEUED' ? null : `${fileInformation.PERCENTAGE.toFixed(2)} %`}
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr className="table-nodata-found">
                    <td colSpan={8}>No data found</td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        </Panel.Body>
      </Panel>
    </Container>
  );
};

export default Dashboard;
