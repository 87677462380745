import React from 'react';
import Table from 'emerald-ui/lib/Table';
import IconButton from 'emerald-ui/lib/IconButton';
import Toggle from 'emerald-ui/lib/Toggle';

const UserTable = ({ userList, handleModal, handleToggleStatus, handleLockReset }) => {
  return (
    <div style={{ overflow: 'auto' }}>
      <Table hover striped condensed>
        <thead>
          <tr>
            <th>Name</th>
            <th>Username</th>
            <th>Role</th>
            <th>Active</th>
            <th>Manage</th>
          </tr>
        </thead>
        <tbody>
          {userList.length ? (
            userList.map((user, i) => {
              return (
                <tr key={i}>
                  <td>{user.name}</td>
                  <td>{user.username}</td>
                  <td>{user.role}</td>
                  <td>
                    <Toggle
                      label="toggle"
                      checked={user.active}
                      onChange={(e, checked) => handleToggleStatus(user._id, checked)}
                    />
                  </td>
                  <td>
                    <IconButton color="info" icon="edit" size="sm" onClick={(e) => handleModal(e, i, true)} />
                    {user.loginAttemps >= 3 && (
                      <IconButton color="info" icon="lock_reset" size="sm" onClick={(e) => handleLockReset(user._id)} />
                    )}
                  </td>
                </tr>
              );
            })
          ) : (
            <tr className="table-nodata-found">
              <td colSpan={8}>No data found</td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
};

export default UserTable;
