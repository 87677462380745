import React from 'react';
import styled from 'styled-components';

import CardGrid from 'emerald-ui/lib/CardGrid';
import Container from 'emerald-ui/lib/Container';

import ErrorModal from '../../components/ErrorModal';
import CardsLoader from '../../components/CardsLoader';
import FileStatusCard from './components/FileStatusCard';
import FileProgressCard from './components/FileProgressCard';

import { getFileStats, getFileProgress, getNewLvFilesProgress, getNewLvFilesStats } from '../../api/lv';

const Title = styled.h2`
  font-weight: lighter;
`;
class FileStatus extends React.Component {
  state = {
    fileStats: [],
    fileProgress: [],
    status: 'LOADING',
  };

  async componentDidMount() {
    try {
      const { data: fileStats } = await getFileStats();

      const { data: fileProgress } = await getFileProgress();

      const { data: newLvFileProgress } = await getNewLvFilesProgress();

      const { data: newLvFileStats } = await getNewLvFilesStats();

      this.setState({
        status: 'LOADED',
        fileStats,
        fileProgress,
        newLvFileProgress,
        newLvFileStats,
      });
    } catch (error) {
      this.setState({ status: 'ERRORED' });
    }
  }

  render() {
    switch (this.state.status) {
      case 'LOADED':
        let { fileStats, fileProgress, newLvFileProgress, newLvFileStats } = this.state;

        return (
          <Container>
            <Title className="text-dark">File Status</Title>
            <hr />
            <CardGrid>
              {fileStats.map((fileStat, index) => (
                <FileStatusCard key={index} fileStat={fileStat} />
              ))}
            </CardGrid>

            <Title className="text-dark">New LV - File Status</Title>
            <hr />
            <CardGrid>
              {newLvFileStats.map((fileStat, index) => (
                <FileStatusCard key={index} fileStat={fileStat} />
              ))}
            </CardGrid>

            <Title className="text-dark">File Progress</Title>
            <hr />
            <CardGrid>
              {fileProgress.map((fileStat, index) => (
                <FileProgressCard key={index} fileStat={fileStat} />
              ))}
              {fileProgress.length === 0 && <Title>Nothing to see here.</Title>}
            </CardGrid>
            <hr />

            <Title className="text-dark">New LV - File Progress</Title>
            <hr />
            <CardGrid>
              {newLvFileProgress.map((fileStat, index) => (
                <FileProgressCard key={index} fileStat={fileStat} />
              ))}
              {newLvFileProgress.length === 0 && <Title>Nothing to see here.</Title>}
            </CardGrid>
          </Container>
        );
      case 'LOADING':
        return (
          <Container>
            <CardsLoader numberOfCards={6} />
          </Container>
        );
      default:
        return <ErrorModal />;
    }
  }
}

export default FileStatus;
