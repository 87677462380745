import React from 'react';

import Table from 'emerald-ui/lib/Table';
import TableLoader from '../../../components/TableLoader';
import TablePlaceholder from '../../../components/TablePlaceholder';

import * as lvAPI from '../../../api/lv';
import { convertQueryStringToObject } from '../../../utils/url';

class BoardErrorsTable extends React.Component {
  state = {
    boardErrors: [],
    status: 'LOADING',
  };

  async componentDidMount() {
    try {
      const {
        location: { search: queryStrings },
      } = this.props;

      const queryStringObject = convertQueryStringToObject(queryStrings);

      const { data: boardErrors } = await lvAPI.getBoardErrors(queryStringObject);

      this.setState({
        boardErrors,
        status: 'LOADED',
      });
    } catch (error) {
      this.setState({
        status: 'ERRORED',
      });
    }
  }

  render() {
    const { boardErrors, status } = this.state;

    const isLoading = status === 'LOADING';
    const isLoaded = status === 'LOADED';
    const errorOcurred = status === 'ERRORED';
    const isEmpty = boardErrors.length === 0;

    return (
      <Table hover={isLoaded && isEmpty ? false : true}>
        <thead>
          <tr>
            <th>Board name</th>
            <th>Error</th>
            <th>Machine Id</th>
          </tr>
        </thead>
        <tbody>
          {isLoading && <TableLoader colSpan={3} />}
          {isLoaded && isEmpty && (
            <TablePlaceholder iconName="chat_bubble_outline" message="Nothing to see here 👀" colSpan={3} />
          )}
          {errorOcurred && <TablePlaceholder iconName="error_outline" message="Something went wrong 💥🌪" colSpan={3} />}
          {isLoaded &&
            !isEmpty &&
            boardErrors.map((boardError, index) => {
              return (
                <tr key={index}>
                  <td>{boardError.boardName}</td>
                  <td>{boardError.boardError}</td>
                  <td>{boardError.machineId}</td>
                </tr>
              );
            })}
        </tbody>
      </Table>
    );
  }
}

export default BoardErrorsTable;
