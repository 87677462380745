import React from 'react';
import Modal from 'emerald-ui/lib/Modal';

import Button from 'emerald-ui/lib/Button';

class ConfirmationModal extends React.Component {
  render() {
    const { children, show } = this.props;

    return (
      <Modal onHide={this.close} show={show}>
        <Modal.Header>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>{children}</Modal.Body>
        <Modal.Footer>
          <Button onClick={this.props.onCancel} shape="flat" color="info">
            Nope
          </Button>
          <Button onClick={this.props.onConfirm} color="info">
            Of course!
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default ConfirmationModal;
