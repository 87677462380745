import axios from 'axios';
import moment from 'moment';
import { getAuthToken } from '../utils/auth';

export const paymentAPI = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

const getHeader = () => {
  return {
    headers: { Authorization: getAuthToken() },
  };
};

export const invoiceStatus = (params = {}) => {
  return paymentAPI.get('/payment/', { params, ...getHeader() });
};

export const invoiceStatusDownload = async (params = {}) => {
  const { date } = params;
  const { data } = await paymentAPI.get('/payment/export-file', {
    params,
    ...getHeader(),
    responseType: 'arraybuffer',
  });

  const isFileEmpty = data.byteLength < 100;
  if (isFileEmpty) return;
  const dateFile = date ? moment(date, 'YYYY-MM-DD').format('MMDDYYYY') : moment().format('MMDDYYYY');

  const url = URL.createObjectURL(new Blob([data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `invoice_status_report_${dateFile}.xlsx`);
  link.click();
  return true;
};
