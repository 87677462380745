import React from 'react';
import ErrorModal from '../../components/ErrorModal';
import Dashboard from './components/Dashboard';
import Loading from '../../components/Loading';
import { lcrUploadProgress } from '../../api/hr';
import moment from 'moment';

class LcrUploadStatus extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status: 'LOADING',
      lcrUploadsProgressInformation: [],
      lcrUploadsProgressInformationStatus: 'LOADING',
      date: moment().format('YYYY-MM-DD'),
    };
  }

  async componentDidMount() {
    try {
      let lcrUploadsProgress = await lcrUploadProgress();
      this.setState({
        lcrUploadsProgressInformation: lcrUploadsProgress.data,
        lcrUploadsProgressInformationStatus: 'LOADED',
        status: 'LOADED',
      });
    } catch (error) {
      this.setState({
        status: 'ERRORED',
      });
    }
  }

  filterByDate = async (date) => {
    try {
      const params = { date };

      this.setState({
        status: 'LOADING',
        lcrUploadsProgressInformationStatus: 'LOADING',
        lcrUploadsProgressInformation: [],
        date,
      });

      let lcrUploadsProgress = await lcrUploadProgress(params);
      this.setState({
        lcrUploadsProgressInformation: lcrUploadsProgress.data,
        lcrUploadsProgressInformationStatus: 'LOADED',
        status: 'LOADED',
      });
    } catch (error) {
      this.setState({
        status: 'ERRORED',
      });
    }
  };

  render() {
    const { status, lcrUploadsProgressInformation, lcrUploadsProgressInformationStatus, date } = this.state;

    switch (status) {
      case 'LOADED':
        return (
          <Dashboard
            lcrUploadsProgressInformation={lcrUploadsProgressInformation}
            lcrUploadsProgressInformationStatus={lcrUploadsProgressInformationStatus}
            filterByDate={this.filterByDate}
            date={date}
          />
        );

      case 'LOADING':
        return <Loading message="Searching LCR's Upload information..." />;

      case 'ERRORED':
      default:
        return <ErrorModal />;
    }
  }
}

export default LcrUploadStatus;
