import React from 'react';
import IconButton from 'emerald-ui/lib/IconButton';
import Spinner from 'emerald-ui/lib/Spinner';
import { getMonthlyScreenshotExportByIdBoard } from '../../../api/lv';

class BoardExpansion extends React.Component {
  state = {
    isDownloading: false,
  };

  exportMonthlyScreenshotsHistoryByBoard = async (type, idBoard, boardName, dateToFilter) => {
    const params = {
      idBoard,
      date: dateToFilter,
    };
    this.setState({
      isDownloading: true,
      needPageRefresh: false,
    });

    let downloaded = await getMonthlyScreenshotExportByIdBoard(type, boardName, params);
    if (!downloaded) this.setState({ needPageRefresh: true });

    this.setState({ isDownloading: false });
  };
  render() {
    const { boardStats, type, dateToFilter } = this.props;
    const { isDownloading, needPageRefresh } = this.state;

    return (
      <tr>
        <td>{boardStats.boardName}</td>
        <td className="table-td-center">{boardStats.totalScreenshots}</td>
        <td className="table-td-center">
          {isDownloading ? (
            <Spinner size="sm" />
          ) : (
            <IconButton
              icon="file_download"
              onClick={() =>
                this.exportMonthlyScreenshotsHistoryByBoard(
                  type,
                  boardStats.idBoard,
                  boardStats.boardName,
                  dateToFilter
                )
              }
            />
          )}
        </td>
        <td style={{ display: needPageRefresh ? 'block' : 'none' }}>
          {needPageRefresh ? <b>Please refresh the page</b> : null}
        </td>
      </tr>
    );
  }
}
export default BoardExpansion;
