import React from 'react';
import ExpansionPanel from 'emerald-ui/lib/ExpansionPanel';
import Table from 'emerald-ui/lib/Table';
import Chip from 'emerald-ui/lib/Chip';
import BoardExpansion from './BoardExpansion';

const StateExpansion = ({ stateName, status, stats, type, dateToFilter }) => {
  let backgroundColor;
  switch (type) {
    case 'added':
      backgroundColor = '#00a950';
      break;

    case 'scheduled':
      backgroundColor = '#fa0';
      break;

    default:
      break;
  }

  const chipStyle = {
    backgroundColor,
    fontWeight: 'bold',
    marginRight: '10px',
    color: 'white',
  };

  return (
    <ExpansionPanel style={{ marginTop: '10px' }} className="panel-content-screenshot">
      <ExpansionPanel.Summary>
        <div className="summaryPanelStyle">
          <p>{stateName}</p>
          <Chip style={chipStyle} size="lg">
            {stats.totalScreenshots}
          </Chip>
        </div>
      </ExpansionPanel.Summary>
      <ExpansionPanel.Content>
        <Table borderless hover striped>
          <tbody>
            {status === 'LOADED'
              ? stats.board.map((board, i) => {
                  return <BoardExpansion key={i} boardStats={board} type={type} dateToFilter={dateToFilter} />;
                })
              : null}
          </tbody>
        </Table>
      </ExpansionPanel.Content>
    </ExpansionPanel>
  );
};

export default StateExpansion;
