import React from 'react';

import ErrorModal from '../../components/ErrorModal';
import Dashboard from './components/Dashboard';
import Loading from '../../components/Loading';
import { getMonthlyScreenshotStatsByType } from '../../api/lv';

const reportType = {
  failed: 'failed',
};

class MonthlyScreenshotHistory extends React.Component {
  state = {
    status: 'LOADING',
    statesWithFailedMonthlyScreenshots: [],
    statesWithFailedMonthlyScreenshotsStatus: 'LOADING',
    dateToFilter: '',
  };
  async componentDidMount() {
    try {
      const { failed } = reportType;
      let failedMonthlyScreenshots = await getMonthlyScreenshotStatsByType(failed);
      this.setState({
        statesWithFailedMonthlyScreenshots: failedMonthlyScreenshots.data,
        statesWithFailedMonthlyScreenshotsStatus: 'LOADED',
        status: 'LOADED',
      });
    } catch (error) {
      this.setState({
        status: 'ERRORED',
      });
    }
  }
  filterByDate = async (dateToFilter) => {
    try {
      const { failed } = reportType;
      const params = { date: dateToFilter };
      this.setState({
        status: 'LOADING',
        statesWithFailedMonthlyScreenshotsStatus: 'LOADING',
        statesWithFailedMonthlyScreenshots: [],
        dateToFilter,
      });
      let failedMonthlyScreenshots = await getMonthlyScreenshotStatsByType(failed, params);
      this.setState({
        statesWithFailedMonthlyScreenshots: failedMonthlyScreenshots.data,
        statesWithFailedMonthlyScreenshotsStatus: 'LOADED',
        status: 'LOADED',
      });
    } catch (error) {
      this.setState({
        status: 'ERRORED',
      });
    }
  };
  render() {
    const { status, statesWithFailedMonthlyScreenshots, statesWithFailedMonthlyScreenshotsStatus, dateToFilter } =
      this.state;
    switch (status) {
      case 'LOADING':
        return <Loading message="Searching Monthly Screenshots History information..." />;

      case 'LOADED':
        return (
          <Dashboard
            statesWithFailedMonthlyScreenshotsStatus={statesWithFailedMonthlyScreenshotsStatus}
            statesWithFailedMonthlyScreenshots={statesWithFailedMonthlyScreenshots}
            filterByDate={this.filterByDate}
            dateToFilter={dateToFilter}
          />
        );

      case 'ERRORED':
      default:
        return <ErrorModal />;
    }
  }
}

export default MonthlyScreenshotHistory;
