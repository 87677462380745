import * as auth from './auth';

const canUse = (feature) => {
  const tokenPayload = auth.getTokenPayload();

  if (!tokenPayload) return false;

  if (tokenPayload.role === 'SUPERADMIN') return true;

  const { permissions, restrictions } = tokenPayload;

  //if the feature is in the list of restrition will return false, which means not access to the resource
  if (restrictions && restrictions.includes(feature)) return false;

  return permissions && permissions.includes(feature);
};

const cannotUse = (feature) => !canUse(feature);

export { canUse, cannotUse };
