import React from 'react';
import { Link } from 'react-router-dom';
import Navbar from 'emerald-ui/lib/Navbar';
import Nav from 'emerald-ui/lib/Nav';
import Avatar from 'emerald-ui/lib/Avatar';
import DropdownButton from 'emerald-ui/lib/DropdownButton';
import DropdownItem from 'emerald-ui/lib/DropdownItem';
import Container from 'emerald-ui/lib/Container';
import logo from './ec-logo.svg';

const Appbar = (props) => {
  return (
    <Navbar inverse>
      <Container>
        <Nav grow>
          <a href="#f" className="nav-item-icon" onClick={props.openSidebar}>
            <i className="material-icons">view_headline</i>
          </a>
          <Navbar.Header>
            <Navbar.Brand>
              <Link to="/lv/state-stats">
                <img src={logo} alt="EverCheck" />
              </Link>
            </Navbar.Brand>
          </Navbar.Header>
        </Nav>

        <Nav>
          <DropdownButton id="drop-down-appbar-1" title={<Avatar title={props.userName} />} noCaret>
            <DropdownItem eventKey="1.1" href="/logout">
              Logout
            </DropdownItem>
          </DropdownButton>
        </Nav>
      </Container>
    </Navbar>
  );
};

export default Appbar;
