import React from 'react';
import Container from 'emerald-ui/lib/Container';
import Row from 'emerald-ui/lib/Row';
import Card from 'emerald-ui/lib/Card';
import styled from 'styled-components';

export const MessageCard = styled(Card)`
  padding: 3em 7em;
  max-width: 600px;
`;

export const Title = styled.h2`
  font-weight: 600;
  text-align: center;
  font-size: 1.3em;
`;

const ErrorModal = () => {
  return (
    <Container>
      <Row center>
        <MessageCard>
          <Title>
            Something went wrong!{' '}
            <span role="img" aria-label="sad">
              😔
            </span>
          </Title>
          <p>
            I know, I know...You already know that you have to "try again later".
            <br />
            <br />
            So, please, try again later. If the error persists, communicate with the production team (if they aren't too
            busy fixing any other project already{' '}
            <span role="img" aria-label="grim">
              😬
            </span>
            ).
            <br />
            <br />
            <b>Ps.</b> If you are part of the PT, fix it!{' '}
            <span role="img" aria-label="fish">
              🐟
            </span>
          </p>
        </MessageCard>
      </Row>
    </Container>
  );
};

export default ErrorModal;
