import React from 'react';
import Spinner from 'emerald-ui/lib/Spinner';
import IconDropdown from 'emerald-ui/lib/IconDropdown';
import DropdownItem from 'emerald-ui/lib/DropdownItem';

import { getMonthlyScreenshotExportByType } from '../../../api/lv';

const spanStyle = {
  display: 'flex',
  flexDirecion: 'row',
  justifyContent: 'center',
  alignItems: 'center',
};

class Export extends React.Component {
  state = {
    isDownloading: false,
  };

  exportByTYpe = async (type, dateToFilter) => {
    const params = { date: dateToFilter };
    this.setState({
      isDownloading: true,
      needPageRefresh: false,
    });

    let downloaded = await getMonthlyScreenshotExportByType(type, params);
    if (!downloaded) this.setState({ needPageRefresh: true });

    this.setState({ isDownloading: false });
  };

  render() {
    const { type, dateToFilter } = this.props;
    const { isDownloading, needPageRefresh } = this.state;

    return (
      <IconDropdown id="download" icon="more_vert">
        {isDownloading ? (
          <span style={spanStyle}>
            <Spinner size="sm" />
            Downloading
          </span>
        ) : needPageRefresh ? (
          <span>Please refresh</span>
        ) : (
          <DropdownItem icon="exit_to_app" onClick={() => this.exportByTYpe(type, dateToFilter)} useButton>
            Export
          </DropdownItem>
        )}
      </IconDropdown>
    );
  }
}
export default Export;
