import React, { useRef, useState } from 'react';
import Toolbar from 'emerald-ui/lib/Toolbar';
import Icon from 'emerald-ui/lib/Icon';
import Button from 'emerald-ui/lib/Button';
import Popover from 'emerald-ui/lib/Popover';
import SearchableSelect from 'emerald-ui/lib/SearchableSelect';
import Container from 'emerald-ui/lib/Container';
import Row from 'emerald-ui/lib/Row';
import Col from 'emerald-ui/lib/Col';
import OverlayTrigger from 'emerald-ui/lib/OverlayTrigger';
import DatePicker from 'emerald-ui/lib/DatePicker';
import 'react-day-picker/lib/style.css';
import Modal from 'emerald-ui/lib/Modal';

import moment from 'moment';
import isObject from 'lodash/isObject';

const queueStatuses = ['Queued', 'Processing', 'Completed', 'Failed'];
const queueStatusesRetry = [
  { key: 'Queued', value: 'queued' },
  { key: 'Failed', value: 'errored' }
];

const Filters = ({
  employers,
  handleFilter,
  filters,
  historyTypes,
  cleanFilters,
  filterCount,
  filtersRetry,
  retryItemsFailed,
  exportData,
  downloadingExport,
}) => {
  const [show, setShow] = useState(false);
  const [currentFilters, setCurrentFilters] = useState(filters);
  const [showModalRetry, setShowModalRetry] = useState(false);
  const target = useRef(null);

  const toggleModalRetry = () => {
    setShowModalRetry(!showModalRetry);
  };

  React.useEffect(() => {
    setCurrentFilters(filters);
  }, [filters]);

  return (
    <div>
      <Toolbar activeView="default">
        <Toolbar.View id="default">
          <OverlayTrigger
            hideOnOutsideClick={false}
            style={{ margin: '3px' }}
            placement="bottom"
            target={target.current}
            overlay={
              <Popover id="FilterPopover">
                {RenderFilter(employers, historyTypes, handleFilter, currentFilters, cleanFilters)}
              </Popover>
            }
          >
            <Button onClick={() => setShow(!show)} ref={target.current}>
              Filter ({filterCount}) <span className="caret" aria-label="Filter" />
            </Button>
          </OverlayTrigger>
          <Button onClick={exportData} disabled={downloadingExport}>
            <Icon name="exit_to_app" aria-hidden="true" />
            <span>Export</span>
          </Button>
          <Button onClick={toggleModalRetry}>
            <Icon name="redo" aria-hidden="true" />
            <span>Retry</span>
          </Button>
        </Toolbar.View>
      </Toolbar>

      <ModalRetry
        showModal={showModalRetry}
        toggleView={toggleModalRetry}
        employers={employers}
        historyTypes={historyTypes}
        filtersRetry={filtersRetry}
        retryItemsFailed={retryItemsFailed}
      />
    </div>
  );
};

const filter = (filterFunction, params) => {
  let paramsWithValues = {};
  Object.keys(params).forEach(key => {
    if (params[key] && params[key] !== 'Select...')
      paramsWithValues[key] = params[key];
  });
  filterFunction(paramsWithValues);
};

const RenderFilter = (
  employers,
  historyTypes,
  handleFilter,
  filters,
  cleanFilters
) => {
  const onDayChange = day => {
    const filterDate = day ? moment(day).format('YYYY-MM-DD') : undefined;
    filters.queuedDate = filterDate;
  };
  return (
    <Row>
      <Col xs={12} md={12}>
        <SearchableSelect
          label="Employer name"
          id="employers"
          style={{ width: '100%', zIndex: 12 }}
          onSelect={empId => {
            if (!isObject(empId)) filters.employerId = empId;
          }}
          placeholder="Select..."
          clearable
          value={filters.employerId}
        >
          {employers.map((em, index) => (
            <option key={index} value={em.id} selected={em.id === (filters.employerId || 'Selected...')}>
              {em.name}
            </option>
          ))}
        </SearchableSelect>
      </Col>

      <Col xs={12} md={12} style={{ marginTop: 5 }}>
        <SearchableSelect
          label="History type"
          id="histories"
          style={{ width: '100%', zIndex: 11 }}
          onSelect={hsType => {
            if (!isObject(hsType)) filters.historyType = hsType;
          }}
          placeholder="Select..."
          clearable
          value={filters.historyType}
        >
          {historyTypes.map(hs => {
            return (
              <option key={hs} value={hs} selected={hs === (filters.historyType || 'Selected...')}>
                {hs}
              </option>
            );
          })}
        </SearchableSelect>
      </Col>

      <Col xs={12} md={12} style={{ marginTop: 5 }}>
        <SearchableSelect
          label="Queue status"
          id="statuses"
          style={{ width: '100%' }}
          onSelect={statusQueue => {
            if (!isObject(statusQueue)) filters.imageStatus = statusQueue;
          }}
          placeholder="Select..."
          clearable
          value={filters.imageStatus}
        >
          {queueStatuses.map(st => {
            return (
              <option key={st} value={st} selected={st === (filters.imageStatus || 'Selected...')}>
                {st}
              </option>
            );
          })}
        </SearchableSelect>
      </Col>
      <Col xs={12} md={12}>
        <DatePicker
          clearable
          label="Queued date"
          dateFormat="yyyy-MM-dd"
          placeholder={`Choose date to filter`}
          style={{ width: '100%', marginBottom: '3rem' }}
          onChange={onDayChange}
          value={filters.queuedDate}
        />
      </Col>
      <br />
      <Col>
        <Container>
          <Row>
            <Col xs={6}>
              <Button onClick={cleanFilters}>Reset</Button>
            </Col>
            <Col xs={6}>
              <Button
                color="info"
                onClick={() => filter(handleFilter, filters)}
              >
                Apply
              </Button>
            </Col>
          </Row>
        </Container>
      </Col>
    </Row>
  );
};

const ModalRetry = ({
  showModal,
  toggleView,
  employers,
  historyTypes,
  filtersRetry,
  retryItemsFailed
}) => {
  const [errorMessage, setErrorMessage] = useState('');
  const validateErrorOnClick = () => {
    if (filtersRetry.imageStatus) {
      retryItemsFailed();
      setErrorMessage('');
    } else {
      setErrorMessage('The field is required');
    }
  };

  return (
    <Modal show={showModal} onHide={() => toggleView()}>
      <Modal.Header closeButton>
        <Modal.Title>Retry Items</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          {RenderFilterRetry(
            employers,
            historyTypes,
            filtersRetry,
            errorMessage,
            setErrorMessage
          )}
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => toggleView()} shape="flat" color="info">
          Cancel
        </Button>
        <Button color="info" onClick={validateErrorOnClick}>
          Retry items
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const RenderFilterRetry = (
  employers,
  historyTypes,
  filtersRetry,
  errorMessage,
  setErrorMessage
) => {
  const onDayChange = day => {
    const filterDate = day && moment(day).format('YYYY-M-DD');
    filtersRetry.queuedDate = filterDate;
  };
  return (
    <Row>
      <Col xs={12} md={12}>
        <SearchableSelect
          label="Employer name"
          id="retry-employers"
          style={{ width: '100%', zIndex: 12 }}
          onSelect={empId => {
            if (!isObject(empId)) filtersRetry.employerId = empId;
          }}
          placeholder="Select..."
          clearable
        >
          {employers.map((em, index) => (
            <option key={index} value={em.id} selected={em.id === (filtersRetry.employerId || 'Selected...')}>
              {em.name}
            </option>
          ))}
        </SearchableSelect>
      </Col>

      <Col xs={12} md={12} style={{ marginTop: 5 }}>
        <SearchableSelect
          label="History type"
          id="retry-histories"
          style={{ width: '100%', zIndex: 11 }}
          onSelect={hsType => {
            if (!isObject(hsType)) filtersRetry.historyType = hsType;
          }}
          placeholder="Select..."
          clearable
        >
          {historyTypes.map(hs => {
            return (
              <option key={hs} value={hs} selected={hs === (filtersRetry.historyType || 'Selected...')}>
                {hs}
              </option>
            );
          })}
        </SearchableSelect>
      </Col>
      <Col xs={12} md={12} style={{ marginTop: 5 }}>
        <SearchableSelect
          label="Queue status"
          id="statuses"
          style={{ width: '100%' }}
          onSelect={statusQueue => {
            if (!isObject(statusQueue)) {
              filtersRetry.imageStatus = statusQueue;
              setErrorMessage('');
            }
          }}
          placeholder="Select..."
          clearable
          value={filtersRetry.imageStatus}
          errorMessage={errorMessage}
          onClear={() => {
            setErrorMessage('');
            filtersRetry.imageStatus = '';
          }}
        >
          {queueStatusesRetry.map(({ key, value }) => {
            return (
              <option
                key={key}
                value={value}
                selected={key === (filtersRetry.imageStatus || 'Selected...')}
              >
                {key}
              </option>
            );
          })}
        </SearchableSelect>
      </Col>
      <Col xs={12} md={12} style={{ marginTop: 5 }}>
        <DatePicker
          clearable
          label="Queued date"
          dateFormat="yyyy-MM-dd"
          placeholder={`Choose date to filter`}
          style={{ width: '50%', marginBottom: '3rem' }}
          onChange={onDayChange}
          value={filtersRetry.queuedDate}
        />
      </Col>
    </Row>
  );
};

export default Filters;
