import React from 'react';
import Label from 'emerald-ui/lib/Label';
import Card from 'emerald-ui/lib/Card';
import styled from 'styled-components';

export const StyledCard = styled(Card)`
  height: 130px;
  width: 20%;
  margin: 2.5%;
`;

export const StyledH3 = styled.h3`
  text-align: center;
  font-weight: bold;
  padding: 10px;
  padding-top: 0px;
`;

const TotalsCards = ({ packages }) => {
  const totalsCards = [];

  const valid = packages.filter((l) => {
    return l.status === 'VALID';
  }).length;
  totalsCards.push(
    <StyledCard key="valid">
      <StyledH3>VALID</StyledH3>
      <Label color="success" style={{ fontSize: 'large' }}>
        {valid}
      </Label>
    </StyledCard>
  );

  const notValid = packages.filter((l) => {
    return l.status !== 'VALID';
  }).length;
  totalsCards.push(
    <StyledCard key="not_valid">
      <StyledH3>NOT VALID</StyledH3>
      <Label color="danger" style={{ fontSize: 'large' }}>
        {notValid}
      </Label>
    </StyledCard>
  );

  return totalsCards;
};

export default TotalsCards;
