import React from 'react';
import Button from 'emerald-ui/lib/Button';
import Label from 'emerald-ui/lib/Label';
import Table from 'emerald-ui/lib/Table';
import Toolbar from 'emerald-ui/lib/Toolbar';
import SearchForm from 'emerald-ui/lib/SearchForm';
import Pager from 'emerald-ui/lib/Pager';
import styled from 'styled-components';

import { convertQueryStringToObject } from '../../../utils/url';

import TableLoader from '../../../components/TableLoader';
import * as lvAPI from '../../../api/lv';
import DetailsModal from './DetailsModal';

const StyledTable = styled(Table)`
  tbody tr td {
    vertical-align: middle;
  }
`;

class BoardStatusTable extends React.Component {
  state = {
    boardStats: [],
    detailsModalOpen: false,
    selectedRow: null,
    status: 'LOADING',
    searchTerm: '',
    currentPage: 1,
    limit: 5,
  };

  async componentDidMount() {
    try {
      const {
        location: { search: queryStrings },
      } = this.props;

      const queryStringObject = convertQueryStringToObject(queryStrings);

      let { data: boardStats } = await lvAPI.getBoardStats(queryStringObject);

      this.setState({
        boardStats,
        status: 'LOADED',
      });
    } catch (error) {
      this.setState({
        status: 'ERRORED',
      });
    }
  }

  handleDetailsModal(e, rowIndex) {
    e.preventDefault();

    this.setState({
      selectedRow: rowIndex,
    });

    this.openDetailsModal();
  }

  closeDetailModal = () => {
    this.setState({
      detailsModalOpen: false,
    });
  };

  openDetailsModal = () => {
    this.setState({
      detailsModalOpen: true,
    });
  };

  handleSearchTermChange = (e) => {
    this.setState({
      searchTerm: e.target.value.replace(/[^a-zA-Z ]/gi, ''),
    });
  };

  handleSearchTermClear = () => {
    this.setState({
      searchTerm: '',
    });
  };

  handleLimitChage = (newLimit) => {
    this.setState({
      limit: newLimit,
    });
  };

  handlePageChange = (event) => {
    this.setState(({ currentPage }) => {
      return {
        currentPage: event === 'next' ? currentPage + 1 : currentPage - 1,
      };
    });
  };

  render() {
    const { status, boardStats, selectedRow, detailsModalOpen, searchTerm, currentPage, limit } = this.state;

    const isLoaded = status === 'LOADED';
    const isLoading = status === 'LOADING';

    const offset = (currentPage - 1) * limit;

    let boardsToDisplay;

    if (!searchTerm) {
      boardsToDisplay = boardStats;
    } else {
      const searchTermRegex = new RegExp(searchTerm.trim().toUpperCase());

      boardsToDisplay = boardStats.filter((row) => {
        return row.boardName.toUpperCase().match(searchTermRegex);
      });
    }

    const numberOfRows = boardsToDisplay.length;

    return (
      <React.Fragment>
        <Toolbar>
          <SearchForm
            clearable
            value={searchTerm}
            onChange={this.handleSearchTermChange}
            onClear={this.handleSearchTermClear}
          />
        </Toolbar>
        <StyledTable>
          <thead>
            <tr>
              <th>Board Name</th>
              <th>Board completion</th>
              <th>Board Information</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {isLoading && <TableLoader colSpan={4} />}
            {isLoaded &&
              boardsToDisplay.slice(offset, offset + limit).map((boardStat, index) => {
                const { count, processed } = boardStat;
                const processedPercentage = ((processed / count) * 100).toFixed(2);
                return (
                  <tr key={index}>
                    <td>{boardStat.boardName}</td>
                    <td>{processedPercentage}% completed</td>
                    <td>{getLabels(boardStat)}</td>
                    <td>
                      <Button color="info" onClick={(e) => this.handleDetailsModal(e, offset + index)}>
                        Details
                      </Button>
                    </td>
                  </tr>
                );
              })}
            <tr>
              <td colSpan="4">
                <Pager
                  offset={offset}
                  limit={limit}
                  onPageChange={this.handlePageChange}
                  onLimitChange={this.handleLimitChage}
                  total={numberOfRows}
                />
              </td>
            </tr>
          </tbody>
        </StyledTable>
        <DetailsModal
          show={detailsModalOpen}
          onHide={this.closeDetailModal}
          boardStats={boardsToDisplay[selectedRow]}
        />
      </React.Fragment>
    );
  }
}

const getLabels = (stats) => {
  const labels = [];
  const { processed, count } = stats;
  const processedPercentage = Number.parseInt(((processed / count) * 100).toFixed(2));

  if (stats.serverError > 0) {
    labels.push({ color: 'danger', text: 'Server Errors' });
  }

  if (stats.notFound > 0) {
    labels.push({ color: 'warning', text: 'Not Founds' });
  }

  if (processedPercentage === 100) {
    labels.push({ color: 'success', text: 'Completed' });
  } else {
    labels.push({ color: 'warning', text: 'Incomplete' });
  }

  return labels.map((labelInfo, index) => {
    return (
      <Label key={index} style={{ marginRight: '10px' }} color={labelInfo.color}>
        {labelInfo.text}
      </Label>
    );
  });
};

export default BoardStatusTable;
