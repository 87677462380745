import React from 'react';
import Card from 'emerald-ui/lib/Card';

const FileProgressCard = ({ fileStat }) => {
  const lastVerificationDate = fileStat.dateLastVerification
    ? new Date(fileStat.dateLastVerification).toLocaleString()
    : 'None given';

  return (
    <Card>
      <Card.Header>
        <h1 className="eui-card-header-title">
          {fileStat.stateCode} - {fileStat.name}
        </h1>
      </Card.Header>
      <div>
        <p>
          <b>Total</b> number of rows: {fileStat.totalRows}
          <br />
          <br />
          Number of <b>processed</b> rows: {fileStat.processedRows}
          <br />
          <br />
          Last license verification Date: {lastVerificationDate}
        </p>
      </div>
    </Card>
  );
};

export default FileProgressCard;
