import React from 'react';
import IconButton from 'emerald-ui/lib/IconButton';
import Pager from 'emerald-ui/lib/Pager';
import Table from 'emerald-ui/lib/Table';
import styled from 'styled-components';

import TableLoader from '../../../components/TableLoader';
import * as API from '../../../api/prehire';

const Td = styled.td`
  text-align: center;
`;

const StyledTable = styled(Table)`
  tbody tr td {
    vertical-align: middle;
  }
`;
class BoardTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = { status: 'NOTLOADED', limit: 10, currentPage: 1 };
  }

  async fetchCredentials() {
    try {
      const { limit = 10, currentPage } = this.state;
      const offset = (currentPage - 1) * limit;

      this.setState({ status: 'LOADING' });
      let {
        data: { boards, total },
      } = await API.getVerifyingCredentialsByBoards({ limit, offset });

      this.setState({
        boards,
        total,
        status: 'LOADED',
      });
    } catch (error) {
      this.setState({
        status: 'ERRORED',
      });
    }
  }

  componentDidMount() {
    const { status } = this.state;
    if (status === 'NOTLOADED') this.fetchCredentials();
  }

  componentDidUpdate(prevProps, prevState) {
    const { currentPage, limit } = this.state;
    if (currentPage !== prevState.currentPage || limit !== prevState.limit) this.fetchCredentials();
  }

  handleLimitChage = (newLimit) => {
    this.setState({
      limit: newLimit,
    });
  };

  handlePageChange = (event) => {
    const { currentPage } = this.state;
    let newPage = event === 'next' ? currentPage + 1 : currentPage - 1;
    this.setState(() => {
      return {
        currentPage: newPage > 0 ? newPage : 1,
      };
    });
  };

  handleDownloadClick = (boardId) => {
    API.getVerifyingExport(boardId);
  };

  render() {
    const { status, boards, total, currentPage = 1, limit = 10 } = this.state;

    const isLoaded = status === 'LOADED';
    const isLoading = status === 'LOADING';

    const offset = (currentPage - 1) * limit;

    return (
      <React.Fragment>
        <StyledTable>
          <thead>
            <tr>
              <th>Board Name</th>
              <th>State code</th>
              <th>Number of verifying credentials</th>
              <th>Report file</th>
            </tr>
          </thead>
          <tbody>
            {isLoading && <TableLoader colSpan={4} />}
            {isLoaded &&
              boards.map((currentBoard, index) => {
                return (
                  <tr key={index}>
                    <td>{currentBoard.boardName}</td>
                    <Td>{currentBoard.stateCode}</Td>
                    <Td>{currentBoard.credentialsCount}</Td>
                    <Td>
                      <IconButton icon="file_download" onClick={() => this.handleDownloadClick(currentBoard.boardId)} />
                    </Td>
                  </tr>
                );
              })}
          </tbody>
          <tr>
            <td colSpan="4">
              <Pager
                offset={offset}
                limit={limit}
                onPageChange={this.handlePageChange}
                onLimitChange={this.handleLimitChage}
                total={total}
                style={{ padding: '10px 13px' }}
              />
            </td>
          </tr>
        </StyledTable>
      </React.Fragment>
    );
  }
}

export default BoardTable;
