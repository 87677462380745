import React from 'react';
import Button from 'emerald-ui/lib/Button';
import Toast from 'emerald-ui/lib/Toast';
import Spinner from 'emerald-ui/lib/Spinner';
import Container from 'emerald-ui/lib/Container';
import Row from 'emerald-ui/lib/Row';
import Col from 'emerald-ui/lib/Col';
import Panel from 'emerald-ui/lib/Panel';
import ErrorModal from '../../components/ErrorModal';
import * as hrAPI from '../../api/hr';

class ScheduleDriverLicenses extends React.Component {
  state = {
    toastMessage: '',
    status: 'LOADING',
    alreadySchedule: true,
    history: null,
  };

  componentDidMount = async () => {
    try {
      const { data: history } = await hrAPI.getDriverLicenseHistory();

      this.setState({
        alreadySchedule: !!history, //if there are any history, that mean it was scheduled already
        history,
        status: 'IDLE',
      });
    } catch (error) {
      this.setState({ status: 'ERRORED' });
    }
  };

  scheduleLicenses = async () => {
    try {
      this.setState({ status: 'LOADING' });

      await hrAPI.scheduleDriverLicense();

      this.setState({
        toastMessage: 'Your life is a success, done!',
        alreadySchedule: true,
        status: 'IDLE',
        history: { username: 'YOU' },
      });
    } catch (error) {
      this.setState({
        toastMessage: 'Schedule failed.',
        status: 'IDLE',
      });
    }
  };

  exportScheduledLicenses = async () => {
    this.setState({ status: 'LOADING' });

    try {
      await hrAPI.getScheduledDriverLicense();
    } catch (error) {
      this.setState({
        toastMessage:
          "Opss! Sorry, if you needed that... Well, It won't be possible for now. We got a 500 internal server error",
      });
    } finally {
      this.setState({ status: 'IDLE' });
    }
  };

  resetToast = () => {
    this.setState({
      toastMessage: '',
    });
  };

  render() {
    switch (this.state.status) {
      case 'IDLE':
        return (
          <Container>
            <Row center>
              <Col md={6} sm={12} xs={12}>
                <Panel>
                  <Panel.Body>
                    <h2 className="text-dark" style={{ fontWeight: 'lighter', textAlign: 'center' }}>
                      Easiest interface ever
                    </h2>

                    {!this.state.alreadySchedule && (
                      <Row center>
                        <Button
                          color="info"
                          onClick={this.scheduleLicenses}
                          disabled={this.state.alreadySchedule || this.state.isSendingRequest}
                        >
                          Just click here to schedule DLs!
                        </Button>
                      </Row>
                    )}

                    {this.state.alreadySchedule && (
                      <Row center>
                        <Button
                          color="info"
                          onClick={this.exportScheduledLicenses}
                          disabled={!this.state.alreadySchedule || this.state.isSendingRequest}
                        >
                          Click here to export the list (xlsx format)
                        </Button>
                      </Row>
                    )}

                    <p style={{ margin: 20 }}>
                      {this.state.history && (
                        <React.Fragment>
                          The licenses were already scheduled today by <b>{this.state.history.username}</b>.
                          <br />
                          <br />
                        </React.Fragment>
                      )}
                      <b>**</b> The licenses schedule here <b>will NOT be immediately verify</b> by LV. To do that,
                      production team have to marked them as procesable licenses for today and actually run LV.
                    </p>

                    <Toast
                      message={this.state.toastMessage}
                      visible={!!this.state.toastMessage}
                      onTimeout={this.resetToast}
                    />
                  </Panel.Body>
                </Panel>
              </Col>
            </Row>
          </Container>
        );

      case 'LOADING':
        return (
          <Container>
            <Row center>
              <Spinner size="lg" />
            </Row>
            <Row center>
              <h3 className="text-dark" style={{ fontWeight: 'lighter', textAlign: 'center' }}>
                Loading...
              </h3>
            </Row>
          </Container>
        );
      default:
        return <ErrorModal />;
    }
  }
}

export default ScheduleDriverLicenses;
