import React from 'react';
import Card from 'emerald-ui/lib/Card';
import Panel from 'emerald-ui/lib/Panel';
import Table from 'emerald-ui/lib/Table';
import Label from 'emerald-ui/lib/Label';
import Container from 'emerald-ui/lib/Container';
import styled from 'styled-components';
import Row from 'emerald-ui/lib/Row';
import Col from 'emerald-ui/lib/Col';
import moment from 'moment';
import Export from './Export';
import 'react-day-picker/lib/style.css';
import DatePicker from 'emerald-ui/lib/DatePicker';
import './Payment.css';
export const StyledH3 = styled.h3`
  text-align: center;
  font-weight: bold;
  padding: 10px;
  padding-top: 0px;
`;

export const StyledCol = styled(Col)`
  margin: 22px 0px;
`;

export const StyledCard = styled(Card)`
  height: 130px;
  width: 20%;
  margin: 2.5%;
`;

export const StyledDiv = styled.div`
  display: flex;
  flex-direction: row;
`;

export const StyledTh = styled.th`
  padding: 21px !important;
  white-space: pre-wrap !important;
`;

export const StyledTd = styled.td`
  word-break: break-all;
`;

export const StyledTr = styled.tr`
  td {
    padding: 21px !important;
  }

  th:nth-child(4) {
    padding: 21px !important;
    width: 92px !important;
    text-align: left;
  }

  td:nth-child(6) {
    white-space: pre-wrap !important;
  }

  td:nth-child(7) {
    white-space: pre-wrap !important;
  }
`;

const statusColors = {
  QUEUED: 'info',
  PROCESSING: 'warning',
  READY_TO_MIGRATE: 'warning',
  IN_PROGRESS: 'warning',
  MIGRATING: 'warning',
  COMPLETED: 'success',
  FAILED: 'danger',
};

const cardValues = [
  {
    title: 'Queued',
    value: 'QUEUED',
  },
  {
    title: 'Processing',
    value: 'PROCESSING',
  },
  {
    title: 'Completed',
    value: 'COMPLETED',
  },
  {
    title: 'Failed',
    value: 'FAILED',
  },
];

const Dashboard = ({ invoiceInformation, filterByDate, date }) => {
  const handlerFilterByDate = (day) => {
    let date = moment(day).format('YYYY-MM-DD');
    filterByDate(date);
  };

  const handleStatusName = (status) => {
    return status.replace(/_/g, ' ');
  };

  const { data, statuses } = invoiceInformation;

  return (
    <Container>
      <Panel>
        <Panel.Body>
          <Row className="container-row-export">
            <Col lg={8} xs={12} md={6} sm={12}>
              <h1 className="text-dark">Payment</h1>
            </Col>
            <Col xs={6} md={4} sm={6} lg={4} className="container-date-picker export-status">
              <DatePicker
                ariaLabel={`Choose date to filter`}
                onChange={handlerFilterByDate}
                value={date}
                label="Date filter"
              />
              {data.length ? <Export type="captured" date={date} /> : null}
            </Col>
          </Row>
        </Panel.Body>
      </Panel>

      <Panel>
        <Panel.Body>
          <Row className="container-cards">
            {cardValues.map((card, i) => {
              return (
                <Col key={i} lg={3} md={3} xs={12} sm={6}>
                  <Row center style={{ padding: '20px 24px' }}>
                    <Col xs={12}>
                      <StyledH3 className="text-dark">{card.title}</StyledH3>
                    </Col>
                    <Col xs={12}>
                      <Label color={statusColors[card.value]} style={{ display: 'block' }}>
                        {statuses.hasOwnProperty(card.value) ? statuses[card.value] : 0}
                      </Label>
                    </Col>
                  </Row>
                </Col>
              );
            })}
          </Row>

          <div style={{ overflow: 'auto' }}>
            <Table hover striped condensed>
              <thead>
                <tr>
                  <th>Employer ID</th>
                  <th>Employer Name</th>
                  <th>Queued Time</th>
                  <th>Completed Time</th>
                  <th># licensed</th>
                  <th># unmonitored</th>
                  <th># unlicensed</th>
                  <th># MVs</th>
                  <th># DLs</th>
                  <th># candidates</th>
                  <th># candidates MVs</th>
                  <th>Status</th>
                  <th>Details</th>
                </tr>
              </thead>
              <tbody>
                {data.length ? (
                  data.map((fileInformation, i) => {
                    return (
                      <tr key={i}>
                        <td>{fileInformation.ID_EMPLOYER}</td>
                        <td>{fileInformation.EMPLOYER_NAME}</td>
                        <td>
                          {fileInformation.QUEUED_TIME ? moment(fileInformation.QUEUED_TIME).format('LLL') : '--'}
                        </td>
                        <td>
                          {fileInformation.COMPLETED_TIME ? moment(fileInformation.COMPLETED_TIME).format('LLL') : '--'}
                        </td>
                        <td>{fileInformation.LICENSED}</td>
                        <td>{fileInformation.UNMONITORED}</td>
                        <td>{fileInformation.UNLICENSED}</td>
                        <td>{fileInformation.MVS}</td>
                        <td>{fileInformation.DLS}</td>
                        <td>{fileInformation.CANDIDATES}</td>
                        <td>{fileInformation.CANDIDATE_MVS}</td>
                        <td>
                          <Label className="label-status" color={statusColors[fileInformation.STATUS]}>
                            {handleStatusName(fileInformation.STATUS)}
                          </Label>
                        </td>
                        <td>
                          <Label className="label-status" color={statusColors[fileInformation.DETAILS_STATUS]}>
                            {handleStatusName(fileInformation.DETAILS_STATUS)}
                          </Label>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={12} style={{ textAlign: 'center' }}>
                      No data found
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        </Panel.Body>
      </Panel>
    </Container>
  );
};

export default Dashboard;
