import React from 'react';
import ErrorModal from '../../components/ErrorModal';
import Dashboard from './components/Dashboard';
import Loading from '../../components/Loading';
import { invoiceStatus } from '../../api/payment';
import moment from 'moment';

class Payment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status: 'LOADING',
      invoiceInformation: [],
      invoiceInformationStatus: 'LOADING',
      date: moment().format('YYYY-MM-DD'),
    };
  }

  async componentDidMount() {
    try {
      let invoice = await invoiceStatus();
      this.setState({
        invoiceInformation: invoice.data,
        invoiceInformationStatus: 'LOADED',
        status: 'LOADED',
      });
    } catch (error) {
      this.setState({
        status: 'ERRORED',
      });
    }
  }

  filterByDate = async (date) => {
    try {
      const params = { date };

      this.setState({
        status: 'LOADING',
        invoiceInformationStatus: 'LOADING',
        invoiceInformation: [],
        date,
      });

      let invoice = await invoiceStatus(params);
      this.setState({
        invoiceInformation: invoice.data,
        invoiceInformationStatus: 'LOADED',
        status: 'LOADED',
      });
    } catch (error) {
      this.setState({
        status: 'ERRORED',
      });
    }
  };

  render() {
    const { status, invoiceInformation, invoiceInformationStatus, date } = this.state;

    switch (status) {
      case 'LOADED':
        return (
          <Dashboard
            invoiceInformation={invoiceInformation}
            invoiceInformationStatus={invoiceInformationStatus}
            filterByDate={this.filterByDate}
            date={date}
          />
        );

      case 'LOADING':
        return <Loading message="Searching Payment information..." />;

      case 'ERRORED':
      default:
        return <ErrorModal />;
    }
  }
}

export default Payment;
