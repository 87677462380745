import React from 'react';
import Container from 'emerald-ui/lib/Container';
import TextField from 'emerald-ui/lib/TextField';
import Button from 'emerald-ui/lib/Button';
import Toast from 'emerald-ui/lib/Toast';
import { downloadOracleEmailNotifications } from '../../api/hr';

class OracleEmailNotifications extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      idList: null,
      loading: false,
      toastMessage: '',
    };
  }

  handleIdListChange = (event) => {
    event.preventDefault();
    this.setState({
      idList: event.target.value,
    });
  };

  generateFiles = async () => {
    this.setState({
      loading: true,
    });
    try {
      if (this.state.idList != null && this.state.idList != '') {
        //Remove spaces and separate by comma
        const userInput = this.state.idList.replace(/\s+/g, '').split(',');
        const idList = userInput.map((id) => Number(id));
        const allNumbers = idList.every((item) => !isNaN(item));

        if (!allNumbers) {
          this.setState({
            idList: null,
            toastMessage: 'All the IDs must be numeric values.',
            showToast: true,
            loading: false,
          });
          return;
        }

        const response = await downloadOracleEmailNotifications({ emailIds: JSON.stringify(idList) });
        if (response.status !== 200) {
          this.setState({
            idList: null,
            toastMessage: 'There was an error processing your request.',
            showToast: true,
            loading: false,
          });
          return;
        }

        const buff = Buffer.from(response.data.file.data);
        const blob = new Blob([buff]);

        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `files.zip`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);

        if (response.data.notFound.length > 0) {
          this.setState({
            toastMessage: `The following email IDs were not found: ${response.data.notFound.join()}`,
            showToast: true,
          });
        }
      } else {
        this.setState({
          toastMessage: 'Please enter a list of email IDs separated by comma.',
          showToast: true,
        });
      }
    } catch (error) {
      this.setState({
        toastMessage: 'There was an unexpected error, please contact the support team.',
        showToast: true,
        loading: false,
      });
      console.log(error);
    } finally {
      this.setState({
        loading: false,
      });
    }
  };

  render() {
    return (
      <Container>
        <h1>Oracle Email Notifications</h1>
        <TextField
          textarea={true}
          resizable={true}
          value={this.state.idList}
          label="Enter Email IDs"
          placeholder="1001,1002,1003"
          onChange={this.handleIdListChange}
        />
        <Button
          color="success"
          style={{ marginTop: 20 }}
          onClick={this.generateFiles}
          loading={this.state.loading}
          disabled={this.state.loading}
        >
          Generate PDF Files
        </Button>
        <Toast
          message={this.state.toastMessage}
          visible={this.state.showToast}
          position="right"
          onTimeout={() => {
            this.setState({
              showToast: false,
            });
          }}
        />
      </Container>
    );
  }
}

export default OracleEmailNotifications;
