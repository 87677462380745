const TOKEN_NAME = 'auth';

export const getAuthToken = () => {
  return localStorage.getItem(TOKEN_NAME);
};

export const isUserLogged = () => {
  return !!getAuthToken();
};

export const saveAuthToken = (token) => {
  return localStorage.setItem(TOKEN_NAME, token);
};

export const getTokenPayload = () => {
  const authToken = getAuthToken();

  if (!authToken) return null;

  const stringifiedPayload = atob(authToken.split('.')[1]);

  return JSON.parse(stringifiedPayload);
};

export const deleteAuthToken = () => {
  return localStorage.removeItem(TOKEN_NAME);
};
