import React from 'react';

import Container from 'emerald-ui/lib/Container';
import Row from 'emerald-ui/lib/Row';
import Col from 'emerald-ui/lib/Col';
import Card from 'emerald-ui/lib/Card';
import Button from 'emerald-ui/lib/Button';
import styled from 'styled-components';
import FileUpload from 'emerald-ui/lib/FileUpload';
import Toast from 'emerald-ui/lib/Toast';

import Spinner from 'emerald-ui/lib/Spinner';

import ConfirmationModal from '../../components/ConfirmationModal';

import xlsx from 'xlsx';

import axios from 'axios';

import { removeLvNotifications, sendLvNotifications } from '../../api/lv';

const StyledContainer = styled(Container)`
  margin-top: 30px;
`;

const Title = styled.h2`
  text-align: center;
  font-weight: lighter;
`;

class AuditLvNotifications extends React.Component {
  state = {
    auditingNotifications: false,
    files: [],
    progressMessage: '',
    askForConfirmation: false,
    allIdsToRemove: [],
    allIdsToSend: [],
    showToast: false,
    toastMessage: '',
  };

  handleUploadStart = (files) => {
    const messages = {
      size: 'File is too large. Please choose another file',
      format: 'File type not supported. We only support XLSX',
      single: 'Only one file upload is supported',
    };

    const filesWithRejectedMessages = files.map((file) => {
      if (file.rejected) {
        file.message = messages[file.rejected];
      }

      return file;
    });

    this.setState((prevState) => {
      const newFiles = [...prevState.files, ...filesWithRejectedMessages];

      return { files: newFiles };
    });
  };

  handleCancel = (fileName, id) => {
    this.setState((prevState) => {
      const newFiles = prevState.files.filter((prevFile) => prevFile.id !== id);

      return { files: newFiles };
    });
  };

  getIdsFromFileContent = (fileContent) => {
    const idsToRemove = [],
      idsToSend = [];

    const fileWorkbook = xlsx.read(fileContent, {
      type: 'buffer',
      cellStyles: true,
    });

    const firstSheetName = fileWorkbook.SheetNames[0];

    const firstSheet = fileWorkbook.Sheets[firstSheetName];

    for (const cellID in firstSheet) {
      if (!cellID.match(/^A[0-9]+$/) || cellID === 'A1') continue;

      const cellContent = firstSheet[cellID];

      if (cellContent.s.fgColor) {
        idsToRemove.push(cellContent.v);
      } else {
        idsToSend.push(cellContent.v);
      }
    }

    return {
      idsToRemove,
      idsToSend,
    };
  };

  auditNotifications = async () => {
    this.setState({
      auditingNotifications: true,
      progressMessage: 'Prosessing files...',
    });

    try {
      const { files } = this.state;

      let allIdsToRemove = [],
        allIdsToSend = [];

      for (const file of files) {
        const { data: fileContent } = await axios.get(file.preview, {
          responseType: 'arraybuffer',
        });

        const { idsToRemove, idsToSend } = this.getIdsFromFileContent(fileContent);

        allIdsToRemove = allIdsToRemove.concat(idsToRemove);
        allIdsToSend = allIdsToSend.concat(idsToSend);
      }

      this.setState({
        progressMessage: 'Waiting for user confirmation...',
        askForConfirmation: true,
        allIdsToRemove,
        allIdsToSend,
      });
    } catch (error) {
      this.setState({
        progressMessage: '',
        showToast: true,
        toastMessage: 'An error ocurred while processing the files',
        auditingNotifications: false,
      });
    }
  };

  onUserConfirm = async () => {
    try {
      this.setState({
        askForConfirmation: false,
        progressMessage: 'Removing highlighted notifications...',
        files: [],
      });

      const allIdsToRemoveNotDuplicated = [...new Set(this.state.allIdsToRemove)];

      await removeLvNotifications(allIdsToRemoveNotDuplicated);

      this.setState({
        progressMessage: 'Adding notifications to the queue...',
      });

      const allIdsToSendNotDuplicated = [...new Set(this.state.allIdsToSend)];

      await sendLvNotifications(allIdsToSendNotDuplicated);

      this.setState({
        toastMessage: 'All set, you made it',
        showToast: true,
      });
    } catch (error) {
      this.setState({
        toastMessage: 'An error ocurred while sending/removing notifications',
        showToast: true,
      });
    } finally {
      this.cleanFiles();
    }
  };

  cleanFiles = () => {
    this.setState({
      askForConfirmation: false,
      auditingNotifications: false,
      files: [],
      allIdsToRemove: [],
      allIdsToSend: [],
    });
  };

  close() {
    this.setState({ askForConfirmation: false });
  }

  hideToast = () => {
    this.setState({
      showToast: false,
    });
  };

  render() {
    const { auditingNotifications, files, progressMessage, askForConfirmation, allIdsToRemove, allIdsToSend } =
      this.state;

    const AuditButtonDisbale = files.length === 0 || auditingNotifications === true;

    return (
      <React.Fragment>
        <StyledContainer>
          <Row center>
            <Col xs={12} md={5}>
              <Card>
                <Title className="text-dark">LV Notifications Auditory</Title>

                {!auditingNotifications && (
                  <FileUpload
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    files={this.state.files}
                    disableClick={auditingNotifications}
                    onUploadStart={this.handleUploadStart}
                    onCancel={this.handleCancel}
                    uploadListCondensed
                  />
                )}
                {auditingNotifications && (
                  <div style={{ textAling: 'center' }}>
                    <Spinner style={{ marginTop: 15 }} size="lg" />
                    <Title className="text-dark">{progressMessage}</Title>
                  </div>
                )}
                {!auditingNotifications && (
                  <Button
                    color="info"
                    onClick={this.auditNotifications}
                    disabled={AuditButtonDisbale}
                    style={{ marginTop: 15 }}
                  >
                    Audit and send notifications
                  </Button>
                )}
              </Card>
            </Col>
          </Row>
        </StyledContainer>

        {askForConfirmation && (
          <ConfirmationModal show={askForConfirmation} onCancel={this.cleanFiles} onConfirm={this.onUserConfirm}>
            <p>
              You are about to remove <b>{allIdsToRemove.length}</b> notifications and send <b>{allIdsToSend.length}</b>{' '}
              notifications.
              <br />
              <br />
              Are you sure this is correct?
            </p>
          </ConfirmationModal>
        )}

        <Toast
          message={this.state.toastMessage}
          visible={this.state.showToast}
          position="left"
          onTimeout={this.hideToast}
        />
      </React.Fragment>
    );
  }
}

export default AuditLvNotifications;
