import React from 'react';
import styled from 'styled-components';

import BoardTable from './components/BoardTable';
import Container from 'emerald-ui/lib/Container';
import Panel from 'emerald-ui/lib/Panel';

const Title = styled.h1`
  font-weight: lighter;
`;

class VerifyingBoards extends React.Component {
  state = {};

  render() {
    return (
      <React.Fragment>
        <Container>
          <Panel style={{ background: 'transparent', boxShadow: 'none' }}>
            <Panel.Body style={{ padding: '0' }}>
              <Title className="text-dark">Verifying Credentials summary</Title>
              <hr />
            </Panel.Body>
          </Panel>
          <Panel>
            <BoardTable {...this.props} />
          </Panel>
        </Container>
      </React.Fragment>
    );
  }
}

export default VerifyingBoards;
