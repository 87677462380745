import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import Alert from 'emerald-ui/lib/Alert';
import Button from 'emerald-ui/lib/Button';
import Panel from 'emerald-ui/lib/Panel';
import TextField from 'emerald-ui/lib/TextField';
import styled from 'styled-components';

import logo from './ec-logo.svg';
import backgroundLogo from './keyboard-background.jpg';

import { isUserLogged, saveAuthToken } from '../../utils/auth';

import { login } from '../../api/auth';

import SessionContext from '../../contexts/sessionContext';

const BackgroundImage = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: url('${backgroundLogo}');
  background-size: cover;
  background-repeat: no-repeat;
  transition: all 0.3s;
  filter: grayscale(60%) blur(5px);
  &:hover {
    filter: grayscale(0%) blur(0);
  }
`;

const StyledPanel = styled(Panel)`
  max-width: 480px;
  margin: 90px auto;
  filter: grayscale(10%);
`;

class Login extends Component {
  static contextType = SessionContext;

  state = {
    username: '',
    password: '',
    usernameErrorMessage: '',
    passwordErrorMessage: '',
    loginError: null,
    status: isUserLogged() ? 'LOGGED' : 'IDLE',
  };

  handleUsernameChange = (event) => {
    this.setState({ username: event.target.value, usernameErrorMessage: '' });
  };

  handlePasswordChange = (event) => {
    this.setState({ password: event.target.value, passwordErrorMessage: '' });
  };

  handleLogin = async () => {
    const { username, password } = this.state;

    const { updateIsLogged } = this.context;

    if (!username) return this.setState({ usernameErrorMessage: 'Your username is required' });

    if (!password) return this.setState({ passwordErrorMessage: 'Your password is required' });

    try {
      this.setState({ status: 'LOADING' });

      const { data: response } = await login({ username, password });

      const { valid, token } = response;

      if (valid === true) {
        saveAuthToken(token);
        this.setState({
          status: 'LOGGED',
        });
        updateIsLogged(true);
      } else {
        this.setState({
          password: '',
          status: 'IDLE',
          loginError: response.message,
        });
      }
    } catch (error) {
      this.setState({
        status: 'ERRORED',
        loginError: 'Something went wrong',
      });
    }
  };

  resetLoginError = () => {
    this.setState({
      loginError: null,
    });
  };

  render() {
    const { state } = this;

    const { status } = state;

    const { isLogged } = this.context;

    const isLoading = status === 'LOADING';

    console.log("System Status check... OK");

    if (isLogged) return <Redirect to="/lv/state-stats" />;

    return (
      <React.Fragment>
        <BackgroundImage />
        <StyledPanel>
          <div
            style={{
              backgroundColor: '#0C295C',
              padding: '50px 0',
              textAlign: 'center',
            }}
          >
            <img src={logo} style={{ width: 170 }} alt="EverCheck" />
          </div>
          <Panel.Body>
            <h2 style={{ fontWeight: 'lighter', margin: '0 0 40px 0', textAlign: 'center' }}>Login to your account</h2>
            <form>
              {state.loginError && (
                <Alert color="danger" onDismiss={this.resetLoginError} dismissible>
                  {state.loginError}
                </Alert>
              )}
              <TextField
                shape="classic"
                label="Username"
                autoComplete="off"
                value={state.username}
                errorMessage={state.usernameErrorMessage}
                onChange={this.handleUsernameChange}
                autoFocus
              />
              <TextField
                shape="classic"
                label="Password"
                type="password"
                autoComplete="off"
                style={{ marginTop: 15 }}
                value={state.password}
                errorMessage={state.passwordErrorMessage}
                onChange={this.handlePasswordChange}
              />
              <Button
                style={{ width: '100%', margin: '20px 0' }}
                color="info"
                loading={isLoading}
                disabled={isLoading}
                onClick={this.handleLogin}
              >
                Log in
              </Button>
            </form>
          </Panel.Body>
        </StyledPanel>
      </React.Fragment>
    );
  }
}

export default Login;
