import React from 'react';
import Alert from 'emerald-ui/lib/Alert';
import Toggle from 'emerald-ui/lib/Toggle';
import Col from 'emerald-ui/lib/Col';
import Row from 'emerald-ui/lib/Row';
import SearchForm from 'emerald-ui/lib/SearchForm';
import moment from 'moment';
import styled from 'styled-components';

export const StyledCol = styled(Col)`
  display: flex;
  flex-direction: row;
  position: relative;
`;

export const StyledAlert = styled(Alert)`
  width: 100%;
`;

export const StyledToggle = styled(Toggle)`
  margin: 0;
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  margin-left: -20px;

  &.toggle.checked {
    background-color: #ff4e4a;
  }
`;

export const StyledDiv = styled.div`
  height: 80px;
  width: 100%;
  position: relative;
`;

export const StyledH3 = styled.h3`
  margin: 0;
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  float: right;
`;

class PackagesList extends React.Component {
  state = {
    searchTerm: '',
    toggleFailed: false,
  };

  updateSeachTerm = (e) => {
    this.setState({
      searchTerm: e.target.value.replace(/[^A-Za-z]/gi, ''),
    });
  };

  clearSearchTerm = () => {
    this.setState({
      searchTerm: '',
    });
  };

  handleToggleFailed = () => {
    this.setState({
      toggleFailed: !this.state.toggleFailed,
    });
  };

  render() {
    const { packages, id } = this.props;
    const { searchTerm, toggleFailed } = this.state;

    const packagesAlerts = [
      <StyledCol md={8} lg={8} key="search">
        <SearchForm
          inputId={`query-${id}`}
          onSubmit={(e) => e.preventDefault()}
          onChange={this.updateSeachTerm}
          onClear={this.clearSearchTerm}
          value={searchTerm}
          clearable
          style={{ width: '100%' }}
        />
      </StyledCol>,
      <StyledCol lg={4} md={4} key="toggleFailed">
        <StyledDiv>
          <Row>
            <StyledCol md={6} lg={6}>
              <StyledDiv>
                <StyledH3>Not valid only</StyledH3>
              </StyledDiv>
            </StyledCol>
            <StyledCol md={6} lg={6}>
              <StyledDiv>
                <StyledToggle
                  label="failed"
                  ref={(node) => (this.toggleElement = node)}
                  checked={toggleFailed}
                  onChange={this.handleToggleFailed}
                />
              </StyledDiv>
            </StyledCol>
          </Row>
        </StyledDiv>
      </StyledCol>,
    ];

    packages.forEach((p) => {
      if (
        (searchTerm !== '' && !p.id.toLowerCase().includes(searchTerm.toLowerCase())) ||
        (toggleFailed && p.status === 'VALID')
      )
        return;

      packagesAlerts.push(
        <StyledCol lg={6} key={p.id}>
          <StyledAlert color={p.status === 'VALID' ? 'success' : 'danger'}>
            <b>
              <span role="img" aria-label="pkg">
                📦
              </span>
              {p.id}
            </b>{' '}
            <span role="img" aria-label="date">
              🗓
              {moment(p.lastCompiled.toDate()).format('MM/DD/YYYY ⏰HH:mm').toString()}
            </span>
          </StyledAlert>
        </StyledCol>
      );
    });

    return packagesAlerts;
  }
}

export default PackagesList;
