import axios from 'axios';
import moment from 'moment';

import { getAuthToken } from '../utils/auth';

export const lvAPI = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

const getHeader = () => {
  return {
    headers: { Authorization: getAuthToken() },
  };
};

export const getStateStats = (params = {}) => {
  return lvAPI.get('/lv/state-stats', { params, ...getHeader() });
};

export const getBoardStats = (params = {}) => {
  return lvAPI.get('/lv/board-stats', { params, ...getHeader() });
};

export const getBoardErrors = (params = {}) => {
  return lvAPI.get('/lv/board-errors', { params, ...getHeader() });
};

export const getFileStats = (params = {}) => {
  return lvAPI.get('/lv/file-stats', { params, ...getHeader() });
};

export const getNewLvFilesStats = (params = {}) => {
  return lvAPI.get('lv//new-lv-file-stats', { params, ...getHeader() });
};

export const getFileProgress = (params = {}) => {
  return lvAPI.get('/lv/file-progress', { params, ...getHeader() });
};

export const getNewLvFilesProgress = (params = {}) => {
  return lvAPI.get('/lv/new-lv-file-progress', { params, ...getHeader() });
};

export const downloadLvNotificationsFile = (params = {}) => {
  return lvAPI.get('/lv/lv-notification', {
    params,
    ...getHeader(),
    responseType: 'blob',
  });
};

export const removeLvNotifications = (ids) => {
  return lvAPI.post('/lv/lv-notification/delete', ids, { ...getHeader() });
};

export const sendLvNotifications = (ids) => {
  return lvAPI.post('/lv/lv-notification/send', ids, { ...getHeader() });
};

export const getLicsByReportType = async (params = {}) => {
  const { data } = await lvAPI.get('/lv/licenses-export', {
    params,
    ...getHeader(),
    responseType: 'blob',
  });

  const url = URL.createObjectURL(new Blob([data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `licenses_report_${params.type}.xlsx`);
  link.click();
};

export const getScreenshotExportByIdBoard = async (params = {}) => {
  const { boardName, type, dateToFilter } = params;
  const { data } = await lvAPI.post('/lv/screenshot-stats-export', params, {
    ...getHeader(),
    responseType: 'arraybuffer',
  });

  const isFileEmpty = data.byteLength < 100;
  if (isFileEmpty) return;

  const date = dateToFilter ? moment(dateToFilter, 'YYYY-MM-DD').format('MMDDYYYY') : moment().format('MMDDYYYY');

  const url = URL.createObjectURL(new Blob([data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${type}_screenshots_${boardName}_${date}.xlsx`);
  link.click();
  return true;
};

export const getScreenshotExportByType = async (params = {}) => {
  const { type, dateToFilter } = params;
  const { data } = await lvAPI.post('/lv/screenshot-stats-export-by-type', params, {
    ...getHeader(),
    responseType: 'arraybuffer',
  });

  const isFileEmpty = data.byteLength < 100;
  if (isFileEmpty) return;
  const date = dateToFilter ? moment(dateToFilter, 'YYYY-MM-DD').format('MMDDYYYY') : moment().format('MMDDYYYY');

  const url = URL.createObjectURL(new Blob([data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${type}_screenshots_${date}.xlsx`);
  link.click();
  return true;
};

export const getSMissingcreenshotStats = (params = {}) => {
  return lvAPI.get('/lv/missing-screenshot-stats', { params, ...getHeader() });
};

export const getS404creenshotStats = (params = {}) => {
  return lvAPI.get('/lv/404-screenshot-stats', { params, ...getHeader() });
};

export const getCapturedScreenshotStats = (params = {}) => {
  return lvAPI.get('/lv/captured-screenshot-stats', { params, ...getHeader() });
};

export const getMonthlyScreenshotStatsByType = (type, params = {}) => {
  return lvAPI.get(`/lv/${type}-monthly-screenshot`, {
    params,
    ...getHeader(),
  });
};

export const getMonthlyScreenshotExportByType = async (type, params = {}) => {
  const { date } = params;

  params.date = date ? date : moment().format('YYYY-MM-DD');

  const { data } = await lvAPI.get(`/lv/${type}-monthly-screenshot-export`, {
    params,
    ...getHeader(),
    responseType: 'arraybuffer',
  });

  const isFileEmpty = data.byteLength < 100;
  if (isFileEmpty) return;
  const dateFile = date ? moment(date, 'YYYY-MM-DD').format('MMDDYYYY') : moment().format('MMDDYYYY');

  const url = URL.createObjectURL(new Blob([data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${type}_monthly_screenshots_${dateFile}.xlsx`);
  link.click();
  return true;
};

export const getMonthlyScreenshotExportByIdBoard = async (type, boardName, params = {}) => {
  const { date } = params;

  params.date = date ? date : moment().format('YYYY-MM-DD');

  const { data } = await lvAPI.get(`/lv/${type}-monthly-screenshot-export`, {
    ...getHeader(),
    params,
    responseType: 'arraybuffer',
  });

  const isFileEmpty = data.byteLength < 100;
  if (isFileEmpty) return;

  const dateFile = date ? moment(date, 'YYYY-MM-DD').format('MMDDYYYY') : moment().format('MMDDYYYY');

  const url = URL.createObjectURL(new Blob([data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${type}_monthly_screenshots_${boardName}_${dateFile}.xlsx`);
  link.click();
  return true;
};

export const expiringLicensesList = async ({ offset = 0, limit = 10, startDate, endDate }) => {
  const params = {
    offset,
    limit,
    startDate,
    endDate,
  };

  return await lvAPI.get(`/expiring-licenses`, { params, ...getHeader() });
};

export const expiringLicensesReport = async ({ offset = 0, limit = 10, range: { startDate, endDate } }) => {
  const params = {
    offset,
    limit,
    startDate: startDate ? startDate : undefined,
    endDate: endDate ? endDate : undefined,
  };

  const { data } = await lvAPI.get(`/expiring-licenses/export`, {
    params,
    ...getHeader(),
    responseType: 'arraybuffer',
  });

  const isFileEmpty = data.byteLength < 100;
  if (isFileEmpty) return;

  const endDateSTR = startDate === endDate ? '' : `-to_${endDate}`;
  const dateFile = `${startDate ? startDate : moment().format('l')}${endDateSTR}`;

  const fileName = `ExpiringLicenses_Report_from_${dateFile}.xlsx`;
  const url = URL.createObjectURL(new Blob([data]));
  const link = document.createElement('a');

  link.href = url;
  link.setAttribute('download', fileName);
  link.click();

  return true;
};
