import React from 'react';
import ErrorModal from '../../components/ErrorModal';
import UserTable from './components/UserTable';
import Loading from '../../components/Loading';
import Panel from 'emerald-ui/lib/Panel';
import Container from 'emerald-ui/lib/Container';
import SearchForm from 'emerald-ui/lib/SearchForm';
import Toolbar from 'emerald-ui/lib/Toolbar';
import styled from 'styled-components';
import UpsertModal from './components/UpsertModal';
import FloatingActionButton from 'emerald-ui/lib/FloatingActionButton';
import Alert from 'emerald-ui/lib/Alert';
import { getUsers, updateUser } from '../../api/user';

const Title = styled.h1`
  font-weight: lighter;
`;

class UserManagement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status: 'LOADING',
      userList: [],
      searchTerm: '',
      upsertModalOpen: false,
      selectedRow: null,
      modalIsUpdate: false,
      userCreated: false,
      userUpdated: false,
      errorUpdating: false,
    };
  }

  async componentDidMount() {
    await this.getUserList();
  }

  getUserList = async () => {
    try {
      let {
        data: { users },
      } = await getUsers();

      this.setState({
        userList: users,
        status: 'LOADED',
      });
    } catch (error) {
      this.setState({
        status: 'ERRORED',
      });
    }
  };

  handleUpsertModal = (e, rowIndex = null, modalIsUpdate = false) => {
    e.preventDefault();

    this.setState({
      selectedRow: rowIndex,
      modalIsUpdate,
    });

    this.openUpsertModal();
  };

  closeDetailModal = () => {
    this.setState({
      upsertModalOpen: false,
      selectedRow: null,
    });
  };

  handleSubmitModal = async (isUpdateUser) => {
    this.setState({
      upsertModalOpen: false,
      userCreated: !isUpdateUser,
      userUpdated: isUpdateUser,
    });
    await this.getUserList();
  };

  openUpsertModal = () => {
    this.setState({
      upsertModalOpen: true,
    });
  };

  handleSearchTermChange = (e) => {
    this.setState({
      searchTerm: e.target.value.replace(/[^a-zA-Z0-9 ]/gi, ''),
    });
  };

  handleSearchTermClear = () => {
    this.setState({
      searchTerm: '',
    });
  };

  handleUpdateUser = async (_id, checked) => {
    let newUserList = [...this.state.userList];
    const i = newUserList.findIndex((user) => user._id === _id);
    let updatedUser;
    if (checked !== undefined) {
      updatedUser = {
        ...newUserList[i],
        active: !checked,
      };
    } else {
      updatedUser = {
        ...newUserList[i],
        loginAttemps: 0,
      };
    }
    newUserList[i] = updatedUser;
    try {
      await updateUser(updatedUser);
      this.setState({
        userList: newUserList,
      });
    } catch (error) {
      this.setState({
        errorUpdating: true,
      });
    }
  };

  handleToggleStatus = async (_id, checked) => {
    await this.handleUpdateUser(_id, checked);
  };

  handleLockReset = async (_id) => {
    await this.handleUpdateUser(_id);
  };

  handleAlert = () => {
    this.setState({
      userCreated: false,
      userUpdated: false,
      errorUpdating: false,
    });
  };

  render() {
    const {
      status,
      userList,
      searchTerm,
      upsertModalOpen,
      selectedRow,
      modalIsUpdate,
      userCreated,
      userUpdated,
      errorUpdating,
    } = this.state;

    let usersToDisplay;

    if (!searchTerm) {
      usersToDisplay = userList;
    } else {
      const searchTermRegex = new RegExp(searchTerm.trim().toUpperCase());

      usersToDisplay = userList.filter((user) => {
        return user.username.toUpperCase().match(searchTermRegex) || user.name.toUpperCase().match(searchTermRegex);
      });
    }

    switch (status) {
      case 'LOADED':
        return (
          <Container>
            <Title className="text-dark">User Management</Title>
            <hr />
            <Panel>
              <Panel.Body>
                {userCreated || userUpdated ? (
                  <Alert color="success" onDismiss={this.handleAlert}>
                    <p style={{ padding: '10px 0', margin: 0 }}>
                      User {userCreated ? 'created' : userUpdated ? 'updated' : null}
                    </p>
                  </Alert>
                ) : null}
                {errorUpdating && (
                  <Alert color="warning" onDismiss={this.handleAlert}>
                    <p style={{ padding: '10px 0', margin: 0 }}>Something went wrong updating the User!</p>
                  </Alert>
                )}
                <Toolbar>
                  <SearchForm
                    clearable
                    value={searchTerm}
                    onChange={this.handleSearchTermChange}
                    onClear={this.handleSearchTermClear}
                  />
                  <FloatingActionButton
                    icon="person_add"
                    color="brand"
                    ariaLabel="Add user"
                    onClick={this.handleUpsertModal}
                  />
                </Toolbar>
                <UserTable
                  userList={usersToDisplay}
                  handleModal={this.handleUpsertModal}
                  handleToggleStatus={this.handleToggleStatus}
                  handleLockReset={this.handleLockReset}
                />
              </Panel.Body>
            </Panel>
            <UpsertModal
              show={upsertModalOpen}
              onHide={this.closeDetailModal}
              user={usersToDisplay[selectedRow]}
              isUpdate={modalIsUpdate}
              handleSubmitModal={this.handleSubmitModal}
            />
          </Container>
        );

      case 'LOADING':
        return <Loading message="Getting user list 👥" />;

      case 'ERRORED':
      default:
        return <ErrorModal />;
    }
  }
}

export default UserManagement;
