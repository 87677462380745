import React from 'react';
import IconButton from 'emerald-ui/lib/IconButton';
import Spinner from 'emerald-ui/lib/Spinner';
import { getScreenshotExportByIdBoard } from '../../../api/lv';

class BoardExpansion extends React.Component {
  state = {
    isDownloading: false,
  };

  exportScreenshots = async (idBoard, boardName, type, dateToFilter) => {
    const params = { idBoard, boardName, type, dateToFilter };
    this.setState({
      isDownloading: true,
      needPageRefresh: false,
    });
    let downloaded = await getScreenshotExportByIdBoard(params);
    if (!downloaded) this.setState({ needPageRefresh: true });

    this.setState({ isDownloading: false });
  };
  render() {
    const { boardStats, type, dateToFilter } = this.props;
    const { isDownloading, needPageRefresh } = this.state;

    return (
      <tr>
        <td className="content-name-td">{boardStats.boardName}</td>
        <td className="table-td-center">{boardStats.totalScreenshots}</td>
        <td className="table-td-center">
          {isDownloading ? (
            <Spinner size="sm" />
          ) : (
            <IconButton
              icon="file_download"
              onClick={() => this.exportScreenshots(boardStats.idBoard, boardStats.boardName, type, dateToFilter)}
            />
          )}
        </td>
        <td style={{ display: needPageRefresh ? 'block' : 'none' }}>
          {needPageRefresh ? <b>Please refresh the page</b> : null}
        </td>
      </tr>
    );
  }
}
export default BoardExpansion;
