import React from 'react';

import Container from 'emerald-ui/lib/Container';
import Row from 'emerald-ui/lib/Row';
import Modal from 'emerald-ui/lib/Modal';
import Col from 'emerald-ui/lib/Col';
import TextField from 'emerald-ui/lib/TextField';
import Button from 'emerald-ui/lib/Button';
import Alert from 'emerald-ui/lib/Alert';
import Spinner from 'emerald-ui/lib/Spinner';
import styled from 'styled-components';
import MultipleSelect from './MultiSelect';
import SingleSelect from 'emerald-ui/lib/SingleSelect';
import { updateUser, createUser } from '../../../api/user';
import { PERMISSION_OPTIONS, ROLE_OPTIONS } from '../../../utils/constants';

const TextFieldContainer = styled(Container)`
  margin: 20px 0;
`;

const SmallSingleSelect = styled(SingleSelect)`
  width: 100%;
`;
const ResetButton = styled(Button)`
  margin-right: 10px;
`;

const ButtonContainer = styled.div`
  margin: 15px;
  text-align: right;
`;

class UpsertModal extends React.Component {
  state = {
    isLoading: false,
    isErrored: false,
    errorMessage: null,
    password: '',
  };

  componentDidUpdate() {
    const { user } = this.props;
    const { username } = this.state;
    if (user && user.username !== username) {
      this.setState({
        ...user,
      });
    }
  }

  handleAlert = () => {
    this.setState({
      isErrored: false,
      errorMessage: null,
    });
  };

  handleNameChange = (e) => {
    this.setState({
      name: e.target.value.toUpperCase(),
    });
  };

  handleUsernameChange = (e) => {
    this.setState({
      username: e.target.value.toLowerCase(),
    });
  };

  handlePasswordChange = (e) => {
    this.setState({
      password: e.target.value,
    });
  };

  handleRoleChange = (e) => {
    this.setState({
      role: e,
    });
  };

  handleResetButton = () => {
    const { user } = this.props;
    this.setState({
      ...user,
      password: '',
    });
  };

  handleSubmitButton = async () => {
    const { _id, name, username, password, role, permissions } = this.state;
    const { isUpdate, handleSubmitModal } = this.props;
    const errorStatusCodes = ['USER_CANNOT_BE_UPDATED', 'USER_CANNOT_BE_CREATED', 'USER_EXISTS'];

    this.setState({
      isLoading: true,
      isErrored: false,
    });

    try {
      let result;

      result = isUpdate
        ? await updateUser({
            _id,
            name,
            username,
            password,
            role,
            permissions,
          })
        : await createUser({
            name,
            username,
            password,
            role,
            permissions,
          });

      this.setState({
        isLoading: false,
      });
      if (errorStatusCodes.includes(result.data.code)) {
        this.setState({
          errorMessage: result.data.message,
          isErrored: true,
        });
        return;
      }
      handleSubmitModal(isUpdate);
    } catch (error) {
      this.setState({
        errorMessage: 'Something went wrong, try again later',
        isErrored: true,
        isLoading: false,
      });
    }
  };

  handleChange = (selectedOptions) => {
    const values = Array.from(selectedOptions, (option) => option.value);
    this.setState({ permissions: values });
  };

  handleClose = () => {
    const { onHide } = this.props;
    this.setState({
      name: '',
      username: '',
      role: '',
      password: '',
      permissions: [],
    });
    onHide();
  };

  render() {
    const {
      name = '',
      username = '',
      role = '',
      password = '',
      permissions = [],
      isErrored,
      errorMessage,
      isLoading,
    } = this.state;

    const { show, isUpdate } = this.props;

    const validName = !!name && name.length >= 4 && name.length <= 30;
    const validUsername = !!username && username.length >= 4 && username.length <= 30;
    const validPassword = !!password && password.length >= 4 && password.length <= 30;
    const invalidPasswordTextField = !validPassword && (!isUpdate || (isUpdate && password !== ''));
    const lengthErrorMessage = 'Length between 4 and 30';
    const buttonDisable = !validName || !validUsername || !role || invalidPasswordTextField || isLoading;

    return (
      <Modal show={show} onHide={this.handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{isUpdate ? `Update ${username}` : 'Create new User'}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <TextFieldContainer>
            <Row>
              <Col md={6}>
                <TextField
                  label="Name"
                  id="name"
                  disabled={isLoading}
                  onChange={this.handleNameChange}
                  value={name}
                  errorMessage={!validName ? lengthErrorMessage : ''}
                  maxLength={30}
                />
              </Col>
              <Col md={6}>
                <SmallSingleSelect
                  label="Role"
                  id="role"
                  disabled={isLoading}
                  value={role}
                  onSelect={this.handleRoleChange}
                >
                  <option key={0} value="" selected={role === ''}>
                    Select role
                  </option>
                  {ROLE_OPTIONS.length ? (
                    ROLE_OPTIONS.map((option, i) => {
                      return (
                        <option key={i + 1} value={option} selected={role === option}>
                          {option}
                        </option>
                      );
                    })
                  ) : (
                    <tr className="table-nodata-found">
                      <td colSpan={8}>No data found</td>
                    </tr>
                  )}
                </SmallSingleSelect>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <TextField
                  label="Username"
                  id="username"
                  disabled={isUpdate || isLoading}
                  onChange={this.handleUsernameChange}
                  value={username}
                  errorMessage={!validUsername ? lengthErrorMessage : ''}
                  maxLength={30}
                />
              </Col>
              <Col md={6}>
                <TextField
                  label="Password"
                  id="password"
                  type="password"
                  disabled={isLoading}
                  onChange={this.handlePasswordChange}
                  value={password}
                  errorMessage={invalidPasswordTextField ? lengthErrorMessage : ''}
                  maxLength={30}
                />
              </Col>
            </Row>

            <MultipleSelect label="Permissions" id="permissions" onSelect={this.handleChange} disabled={isLoading}>
              {PERMISSION_OPTIONS.length ? (
                PERMISSION_OPTIONS.map((option, i) => {
                  return (
                    <option key={i} value={option.value} selected={permissions.includes(option.value)}>
                      {option.description}
                    </option>
                  );
                })
              ) : (
                <tr className="table-nodata-found">
                  <td colSpan={8}>No data found</td>
                </tr>
              )}
            </MultipleSelect>
          </TextFieldContainer>
          <ButtonContainer>
            <ResetButton onClick={this.handleResetButton} disabled={isLoading}>
              RESET
            </ResetButton>
            <Button onClick={this.handleSubmitButton} color="info" loading={isLoading} disabled={buttonDisable}>
              SUBMIT
            </Button>
          </ButtonContainer>
          {isErrored ? (
            <Alert color="warning" onDismiss={this.handleAlert}>
              <p style={{ padding: '10px 0', margin: 0 }}>{errorMessage}</p>
            </Alert>
          ) : null}
          {isLoading && <Spinner size="lg" style={{ margin: '20px auto' }} />}
        </Modal.Body>
      </Modal>
    );
  }
}

export default UpsertModal;
