import React from 'react';
import Tab from 'emerald-ui/lib/Tab';
import Panel from 'emerald-ui/lib/Panel';
import StateExpansion from './StateExpansion';
import Export from './Export';
import Loading from '../../../components/Loading';
import Row from 'emerald-ui/lib/Row';
import Col from 'emerald-ui/lib/Col';
import Container from 'emerald-ui/lib/Container';
import Nav from 'emerald-ui/lib/Nav';
import DatePicker from 'emerald-ui/lib/DatePicker';
import moment from 'moment';
import './MonthlyScreenshot.css';

const Dashboard = ({
  statesWithFailedMonthlyScreenshotsStatus,
  statesWithFailedMonthlyScreenshots,
  filterByDate,
  dateToFilter,
}) => {
  let totalFailedScreenshots = 0;

  if (statesWithFailedMonthlyScreenshotsStatus && statesWithFailedMonthlyScreenshotsStatus === 'LOADED')
    Object.keys(statesWithFailedMonthlyScreenshots).forEach((state) => {
      totalFailedScreenshots += statesWithFailedMonthlyScreenshots[state].totalScreenshots;
    });

  const handlerFilterByDate = (day) => {
    let dateToFilter = moment(day).format('YYYY-MM-DD');
    filterByDate(dateToFilter);
  };

  return (
    <Container>
      <Panel>
        <Panel.Body>
          <Row className="container-monthly">
            <Col lg={9} xs={12} md={8} sm={12}>
              <h1 className="text-dark">Monthly Screenshot History</h1>
            </Col>
            <Col xs={6} md={4} sm={6} lg={3}>
              <DatePicker
                ariaLabel={`Choose month to filter`}
                onChange={handlerFilterByDate}
                value={dateToFilter && moment(dateToFilter).format('MM/yyyy')}
                label="Month"
                dateFormat="MM/yyyy"
                showMonthYearPicker
              />
            </Col>
          </Row>
        </Panel.Body>
      </Panel>

      <Tab.Container defaultActiveKey="first">
        <Nav className="monthly-screenshot-history-nav">
          <a id="first" href="/">
            <b>
              {statesWithFailedMonthlyScreenshotsStatus === 'LOADING' ? (
                <span role="img" aria-label="searching">
                  🔍
                </span>
              ) : (
                totalFailedScreenshots
              )}
              &nbsp;
            </b>
            Failed
            {totalFailedScreenshots > 0 ? <Export type="failed" dateToFilter={dateToFilter} /> : null}
          </a>
        </Nav>
        <div className="bg-body monthly-screenshot-history">
          <Tab.Content>
            <Tab.Pane eventKey="first">
              {statesWithFailedMonthlyScreenshotsStatus === 'LOADING' ? (
                <Loading message="Searching Monthly Screenshots History information..." />
              ) : Object.keys(statesWithFailedMonthlyScreenshots).length ? (
                Object.keys(statesWithFailedMonthlyScreenshots).map((state, i) => {
                  return (
                    <StateExpansion
                      key={i}
                      stateName={state}
                      status={statesWithFailedMonthlyScreenshotsStatus}
                      stats={statesWithFailedMonthlyScreenshots[state]}
                      type={'failed'}
                      dateToFilter={dateToFilter}
                    />
                  );
                })
              ) : (
                <b>
                  No data found for this month...{' '}
                  <span role="img" aria-label="celebrate">
                    🎉
                  </span>
                </b>
              )}
            </Tab.Pane>
          </Tab.Content>
        </div>
      </Tab.Container>
    </Container>
  );
};

export default Dashboard;
