import React from 'react';
import ErrorModal from '../../components/ErrorModal';
import Dashboard from './components/Dashboard';
import Loading from '../../components/Loading';
import { CustomNotificationProgress } from '../../api/hr';
import moment from 'moment';

class CustomNotificationStatus extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status: 'LOADING',
      CNProgressInformation: [],
      date: moment().format('MM-DD-YYYY'),
    };
  }

  async componentDidMount() {
    try {
      const { data } = await CustomNotificationProgress();
      let status = {};
      data.map((cn) => {
        if (!status.hasOwnProperty(cn.status)) {
          status[cn.status] = 1;
        } else {
          status[cn.status] += 1;
        }
      });
      this.setState({
        CNProgressInformation: {
          data,
          status,
        },
        status: 'LOADED',
      });
    } catch (error) {
      this.setState({
        status: 'ERRORED',
      });
    }
  }

  filterByDate = async (date) => {
    try {
      const params = { date };

      this.setState({
        status: 'LOADING',
        CNProgressInformation: 'LOADING',
        date,
      });

      let { data } = await CustomNotificationProgress(params);
      let status = {};
      data.map((cn) => {
        if (!status.hasOwnProperty(cn.status)) {
          status[cn.status] = 1;
        } else {
          status[cn.status] += 1;
        }
      });
      this.setState({
        CNProgressInformation: {
          data,
          status,
        },
        status: 'LOADED',
      });
    } catch (error) {
      this.setState({
        status: 'ERRORED',
      });
    }
  };

  render() {
    const { status, CNProgressInformation, date } = this.state;

    switch (status) {
      case 'LOADED':
        return <Dashboard CNProgressInformation={CNProgressInformation} filterByDate={this.filterByDate} date={date} />;

      case 'LOADING':
        return <Loading message="Searching Custom Notification logs information..." />;

      case 'ERRORED':
      default:
        return <ErrorModal />;
    }
  }
}

export default CustomNotificationStatus;
