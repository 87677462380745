import React from 'react';

import Container from 'emerald-ui/lib/Container';
import CardGrid from 'emerald-ui/lib/CardGrid';

import { FirestoreDocument } from 'react-firestore';
import ExpansionPanel from 'emerald-ui/lib/ExpansionPanel';

import StatusLabels from './StatusLabels';
import QstatusCard from './QStatusCard';
import ShowPostCard from './ShowPostCard';

import { TableLoader, LabelLoader } from './Loaders';

const InstanceStatus = ({ datacenter }) => {
  return (
    <ExpansionPanel>
      <ExpansionPanel.Summary>
        <FirestoreDocument
          path={`shareplex-status/${datacenter}_status`}
          render={({ isLoading, data: statusInfo, error }) => {
            if (isLoading) {
              return <LabelLoader />;
            }

            if (error) {
              return <h3>error.message</h3>;
            }

            const labels = statusInfo.information;
            return (
              <Container>
                <StatusLabels labels={labels} />
              </Container>
            );
          }}
        />
      </ExpansionPanel.Summary>

      <ExpansionPanel.Content style={{ 'background-color': '#f2f2f2' }}>
        <CardGrid>
          <FirestoreDocument
            path={`shareplex-status/${datacenter}_qstatus`}
            render={({ isLoading, data: qstatusOutput, error }) => {
              if (isLoading) {
                return <TableLoader />;
              }

              if (error) {
                return <h3>{error.message}</h3>;
              }

              return <QstatusCard qstatus={qstatusOutput} />;
            }}
          />

          <FirestoreDocument
            path={`shareplex-status/${datacenter}_show_post`}
            render={({ isLoading, data: showPostOutput, error }) => {
              if (isLoading) {
                return <TableLoader />;
              }

              if (error) {
                return <h3>{error.message}</h3>;
              }

              return <ShowPostCard showPost={showPostOutput} />;
            }}
          />
        </CardGrid>
      </ExpansionPanel.Content>
    </ExpansionPanel>
  );
};

export default InstanceStatus;
