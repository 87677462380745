import axiosBuildURL from 'axios/lib/helpers/buildURL';
import mapValues from 'lodash/mapValues';
import qs from 'querystring';

/**
 * Converts a query string to an object (with or without question mark at the beginning)
 * @param {string} [search='']  The query string to convert.
 * @returns {Object} Object from queryString
 * @example for 'offset=0&limit=10' the function returns {offset: 0, limit: 10}
 */
export const convertQueryStringToObject = (search = '') => {
  const queryString = search.replace(/^\?/, '');

  return mapValues(qs.parse(queryString), (value) => {
    let result;
    try {
      result = JSON.parse(value);
    } catch (e) {
      result = value;
    }
    return result;
  });
};

/**
 * Converts an object to a query string
 * @param {Object} [source={}] The object to be converted to queryString
 * @param {boolean} [withQuestionCharacter=true] Determinate whether to remove the question mark
 * @returns {string} QueryString for object
 * @example for {offset:0, limit:10} the function returns '?offset=0&limit=10' or 'offset=0&limit=10'
 */
export const convertObjectToQueryString = (source = {}, withQuestionCharacter = true) => {
  const queryString = axiosBuildURL('', source);
  return withQuestionCharacter ? queryString : queryString.slice(1);
};
