import React from 'react';
import { deleteAuthToken } from '../../utils/auth';
import SessionContext from '../../contexts/sessionContext';

const Logout = () => {
  const { updateIsLogged } = React.useContext(SessionContext);

  React.useEffect(() => {
    deleteAuthToken();
    updateIsLogged(false);
  });

  return null;
};

export default Logout;
