import React from 'react';

import Modal from 'emerald-ui/lib/Modal';
import TextField from 'emerald-ui/lib/TextField';
import Button from 'emerald-ui/lib/Button';

import { runUpload } from '../../../api/prehire';

class ConfirmationModal extends React.Component {
  state = {
    employerId: '',
    status: 'IDLE',
  };

  handleEmployerIdChange = (e) => {
    let employerId = e.target.value;
    this.setState({
      employerId,
    });
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.show === true) {
      this.setState({
        employerId: '',
        status: 'IDLE',
      });
    }
  }

  handleSubmit = async () => {
    let requestError;

    try {
      this.setState({
        status: 'LOADING',
      });

      await runUpload({
        employerId: this.props.employerInfo.id,
      });

      this.setState({
        status: 'IDLE',
      });
    } catch (error) {
      requestError = error;
    } finally {
      //callback to do stuff, if error happens is send to callback as param
      this.props.runProcessCallback(requestError);
    }
  };

  render() {
    const { props, state } = this;

    const { employerInfo = {} } = props;

    const isConfirmationGood = +employerInfo.id === +state.employerId;
    const isLoading = state.status === 'LOADING';

    return (
      <Modal show={props.show} onHide={props.onClose}>
        <Modal.Header closeButton>
          <Modal.Title>Do you really want to run the process?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            You are about to run upload process for employer{' '}
            <b>
              60-{employerInfo.id} {employerInfo.name}
            </b>
            . Please reenter the employer ID to confirm.
          </p>
          <TextField
            label="Employer ID"
            id="employer-id"
            onChange={this.handleEmployerIdChange}
            style={{ width: '50%' }}
            autoComplete="off"
            value={state.employerId}
            maxLength={3}
          />
          <Button
            color="info"
            disabled={!isConfirmationGood || isLoading}
            loading={isLoading}
            onClick={this.handleSubmit}
          >
            RUN UPLOAD
          </Button>
        </Modal.Body>
      </Modal>
    );
  }
}

export default ConfirmationModal;
