import React from 'react';
import Card from 'emerald-ui/lib/Card';

const FileStatusCard = ({ fileStat }) => {
  const dateDownloaded = fileStat.dateDownloaded ? new Date(fileStat.dateDownloaded).toLocaleString() : 'None given';

  const fileFinishedAt = fileStat.dateFinish ? new Date(fileStat.dateFinish).toLocaleString() : 'None given';

  const subtitle = fileFinishedAt ? 'Process finished' : 'Process still running';

  return (
    <Card>
      <Card.Header>
        <h1 className="eui-card-header-title">
          {fileStat.stateCode} - {fileStat.name}
        </h1>
      </Card.Header>
      <div>
        <h2 className="eui-card-title">{subtitle}</h2>
        <p>
          Date file <b>downloaded</b>: {dateDownloaded}
          <br />
          <br />
          Date process <b>finished</b>: {fileFinishedAt}
        </p>
      </div>
    </Card>
  );
};

export default FileStatusCard;
